import './App.css'
import Navigation from './navigation'
import TimeCardContextInitializer from './contexts/timeCardContext'
import background from './public/images/background.jpg'
import StatisticsContextInitializer from './contexts/statisticsContext'
import ErrorContextInitializer from './contexts/errorContext'
import ErrorWrapper from './components/ErrorWrapper'
import SessionContextInitializer from './contexts/sessionContext'

function App() {
	return (
		<ErrorContextInitializer>
			<ErrorWrapper>
				<SessionContextInitializer>
					<TimeCardContextInitializer>
						<StatisticsContextInitializer>
							<img src={background} alt='' className='image' />
							<Navigation />
						</StatisticsContextInitializer>
					</TimeCardContextInitializer>
				</SessionContextInitializer>
			</ErrorWrapper>
		</ErrorContextInitializer>
	)
}

export default App