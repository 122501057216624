import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import HoursInput from '../../Inputs/HoursInput'
import ConfirmationDialogBox from '../../ConfirmationDialogBox'
import DescriptionTextarea from '../../Inputs/DescriptionTextarea'
import useTimeCardServices from '../../../services/timeCard'
import useMonthConfirmationServices from '../../../services/monthConfirmation'
import { checkHours, getSavedTaskKey } from '../../../utils/userTasks'
import { getRange } from '../../../utils/getRange'
import pen from '../../../public/pen.svg'
import cancel from '../../../public/cancel.svg'
import useError from '../../../hooks/useError'
import { editTimeCardsCheckIsMonthLocked } from '../../../utils/monthConfirmation'

const SavedTask = ({ task, index }) => {
    const [data, setData] = useState(Object.values(task)[0])
    const [isTaskDisabled, setIsTaskDisabled] = useState(true)
    const [editedTasks, setEditedTasks] = useState([])
    const [oldTask, setOldTask] = useState(null)
    const [areHourInputsOk, setAreHourInputsOk] = useState(true)
    const [isConfirmationShown, setIsConfirmationShown] = useState(false)
    const [isDescriptionShown, setIsDescriptionShown] = useState(false)
    const [disabledDays, setDisabledDays] = useState([])
    const { tasksState, deleteSavedTasks, changeSavedHours, restoreTask } = useContextTimeCard()
    const { deleteTimeCards, editTimeCards } = useTimeCardServices()
    const { getMonthConfirmation } = useMonthConfirmationServices()
    const { setErrorMessage } = useError()
    const { overtime, monthConfirmation, selectedDays, selectedMonth } = tasksState

    const getDisabledDays = () => {
        const days = []
        if (monthConfirmation?.prev?.isconfirmed || monthConfirmation?.prev?.islocked) {
            let idx = 0
            while (new Date(selectedDays[idx]).getMonth() < Number(selectedMonth.slice(5))) {
                days.push(idx)
                idx++
            }
        }

        if (monthConfirmation?.next?.isconfirmed || monthConfirmation?.next?.islocked) {
            let idx = 6
            while (new Date(selectedDays[idx]).getMonth() > Number(selectedMonth.slice(5))) {
                days.push(idx)
                idx--
            }
        }

        return days
    }

    useEffect(() => {
        setDisabledDays(getDisabledDays())
    }, [monthConfirmation, selectedDays])

    useEffect(() => {
        setData(Object.values(task)[0])
    }, [task])

    const handleRemoveTask = async () => {
        const monthConfirmationResponse = await getMonthConfirmation(selectedMonth)
        const isLocked = editTimeCardsCheckIsMonthLocked(monthConfirmationResponse, Object.values(task))
        if (isLocked) {
            setIsConfirmationShown(false)
            return setErrorMessage({error: 'Trying to delete locked hours.'})
        }

        const taskIds = Object.values(task).map(t => t.timecardid)
        const response = await deleteTimeCards(taskIds, overtime.week)

        if (response.error) {
            setErrorMessage(response)
            setIsConfirmationShown(false)
            return
        }

        const taskKey = getSavedTaskKey(data)
        const clientid = data.clientid
        const projectid = data.projectid
        setIsConfirmationShown(false)
        deleteSavedTasks(taskKey, clientid, projectid)
    }

    const handleEditTask = async () => {
        setIsTaskDisabled(false)
        setOldTask(task)
    }

    const handleTaskConfirm = async () => {
        if (editedTasks.length) {
            const currEditedTasks = []
            for (const key in task) {
                if (editedTasks.includes(key)) {
                    currEditedTasks.push(task[key])
                }
            }
            
            let hours = currEditedTasks.map(t => t.hours)
            const badInput = checkHours(hours)

            if (badInput) {
                setAreHourInputsOk(false)
                setTimeout(() => {
                    setAreHourInputsOk(true)
                }, 6000)

                return
            }

            const monthConfirmationResponse = await getMonthConfirmation(selectedMonth)
            const isLocked = editTimeCardsCheckIsMonthLocked(monthConfirmationResponse, currEditedTasks)
            if (isLocked) {
                return setErrorMessage({error: 'Trying to submit data for a locked month. Please refresh the page.'})
            }

            const response = await editTimeCards(currEditedTasks, overtime.week)

            if (response.error) {
                setErrorMessage(response)
                restoreTask(oldTask)
                setIsTaskDisabled(true)
                setEditedTasks([])
                return
            }
        }
        
        setEditedTasks([])
        setIsTaskDisabled(true)
    }

    const handleChangeHours = (e) => {
        const taskKey = getSavedTaskKey(data)
        const clientid = data.clientid
        const projectid = data.projectid
        const day = e.target.id
        const newEditedTasks = [...editedTasks, day]
        setEditedTasks(newEditedTasks)
        changeSavedHours(taskKey, clientid, projectid, day, e.target.value)
    }

    const handleCancelTask = () => {
        restoreTask(oldTask)
        setIsTaskDisabled(true)
        setEditedTasks([])
    }

    const showDescription = () => {
        const mobileDivs = Array.from(document.getElementsByClassName('mobile'))
        const anyMobileDivsShown = mobileDivs.some(md => window.getComputedStyle(md).display !== 'none')
        if (anyMobileDivsShown) {
            setIsDescriptionShown(true)
        }
    }

    return (
        <div className={styles.container}>
            {index === 0 && <div>
                Client: {data.clientname}, Project: {data.projectname}
                <div className={styles['column-names']}>
                    {data.jobname && <div className={styles.column} >Job:</div>}
                    {data.zonename && <>
                        <div className={`${styles.column} ${styles.desktop}`} >Zone:</div>
                        <div className={`${styles.column} ${styles.mobile} mobile`} >Zon:</div>
                    </>}
                    {data.systemname && <>
                        <div className={`${styles.column} ${styles.desktop}`} >System:</div>
                        <div className={`${styles.column} ${styles.mobile} mobile`} >Sys:</div>
                    </>}
                    {data.leadname && <>
                        <div className={`${styles.column} ${styles.desktop}`} >Lead:</div>
                        <div className={`${styles.column} ${styles.mobile} mobile`} >L/D:</div>
                    </>}
                    {data.activityname && <>
                        <div className={`${styles.column} ${styles.desktop}`} >Activity:</div>
                        <div className={`${styles.column} ${styles.mobile} mobile`} >Act:</div>
                    </>}
                    {data.revisionname && <>
                        <div className={`${styles.column} ${styles.desktop}`} >Revision:</div>
                        <div className={`${styles.column} ${styles.mobile} mobile`} >Rev:</div>
                    </>}
                </div>
            </div>}
            <div className={styles.task}>
                <div className={styles.left} onClick={showDescription} >
                    {data.jobname && <input className={styles.column} title={data.jobname} value={data.jobname} disabled />}
                    {data.zonename && <input className={styles.column} title={data.zonename} value={data.zonename} disabled />}
                    {data.systemname && <input className={styles.column} title={data.systemname} value={data.systemname} disabled />}
                    {data.leadname && <input className={styles.column} title={data.leadname} value={data.leadname} disabled />}
                    {data.activityname && <input className={styles.column} title={data.activityname} value={data.activityname} disabled />}
                    {data.revisionname && <input className={styles.column} title={data.revisionname} value={data.revisionname} disabled />}
                </div>
                {isDescriptionShown && <>
                    <div className={styles.transparent} onClick={() => setIsDescriptionShown(false)} ></div>
                    <div className={styles['description-container']}>
                        <div>Client:</div>
                        <DescriptionTextarea value={data.clientname} />
                        <div>Project:</div>
                        <DescriptionTextarea value={data.projectname} />
                        {data.jobname && <>
                            <div>Job:</div>
                            <DescriptionTextarea value={data.jobname} />
                        </>}
                        {data.zonename && <>
                            <div>Zone:</div>
                            <DescriptionTextarea value={data.zonename} />
                        </>}
                        {data.systemname && <>
                            <div>System:</div>
                            <DescriptionTextarea value={data.systemname} />
                        </>}
                        {data.leadname && <>
                            <div>Lead/Depratment:</div>
                            <DescriptionTextarea value={data.leadname} />
                        </>}
                        {data.activityname && <>
                            <div>Activity:</div>
                            <DescriptionTextarea value={data.activityname} />
                        </>}
                        {data.revisionname && <>
                            <div>Revision:</div>
                            <DescriptionTextarea value={data.revisionname} />
                        </>}
                    </div>
                </>}
                <div className={styles.right} >
                    {getRange(0, 7).map(num => (
                        <HoursInput
                            key={num}
                            id={num}
                            value={task[num] && task[num].hours}
                            disabled={!(task[num] && !isTaskDisabled) || disabledDays.includes(num)}
                            onChange={handleChangeHours}
                            timecardid={task[num] && task[num].timecardid}
                        />
                    ))}
                    {isTaskDisabled
                        ? < div className={styles['buttons-container']}>
                            <button
                                className={styles['edit-button']}
                                onClick={handleEditTask}
                                disabled={
                                    overtime.isconfirmed || overtime.islocked ||
                                    ((monthConfirmation?.current?.isconfirmed || monthConfirmation?.current?.islocked) && !disabledDays.length)
                                }
                            >
                                <img src={pen} alt='' height='16' />
                            </button>
                            <button
                                className={styles['remove-button']}
                                onClick={() => setIsConfirmationShown(true)}
                                disabled={overtime.isconfirmed || overtime.islocked || monthConfirmation?.current?.isconfirmed || monthConfirmation?.current?.islocked}
                            >
                                X
                            </button>
                        </div>
                        : < div className={styles['buttons-container']}>
                            <button className={styles['edit-button']} onClick={handleTaskConfirm} >
                                ✓
                            </button>
                            <button className={styles['cancel-button']} onClick={handleCancelTask} >
                                <img src={cancel} alt={''} width='25' height='16' />
                            </button>
                        </div>
                    }
                </div>
            </div>
            {!areHourInputsOk && <div>Please fill hour inputs with correct values</div>}
            {isConfirmationShown &&
            <ConfirmationDialogBox
                text='Are you sure you want to delete all your saved hours for this task?'
                onConfirm={handleRemoveTask}
                onCancel={() => setIsConfirmationShown(false)}
            />}
        </div >
    )
}

export default SavedTask
