import React, { useEffect } from 'react'
import styles from './index.module.css'
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE } from '../../utils/constants'

const ConfirmationDialogBox = ({ text, onConfirm, onCancel }) => {
    useEffect(() => {
        const onKeyPress = (event) => {
            
            if (event.keyCode === ESCAPE_KEY_CODE) {
                onCancel()
                return
            }
            
            if (event.keyCode === ENTER_KEY_CODE) {
                onConfirm()
                return
            }
        }

        document.addEventListener('keydown', onKeyPress)
        
        return () => document.removeEventListener('keydown', onKeyPress)
    }, [onCancel, onConfirm])

    return (
        <div>
            <div className={styles.transparent}></div>
            <div className={styles.container}>
                <div>{text}</div>
                <div className={styles['buttons-container']}>
                    <button className={styles.button} onClick={onConfirm} >Yes</button>
                    <button className={styles.button} onClick={onCancel} >No</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationDialogBox
