import React from 'react'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import Weekdays from '../../Weekdays'
import SavedTask from '../SavedTask'
import TotalHours from '../TotalHours'
import styles from './index.module.css'

const SavedTimeCard = () => {
    const { tasksState } = useContextTimeCard()
    const { savedTimeCard } = tasksState

    return (
        <div className={styles.container}>
            <div className={styles['inner-container']} >
                {savedTimeCard && !!Object.keys(savedTimeCard).length
                    ? <div className={styles.weekdays}><Weekdays /></div>
                    : <div>There are no saved hours for this week</div>
                }
                {Object.keys(savedTimeCard).map(clientid => (
                    <div key={clientid}>
                        {Object.keys(savedTimeCard[clientid]).map(projectid => (
                            <div key={projectid} className={styles.project} >
                                {Object.values(savedTimeCard[clientid][projectid]).map((task, index) => (
                                    <SavedTask key={index} task={task} index={index} />
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <TotalHours />
        </div>
    )
}

export default SavedTimeCard
