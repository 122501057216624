import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList as List }  from 'react-window';
import AddManualProjectOption from '../AddManualProjectOption'
import CheckBox from '../../../CheckBox'
import { getSortedNumbersAscending } from '../../../../utils/getSortedNumbersAscending'
import styles from './index.module.css'
import { pluralizeWord } from '../../../../utils/text'
import useError from '../../../../hooks/useError'
import useProjectAttributeServices from '../../../../services/projectAttribute'
import ConfirmationDialogBox from '../../../ConfirmationDialogBox';

const AddProjectOptions = React.memo(({ 
    setSelectedOption, 
    setManualOption, 
    options, 
    manualOption, 
    availableOptions,
    optionName,
    selectedEditProjectId
}) => {
    const { checkForSavedHoursWithAttributes } = useProjectAttributeServices()
    const [listOfOptions, setListOfOptions] = useState({})
    const [listOfFilteredOptions, setListOfFilteredOptions] = useState({})
    const [filterValue, setFilterValue] = useState('')
    const [isConfirmationShown, setIsConfirmationShown] = useState(false)
    const [currentPropId, setCurrentPropId] = useState()
    const [currentMessage, setCurrentMessage] = useState()
    const [isCurrentOptionChecked, setIsCurrentOptionChecked] = useState()
    const shiftSelection = useRef({})
    const { setErrorMessage } = useError()

    const attColumnNames = useMemo(() => ({
        id: optionName + 'id',
        name: optionName + 'name'
    }), [optionName])

    const setAttributeOptions = useCallback(() => {
        const optionsById = availableOptions.reduce((acc, row) => {
            acc[row[attColumnNames.id]] =  row
            return acc
        }, {})

        setListOfOptions(optionsById)
        setListOfFilteredOptions(optionsById)
    }, [availableOptions, attColumnNames.id])

    useEffect(() => {
        setAttributeOptions()
    }, [setAttributeOptions])

    const listOfUnfilteredOptions = useMemo(() => {
        // console.log(attColumnNames.name, (new Date()).getTime())
        const result = Object.entries(listOfFilteredOptions)
        .filter(([key, _]) => !options.has(Number(key))).sort(([_, a], [__, b]) => a[attColumnNames.name].localeCompare(b[attColumnNames.name]))
        
        // console.log(attColumnNames.name, (new Date()).getTime())
        return result
    }, [attColumnNames.name, listOfFilteredOptions, options])

    const listOfSelectedOptionOnState = useMemo(() => {
        if (!Object.keys(listOfOptions).length) return

        return Array.from(options)
            .sort((a, b) => listOfOptions[a][attColumnNames.name].localeCompare(listOfOptions[b][attColumnNames.name]))
    }, [listOfOptions, attColumnNames.name, options])

    const setFilter = (event) => {
        const currentFitler = event.target.value
        setFilterValue(currentFitler)


        const newFilteredOptions = {}
        for (const option of Object.values(listOfOptions)) {
            if (option[attColumnNames.name].toLowerCase().includes(currentFitler.toLowerCase())) {
                newFilteredOptions[option[attColumnNames.id]] = option
            }
        }

        setListOfFilteredOptions(newFilteredOptions)
    }

    const markInputAsChecked = (element, isInputSelected) => {
        const lastChild = element.lastChild
        lastChild.style.backgroundColor = isInputSelected ? 'white' : '#0896f5'
    }

    const unMarkInput = (element, isInputSelected) => {
        const lastChild = element.lastChild
        lastChild.style.backgroundColor = isInputSelected ? '#0896f5' : 'white'
    }

    const handleMouseEnter = (event, newIndex, isInputSelected) => {
        if (
            !event.shiftKey ||
            !Object.keys(shiftSelection.current).length ||
            shiftSelection.current.firstIndex === newIndex
        ) return

        const container = event.currentTarget.parentElement
        const childCheckboxes = Array.from(container.children)
        const [startIndex, endIndex] = getSortedNumbersAscending(shiftSelection.current.firstIndex, newIndex)

        for (let checkboxIndex = startIndex; checkboxIndex <= endIndex; checkboxIndex++) {
            markInputAsChecked(childCheckboxes[checkboxIndex], isInputSelected)
        }

        shiftSelection.current.secondIndex = newIndex
    }

    const handleMouseLeave = (event, isInputSelected) => {
        const container = event.currentTarget.parentElement
        const childCheckboxes = Array.from(container.children)

        const [startIndex, endIndex] = getSortedNumbersAscending(
            shiftSelection.current.firstIndex, 
            shiftSelection.current.secondIndex
        )
        for (let checkboxIndex = startIndex; checkboxIndex <= endIndex; checkboxIndex++) {
            unMarkInput(childCheckboxes[checkboxIndex], isInputSelected)
        }
    }

    const pluralizedOptionName = pluralizeWord(optionName)

    const handleChange = async (event, name) => {
        const propId = event.target.name
        const isChecked = event.target.checked
        // const indexOfArray = event.target.id

        // if (!event.shiftKey) {
            if (!isChecked && selectedEditProjectId) {
                const savedHoursResponse = await checkForSavedHoursWithAttributes({ optionName, attributeIds: [propId], selectedEditProjectId })
                if (savedHoursResponse.error) {
                    setErrorMessage(savedHoursResponse)
                    return
                }

                if (savedHoursResponse) {
                    return setErrorMessage({error: `This ${optionName} has filled hours in current project.`})  
                }
                
                setIsConfirmationShown(true)
                setCurrentPropId(propId)
                setIsCurrentOptionChecked(isChecked)
                setCurrentMessage(`Are you sure you want to delete ${name}`)
                return
            }

            setSelectedOption({ optionName, propId: new Set([propId]), isChecked })
            shiftSelection.current = {}
            return
        // }

        // if (!Object.keys(shiftSelection.current).length || shiftSelection.current.isChecked !== isChecked) {
        //     markInputAsChecked(event.target.parentElement)

        //     const container = event.target.parentElement
        //     const parrentElement = container.parentElement
        //     shiftSelection.current = { projectId: propId, isChecked: isChecked, firstIndex: Number(indexOfArray), parrentElement }
        //     return
        // }

        // if (isChecked) {
        //     const [startIndex, endIndex] = getSortedNumbersAscending(shiftSelection.current.firstIndex, Number(indexOfArray))
        //     const slicedSelection = listOfUnfilteredOptions.slice(startIndex, endIndex + 1)
        //     const selectedIds = slicedSelection.map(p => Number(p[0]))
        //     shiftSelection.current = {}

        //     setSelectedOption({ optionName, propId: new Set(selectedIds), isChecked })
        //     return
        // }

        // const [startIndex, endIndex] = getSortedNumbersAscending(shiftSelection.current.firstIndex, Number(indexOfArray))
        // shiftSelection.current = {...shiftSelection.current, secondIndex: Number(indexOfArray)}
        // const slicedSelection = listOfSelectedOptionOnState.slice(startIndex, endIndex + 1)
        
        // const savedHoursResponse = await checkForSavedHoursWithAttributes({ optionName, attributeIds: [slicedSelection], selectedEditProjectId })
        // if (savedHoursResponse.error) {
        //     setErrorMessage(savedHoursResponse)
        //     return
        // }
        // if (savedHoursResponse) {
        //     const getChildElements = Array.from(shiftSelection.current.parrentElement.children)
            
        //     for (let index = shiftSelection.current.firstIndex; index <= shiftSelection.current.secondIndex; index++) {
        //         const checkbox = getChildElements[index]
        //         markInputAsChecked(checkbox)
        //     }

        //     return setErrorMessage({error: `One or more ${pluralizedOptionName} have filled hours in current project.`})  
        // }
        
        // shiftSelection.current = {}
        // setSelectedOption({ optionName, propId: new Set(slicedSelection), isChecked })
    }

    const handleConfirm = () => {
        setSelectedOption({ optionName, propId: new Set([currentPropId]), isCurrentOptionChecked })
        shiftSelection.current = {}
        setIsConfirmationShown(false)
    }

    return (
        <div className={styles.container}>
            <div className={styles['filter-input']}>
                <label htmlFor={optionName} className={styles.filter}>Filter {pluralizedOptionName}:</label>
                <input
                    id={optionName}
                    autoComplete='off'
                    value={filterValue}
                    onChange={setFilter}
                    className={styles.input}
                />
            </div>
            <div className={styles.lists}>
                <div className={styles.list}>
                    <div className={styles['list-label']}>List of {pluralizedOptionName}:</div>
                    { listOfUnfilteredOptions?.length ? (
                        <div className={styles['list-options']}>
                            <List
                                height={302}
                                width={302}
                                itemCount={listOfUnfilteredOptions.length}
                                itemSize={30}
                                className={styles['checkbox-list']}
                                itemData={{ 
                                    options: listOfUnfilteredOptions, 
                                    handleChange, 
                                    handleMouseEnter, 
                                    handleMouseLeave, 
                                    columnName: attColumnNames.name,
                                    columnId: attColumnNames.id 
                                }}
                            >
                                {Row}
                            </List>
                        </div>
                    ) : null}
                </div>
                <div className={styles.list}>
                    <div className={styles['list-label']}>List of selected {pluralizedOptionName}:</div>
                    <div className={styles['list-options-selected']}>
                        {Object.keys(listOfOptions).length !== 0 &&
                            listOfOptions.constructor === Object &&
                            listOfSelectedOptionOnState.map((o, index) => {
                                const value = listOfOptions[o]
                                return (
                                    <CheckBox
                                        key={`${value[attColumnNames.name]}_zone_label`}
                                        value={value[attColumnNames.name]}
                                        name={value[attColumnNames.id]}
                                        checked={true}
                                        index={index}
                                        onChange={(e) => handleChange(e, value[attColumnNames.name])}
                                        onMouseEnter={(e) => handleMouseEnter(e, index, true)}
                                        onMouseLeave={(e) => handleMouseLeave(e, true)}
                                    />
                                )
                            })}
                    </div>
                    {isConfirmationShown && <ConfirmationDialogBox
                        text={currentMessage}
                        onConfirm={handleConfirm}
                        onCancel={() => setIsConfirmationShown(false)}
                    />}
                </div>
                <AddManualProjectOption
                    setManualOption={setManualOption}
                    manualOption={manualOption}
                    optionName={optionName}
                    listOfOptions={new Set(Object.values(listOfOptions).map(option => option[attColumnNames.name]))}
                />
            </div>
        </div>
    )
})

function Row({ index, style, data }) {
    const { 
        options, 
        handleChange, 
        handleMouseEnter, 
        handleMouseLeave, 
        columnName,
        columnId 
    } = data
    const row = options[index][1]
    
    return (
        <CheckBox
            key={index}
            value={row[columnName]}
            name={row[columnId]}
            checked={false}
            index={index}
            onChange={handleChange}
            onMouseEnter={(e) => handleMouseEnter(e, index, false)}
            onMouseLeave={(e) => handleMouseLeave(e, false)}
            containerStyle={style}
        />
    )
}

export default AddProjectOptions