import React from 'react'
import styles from './index.module.css'

const InputUser = ({ value, type, handleChange, isDisabled, placeholder, customClass }) => {
    return (
        <input
            className={`${styles.input} ${customClass}`}
            value={value}
            onChange={(e) => handleChange(e, type)}
            disabled={isDisabled}
            placeholder={placeholder}
            title={value}
        />
    )
}

export default InputUser
