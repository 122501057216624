import React, { useRef, useEffect, useState } from 'react'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import useProject from '../../../hooks/useProject'
import ProjectOptions from '../ProjectOptions'
import useProjectServices from '../../../services/project'
import CopyProject from './CopyProject'
import Input from '../../Inputs/Input'
import CheckBox from '../../CheckBox'
import Button from '../../Button'
import styles from './index.module.css'
import { deepCopy } from '../../../utils/deepCopy'
import useError from '../../../hooks/useError'
import AttributeInputDropdown from '../AttributeInputDropdown'

let timeout

const AddProject = () => {
    const {
        projectState,
        option,
        isProjectStateFilled,
        setProjectDispatch,
        setOption,
        fillProjectDispatch,
        setManualOption,
        setOptionVariable,
        setCopiedProject,
        handleChangeAddProject,
    } = useProject()
    const { setErrorMessage } = useError()
    const errorRef = useRef(null)
    const [clientInput, setClientInput] = useState({ isOnFocus: false, currentInputValue: '' })
    const { tasksState: { clients }, addProjectFromNewSaveInDB } = useContextTimeCard()
    const { addProject } = useProjectServices()
    const isComponentLoaded = useRef(true)

    const isClientInputBad = 
        clients && 
        clientInput.currentInputValue && 
        !clients.find(c => c.clientname === clientInput.currentInputValue)

    useEffect(() => {
        return () => {
            clearTimeout(timeout)
            isComponentLoaded.current = false
        }
    }, [])

    const handleSubmit = async () => {
        if (!projectState.project.name) {
            return setErrorMessage({ error: 'Project name can not be empty' })
        }

        if (!clientInput.currentInputValue) {
            return setErrorMessage({ error: 'Client must be selected' })
        }

        if (isClientInputBad) {
            return setErrorMessage({ error: 'Invalid client name' })
        }

        const preparedCopiedState = deepCopy(projectState, { convertSetToArray: true })
        const optionsForSetInProject = {
            ...(preparedCopiedState.zones && { zones: preparedCopiedState.zones }),
            ...(preparedCopiedState.systems && { systems: preparedCopiedState.systems }),
            ...(preparedCopiedState.jobs && { jobs: preparedCopiedState.jobs }),
            ...(preparedCopiedState.revisions && { revisions: preparedCopiedState.revisions }),
            ...(preparedCopiedState.activities && { activities: preparedCopiedState.activities }),
            ...(preparedCopiedState.leads && { leads: preparedCopiedState.leads }),
        }

        if (
            !Object.keys(optionsForSetInProject).length && 
            !Object.keys(preparedCopiedState.create).length
        ) {
            return setErrorMessage({ error: 'Cannot create an empty project' })
        }

        const preparedProjectStateForDb = {
            project: preparedCopiedState.project,
            existingAttributes: optionsForSetInProject,
            newAttributes: preparedCopiedState.create,
        }

        const response = await addProject(preparedProjectStateForDb)
        if (!isComponentLoaded.current) return

        if (response.error) {
            setErrorMessage({ error: 'An error occurred while trying to save the project' })
            return
        }

        fillProjectDispatch({ type: 'resetState' })
        setOption([])
        setClientInput({ isOnFocus: false, currentInputValue: '' })
        addProjectFromNewSaveInDB(response)
    }

    return (
        <div className={styles.form}>
            <div className={styles.title}>
                <span>Add project</span>
                <Button onClick={handleSubmit} title='SAVE PROJECT' />
            </div>
            <div className={styles['new-container']}>
                <div className={styles['one-line-field']}>
                    <div className={styles['name-field']}>
                        Project name
                        <span className={styles.required}>/required/</span>
                    </div>
                    <Input
                        type='text'
                        name='name'
                        placeholder='Project name'
                        value={projectState.project.name}
                        onChange={e => setProjectDispatch({ type: 'setProjectInfoName', value: e.target.value })}
                        inputStyle={styles.input}
                    />
                </div>
                <div className={styles['one-line-field']}>
                    <div className={styles['name-field']}>
                        Additional info
                        <span className={styles.required}>/not required/</span>
                    </div>
                    <Input
                        type='text'
                        name='more'
                        placeholder='Additional info'
                        value={projectState.project.more}
                        onChange={(e) =>
                            setProjectDispatch({ type: 'setProjectInfoMore', value: e.target.value })
                        }
                        inputStyle={styles.input}
                    />
                </div>
                <div className={styles['one-line-field']}>
                    <label htmlFor='client' className={styles['client-text']}>
                        Clients:
                    </label>
                    <AttributeInputDropdown
                        attributeName='client'
                        inputOptions={clients}
                        placeholder='Select client'
                        currentInputValue={clientInput.currentInputValue}
                        isOnFocus={clientInput.isOnFocus}
                        isInputDropdownBad={isClientInputBad}
                        setValue={(value) =>
                            setClientInput((ci) => ({ ...ci, currentInputValue: value }))
                        }
                        setOption={(value) =>
                            setProjectDispatch({ type: 'setProjectInfoClientId', value: value.clientid })
                        }
                        setIsOnFocus={(value) =>
                            setClientInput((ci) => ({ ...ci, isOnFocus: value }))
                        }
                    />
                </div>
            </div>
            <div className={styles['option-container']}>
                <CheckBox
                    value='Do you want to copy an existing project?'
                    name='copyLabel'
                    checked={option.includes('Do you want to copy an existing project?')}
                    onChange={setOptionVariable}
                    isOptional={true}
                />
                {option.includes('Do you want to copy an existing project?') && (
                    <CopyProject
                        selectedClient={projectState.project.clientId}
                        setCopiedProject={setCopiedProject}
                        isProjectStateFilled={isProjectStateFilled}
                    />
                )}
            </div>
            <ProjectOptions
                action='Add'
                options={option}
                setOptionVariable={setOptionVariable}
                setSelectedOption={handleChangeAddProject}
                setManualOption={setManualOption}
                projectState={projectState}
            />
            <Button onClick={handleSubmit} title='SAVE PROJECT' />
            <div className={styles.error} ref={errorRef}></div>
        </div>
    )
}

export default AddProject
