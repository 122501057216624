import React, { useCallback, useEffect, useMemo, useState } from 'react'
import NavigationBar from '../../components/NavigationBar'
import User from '../../components/User'
import OldUser from '../../components/UserOld'
import UserOvertimeAndConfirmation from '../../components/UserOvertimeAndConfirmation'
import useError from '../../hooks/useError'
import useUserServices from '../../services/user'
import styles from './index.module.css'

const Users = () => {
    const [currentTab, setCurrentTab] = useState('confirmation')
    const [users, setUsers] = useState(null)
    const [oldUsers, setOldUsers] = useState(null)
    const [areOldUserShown, setAreOldUsersShown] = useState(false)
    const { setErrorMessage } = useError()
    const { getOldUsers, getCurrentUsers } = useUserServices()

    const fetchUsersWithOvertime = useCallback(async () => {
        const response = await getCurrentUsers()
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setUsers(response)
    }, [getCurrentUsers, setErrorMessage])

    useEffect(() => {
        fetchUsersWithOvertime()
    }, [fetchUsersWithOvertime])

    const usersByName = useMemo(() => {
        if (!users) return null

        return users.sort((a, b) => a.name.localeCompare(b.name))
    }, [users])

    const showOldUsers = async () => {
        if (!areOldUserShown && !oldUsers) {
            const usersResponse = await getOldUsers()

            if (usersResponse.error) {
                setErrorMessage(usersResponse)
                return
            }
            
            setOldUsers(usersResponse)
            setAreOldUsersShown(true)
            return
        }

        setAreOldUsersShown(!areOldUserShown)
    }

    return (
        <>
            <NavigationBar />
            <main className={styles.container}>
                <div className={styles.tabs}>
                    <div
                        onClick={() => setCurrentTab('confirmation')}
                        className={`${currentTab === 'confirmation' && styles.selected}`}
                    >
                        Overtime/Confirmation
                    </div>
                    <div 
                        onClick={() => setCurrentTab('details')}
                        className={`${currentTab === 'details' && styles.selected}`}
                    >
                        User Details
                    </div>
                </div>
                { currentTab === 'details' 
                    ? <div className={styles.users}>
                        <div className={`${styles.headers} ${styles.responsive}`}>
                            <div>Name</div>
                            <div>Username</div>
                            <div>Email</div>
                            <div>Role</div>
                            <div>Reminder</div>
                        </div>

                        {usersByName && usersByName.map(u => (
                            <User
                                key={u.userid}
                                initialUser={u}
                                setCurrentUsers={setUsers}
                                setOldUsers={setOldUsers}
                            />
                        ))}
                        <button onClick={showOldUsers} className={styles.button}>
                            {areOldUserShown ? 'Hide old users' : 'Show old users'}
                        </button>
                        {areOldUserShown && oldUsers && oldUsers.sort((a, b) => a.name.localeCompare(b.name)).map(u => (
                            <OldUser
                                key={u.userid}
                                user={u}
                                setCurrentUsers={setUsers}
                                setOldUsers={setOldUsers}
                            />
                        ))}
                    </div>
                    : <UserOvertimeAndConfirmation/>
                }
            </main>
        </>
    )
}

export default Users
