import React from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './index.css'
import { useContextTimeCard } from '../../../contexts/timeCardContext'

const UsersCalendar = ({ setDayOfSelectedMonth }) => {
    const { tasksState } = useContextTimeCard()
    const { holidays } = tasksState

    const modifiers = {
        weekends: {
            daysOfWeek: [0, 6]
        },
        holidays: holidays.holidays,
        workingDays: holidays.workingDays
    }

    const handleOnMonthChange = (date) => {
        setDayOfSelectedMonth(date)
    }

    return (
        <div className='calendar'>
            <DayPicker
                showWeekNumbers
                showOutsideDays
                modifiers={modifiers}
                firstDayOfWeek={1}
                todayButton="This Month"
                onMonthChange={handleOnMonthChange}
            />
        </div>
    )
}

export default UsersCalendar
