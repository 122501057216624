import { useCallback } from 'react'
import { AUTH_COOKIE_NAME, HTTP_HEADERS, JSON_CONTENT_TYPE, REST_API_URL, ROWS_ON_PAGE } from '../utils/constants'
import { convertDateRangesToUTC } from '../utils/statistics'
import useUserServices from './user'

const STATISTICS_URL = REST_API_URL + '/statistics'

const useStatisticsServices = () => {
    const { verifyUserSession } = useUserServices()

    const getFilterData = useCallback(async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${STATISTICS_URL}/filter-data`, {
            method: 'GET',
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const getAllUsers = useCallback(async (areOldUsersIncluded) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${STATISTICS_URL}/users?areOldUsersIncluded=${areOldUsersIncluded}`, {
            method: 'GET'
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const getClientUsers =useCallback( async (clientIds, areOldUsersIncluded) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        // clientIds values are in [] because REST API expects them when we are passing multiple values
        // (this way it will automatically parse the values into an array)
        const response = await fetch(`${STATISTICS_URL}/users/clients?clientIds=[${clientIds.join(',')}]&areOldUsersIncluded=${areOldUsersIncluded}`, {
            method: 'GET',
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const getProjectUsers = useCallback(async (projectIds, areOldUsersIncluded) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${STATISTICS_URL}/users/projects?projectIds=[${projectIds.join(',')}]&areOldUsersIncluded=${areOldUsersIncluded}`, {
            method: 'GET',
        })
    
        return await response.json()
    }, [verifyUserSession])

    const getQuickReport = useCallback(async (conditions, groupingConditions) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }

        const convertedConditions = { ...conditions }
        convertedConditions.selectedRange = convertDateRangesToUTC(convertedConditions.selectedRange)
        
        const response = await fetch(`${STATISTICS_URL}/quick-report`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({
                conditions: convertedConditions,
                groupingConditions
            })
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const getStatistics = async (selectedData, page = 1, pageSize = ROWS_ON_PAGE, returnCount = true) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const convertedSelectedData = { ...selectedData }
        convertedSelectedData.selectedRange = convertDateRangesToUTC(convertedSelectedData.selectedRange)
    
        const { selectedRange } = convertedSelectedData
        const isLastRangeInvalid =
            selectedRange.length > 0 &&
            Object.values(selectedRange[selectedRange.length - 1])
                .map((s) => new Date(s))
                .some((d) => d.getTime() === 0)
        if (isLastRangeInvalid) {
            // in case a range entry was created on the front end, but a range was not selected
            // the last entry in selectedRange would consist of "from" and "to" equaling the earliest
            // possible date in JS (January 1 1970). In that case, we want to remove that entry
            selectedRange.pop()
        }
    
        const response = await fetch(`${STATISTICS_URL}`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify({
                conditions: convertedSelectedData,
                page,
                pageSize,
                returnCount
            })
        })
    
        return await response.json()
    }
    
    const getTotalHours = async (selectedData) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const convertedSelectedData = { ...selectedData }
        if (convertedSelectedData.selectedRange.length) {
            convertedSelectedData.selectedRange = convertDateRangesToUTC(convertedSelectedData.selectedRange)
        } else {
            delete convertedSelectedData.selectedRange
        }
    
        const response = await fetch(`${STATISTICS_URL}/total-hours`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify(convertedSelectedData),
        })
    
        return await response.json()
    }
    
    const getFullReport = async (selectedData) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const convertedSelectedData = { ...selectedData }
        convertedSelectedData.selectedRange = convertDateRangesToUTC(convertedSelectedData.selectedRange)
    
        const response = await fetch(`${STATISTICS_URL}/full-report`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify(convertedSelectedData),
        })
        
        return await response.json()
    }
    
    return {
        getFilterData,
        getAllUsers,
        getClientUsers,
        getProjectUsers,
        getStatistics,
        getTotalHours,
        getFullReport,
        getQuickReport,
    }
}

export default useStatisticsServices