import React, { useEffect, useState } from 'react'
import styles from './index.module.css'

const StatusField = ({ status, handleClick, keyString }) => {
  const { isconfirmed, islocked } = status
  const [text, setText] = useState()

  useEffect(() => {
    const newText = islocked 
                      ? 'Locked' 
                      : isconfirmed 
                        ? 'Confirmed' 
                        : 'Not Confirmed'

    setText(newText)
  }, [isconfirmed, islocked])

  const handleMouseEnter = () => {
    if (islocked) {
      setText('Unlock')
      return
    }

    setText('Lock')
  }

  const handleMouseLeave = () => {
    if (islocked) {
      setText('Locked')
      return
    }

    if (isconfirmed) {
      setText('Confirmed')
      return
    }

    setText('Not Confirmed')
  }

  return (
    <div
      className={`
        ${styles.status}
        ${islocked 
          ? styles.locked
          : isconfirmed
            ? styles.confirmed
            : styles['not-confirmed']
        }
      `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleClick(status, islocked, keyString)}
    >
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default StatusField
