const AUTH_COOKIE_NAME = 'x-expire-auth'
const JSON_CONTENT_TYPE = 'application/json'
const HTTP_HEADERS = {
    AUTHORIZATION: 'Authorization',
    CONTENT_TYPE: 'Content-Type',
}

const GUEST_USER = { username: '', userid: '', role: 'guest' }

const REST_API_URL = '/api'

const PROJECT_COLUMN_NAMES = {
    zone: 'zones',
    system: 'systems',
    job: 'jobs',
    revision: 'revisions',
    activity: 'activities',
    lead: 'leads',
}

const WORKING_SHIFT = 8

const XLSX_COLUMNS = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
]

const ROWS_ON_PAGE = 20

const ESCAPE_KEY_CODE = 27
const ENTER_KEY_CODE = 13
const ARROW_DOWN_KEY_CODE = 40
const ARROW_UP_KEY_CODE = 38

const SERVER_ERROR_MESSAGE_DURATION_MS = 8000

export {
    GUEST_USER,
    AUTH_COOKIE_NAME,
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    PROJECT_COLUMN_NAMES,
    WORKING_SHIFT,
    XLSX_COLUMNS,
    ROWS_ON_PAGE,
    ESCAPE_KEY_CODE,
    ENTER_KEY_CODE,
    ARROW_DOWN_KEY_CODE,
    ARROW_UP_KEY_CODE,
    SERVER_ERROR_MESSAGE_DURATION_MS,
}
