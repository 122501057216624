import React from 'react'
import WindowContainer from '../../../WindowContainer'
import Button from '../../../Button'
const ConfirmClearTask = ({ hideWindow, setCopyProject }) => {
    const handleOnClick = () => {
        hideWindow()
        setCopyProject()
    }
    return (
       <WindowContainer hideWindow={hideWindow}>
           <div>All completed fields will be cleared</div>
           <Button title='Confirm' onClick={handleOnClick} />
       </WindowContainer>
    )
}

export default ConfirmClearTask
