import React, { useMemo } from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import { ROWS_ON_PAGE } from '../../utils/constants'
import { formatDate } from '../../utils/date'
import Pagination from '../Pagination'
import StatisticsResultsHeader from '../StatisticsResultsHeader'
import styles from './index.module.css'

const StatisticsResults = () => {
    const { statisticsState } = useContextStatistics()
    const { statisticsResults, resultsTotalCount, resultsTotalHours } = statisticsState

    const totalHours = useMemo(() => {
        if (!statisticsResults) return

        return statisticsResults.reduce((total, value) => {
            return total += value.hours
        }, 0)
    }, [statisticsResults])

    const shouldShowPagination = resultsTotalCount && resultsTotalCount > ROWS_ON_PAGE

    if (!statisticsResults) return null

    return (
        <div className={styles.container} id='statistics-results'>
            { shouldShowPagination ? <Pagination/> : null }
            <div className={styles.row}>
                <StatisticsResultsHeader type='date' text='Date' style={`${styles.date} ${styles.data}`} />
                <StatisticsResultsHeader type='created' text='Created' style={`${styles.date} ${styles.data}`} />
                <StatisticsResultsHeader type='clientname' text='Client' style={styles.data} />
                <StatisticsResultsHeader type='projectname' text='Project' style={styles.data} />
                <StatisticsResultsHeader type='jobname' text='Job' style={styles.data} />
                <StatisticsResultsHeader type='zonename' text='Zone' style={styles.data} />
                <StatisticsResultsHeader type='systemname' text='System' style={styles.data} />
                <StatisticsResultsHeader type='leadname' text='Lead' style={styles.data} />
                <StatisticsResultsHeader type='activityname' text='Activity' style={styles.data} />
                <StatisticsResultsHeader type='revisionname' text='Revision' style={styles.data} />
                <StatisticsResultsHeader type='name' text='User' style={styles.data} />
                <div className={`${styles.hours} ${styles.data}`}>Hours</div>
            </div>
            {statisticsResults.map((row, rowIndex) => (
                <div 
                    key={row.timecardid} 
                    className={`${styles.row} ${styles.results} ${rowIndex % 2 === 0 && styles['row-color']}`}
                >
                    <div className={`${styles.date} ${styles.data}`}>{formatDate(new Date(row.date), '%d %b %Y')}</div>
                    <div className={`${styles.date} ${styles.data}`}>{formatDate(new Date(row.created), '%d %b %Y')}</div>
                    <div title={row.clientname} className={styles.data}>{row.clientname}</div>
                    <div title={row.projectname} className={styles.data}>{row.projectname}</div>
                    <div title={row.jobname} className={styles.data}>{row.jobname}</div>
                    <div title={row.zonename} className={styles.data}>{row.zonename}</div>
                    <div title={row.systemname} className={styles.data}>{row.systemname}</div>
                    <div title={row.leadname} className={styles.data}>{row.leadname}</div>
                    <div title={row.activityname} className={styles.data}>{row.activityname}</div>
                    <div title={row.revisionname} className={styles.data}>{row.revisionname}</div>
                    <div title={row.name} className={styles.data}>{row.name}</div>
                    <div className={`${styles.hours} ${styles.data}`}>{row.hours}</div>
                </div>
            ))}
            <div className={styles.total}>Total hours: {resultsTotalHours || totalHours}</div>
            { shouldShowPagination ? <Pagination/> : null }
        </div>
    )
}

export default StatisticsResults
