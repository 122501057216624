import React from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import styles from './index.module.css'

const StatisticsSelectionByType = ({ type, selection }) => {
    const { removeSelectedProperty } = useContextStatistics()

    return (
        <div className={styles.container}>
            {Object.entries(selection).map(s => (
                <div key={s[0]} className={styles.option} >
                    <div title={s[1]} >{s[1]}</div>
                    <span onClick={() => removeSelectedProperty(type, s[0])} >X</span>
                </div>
            ))}
        </div>
    )
}

export default StatisticsSelectionByType
