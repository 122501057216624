const getInitialState = () => {
    const initialState = {
        project: {
            name: '',
            more: '',
            clientId: ''
        },
        zones: new Set(),
        systems: new Set(),
        jobs: new Set(),
        revisions: new Set(),
        activities: new Set(),
        leads: new Set(),
        create: {
            zones: new Set(),
            systems: new Set(),
            jobs: new Set(),
            revisions: new Set(),
            activities: new Set(),
            leads: new Set()
        }
    }

    return initialState
}

const reducer = (state, action) => {
    const { value, type } = action

    switch (type) {
        case 'setProjectInfoName':
            return { ...state, project: { ...state.project, name: value } }
        case 'setProjectInfoMore':
            return { ...state, project: { ...state.project, more: value } }
        case 'setProjectInfoClientId':
            return { ...state, project: { ...state.project, clientId: value } }
        case 'setCopiedProject':
            return {
                ...state,
                zones: value.zones.length ? new Set(value.zones.map(z => z.zoneid)) : new Set(),
                systems: value.systems.length ? new Set(value.systems.map(s => s.systemid)) : new Set(),
                jobs: value.jobs.length ? new Set(value.jobs.map(j => j.jobid)) : new Set(),
                revisions: value.revisions.length ? new Set(value.revisions.map(r => r.revisionid)) : new Set(),
                activities: value.activities.length ? new Set(value.activities.map(a => a.activityid)) : new Set(),
                leads: value.leads.length ? new Set(value.leads.map(l => l.leadid)) : new Set(),
                create: {
                    zones: new Set(),
                    systems: new Set(),
                    jobs: new Set(),
                    revisions: new Set(),
                    activities: new Set(),
                    leads: new Set()
                }
            }
        case 'setEditProject':
            return {
                ...state,
                project: {
                name: value.project.name,
                more: value.project.more,
                clientId: value.project.clientId},
                zones: value.zones.length ? new Set(value.zones.map(z => z.zoneid)) : new Set(),
                systems: value.systems.length ? new Set(value.systems.map(s => s.systemid)) : new Set(),
                jobs: value.jobs.length ? new Set(value.jobs.map(j => j.jobid)) : new Set(),
                revisions: value.revisions.length ? new Set(value.revisions.map(r => r.revisionid)) : new Set(),
                activities: value.activities.length ? new Set(value.activities.map(a => a.activityid)) : new Set(),
                leads: value.leads.length ? new Set(value.leads.map(l => l.leadid)) : new Set()
            }
        case 'setProjectZones':
            return { ...state, zones: value }
        case 'setManualZones':
            return { ...state, create: { ...state.create, zones: value }}
        case 'setProjectSystems':
            return { ...state, systems: value }
        case 'setManualSystems':
            return { ...state, create: { ...state.create, systems: value }}
        case 'setProjectJobs':
            return { ...state, jobs: value }
        case 'setManualJobs':
            return { ...state, create: { ...state.create, jobs: value }}
        case 'setProjectRevisions':
            return { ...state, revisions: value }
        case 'setManualRevisions':
            return { ...state, create: { ...state.create, revisions: value }}
        case 'setProjectActivities':
            return { ...state, activities: value }
        case 'setManualActivities':
            return { ...state, create: { ...state.create, activities: value }}
        case 'setProjectLeads':
            return { ...state, leads: value }
        case 'setManualLeads':
            return { ...state, create: { ...state.create, leads: value }}
        case 'resetState':
            return getInitialState()
        default:
            return state
    }
}

export {
    getInitialState,
    reducer
}