import React, { useState } from 'react'
import useError from '../../../hooks/useError'
import useProjectAttributeServices from '../../../services/projectAttribute'
import { capitalizeFirstLetter, pluralizeWord } from '../../../utils/text'
import InputDropdown from '../../Inputs/InputDropdown'
import styles from './index.module.css'

const editOptions = [
    'Client',
    'Project',
    'Zone',
    'System',
    'Job',
    'Revision',
    'Activity',
    'Lead',
]

const EditNames = () => {
    const [type, setType] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [optionForEdit, setOptionForEdit] = useState({
        userInput: '',
        selectedOption: null,
        isOnFocus: false,
        newValue: ''
    })
    const { getProjectAttributeNames, editAttributeName } = useProjectAttributeServices()
    const { setErrorMessage } = useError()
    const attributeColumnName = [`${type}name`]

    const handleSelectType = async (e) => {
        setType(e.target.value)
        setIsLoading(true)
        setOptionForEdit({
            userInput: '',
            selectedOption: null,
            isOnFocus: false,
            newValue: ''
        })
        setOptions([])

        const response = await getProjectAttributeNames([pluralizeWord(e.target.value)])
        setIsLoading(false)
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setOptions(Object.values(response)[0])
    }

    const handleSetUserInput = (value) => {
        setOptionForEdit(currentOption => {
            return { ...currentOption, userInput: value }
        })
    }

    const handleSetSelectedOption = (value) => {
        setOptionForEdit(currentOption => {
            return { ...currentOption, userInput: value[attributeColumnName], newValue: value[attributeColumnName], selectedOption: value }
        })
    }

    const handleSetIsOnFocus = (value) => {
        setOptionForEdit(currentOption => {
            return { ...currentOption, isOnFocus: value }
        })
    }

    const handleChangeNewValue = (e) => {
        setOptionForEdit(currOption => ({ ...currOption, newValue: e.target.value }))
    }

    const handleSave = async () => {
        if (!optionForEdit.newValue) {
            setErrorMessage({ error: `${capitalizeFirstLetter(type)} name can not be empty` })
        }

        const id = type + 'id'
        const response = await editAttributeName(type, optionForEdit.selectedOption[id], optionForEdit.newValue)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setOptionForEdit({
            userInput: '',
            selectedOption: null,
            isOnFocus: false,
            newValue: ''
        })
        setOptions([])
        setType('')
    }

    return (
        <div className={styles.container}>
            <div className={styles.text}>Edit Names</div>
            <div>
                <label htmlFor='type' >Type:</label>
                <select id='type' className={styles.select} onChange={handleSelectType} value={type}>
                    <option disabled value=''></option>
                    {editOptions.map((option, index) => (
                        <option key={index} value={option.toLowerCase()}>{option}</option>
                    ))}
                </select>
            </div>
            { isLoading
                ? <div className={styles.loading} >Loading...</div>
                : <div className={styles['input-container']}>
                    <div>
                        <label htmlFor='old-name' >Old name:</label>
                        <InputDropdown
                            value={optionForEdit.userInput}
                            setValue={handleSetUserInput}
                            setOption={handleSetSelectedOption}
                            setIsOnFocus={handleSetIsOnFocus}
                            isOnFocus={optionForEdit.isOnFocus}
                            inputOptions={options}
                            isInputDropdownBad={optionForEdit.userInput && !options.find(
                                option => optionForEdit.userInput === option[attributeColumnName]
                            )}
                            isDisabled={!type}
                            customContainerStyle={{ height: '1.8rem', flexGrow: 1 }}
                            customStyle={{ width: '100%', height: '100%' }}
                            type={type}
                            inputId='old-name'
                        />
                    </div>
                    <div className={styles['new-name-container']}>
                        <label htmlFor='new-name' >New name:</label>
                        <input
                            id='new-name'
                            disabled={!type}
                            className={styles['new-name']}
                            value={optionForEdit.newValue}
                            onChange={handleChangeNewValue}
                        />
                    </div>
                    <button
                        disabled={(!options.find(
                            option => optionForEdit.userInput === option[attributeColumnName]
                        )) || optionForEdit.userInput === optionForEdit.newValue
                        }
                        onClick={handleSave}
                        className={styles.button}
                    >Save</button>
                </div>
            }
        </div>
    )
}

export default EditNames
