import React, { useEffect, useState } from 'react'
import InputUser from '../../Inputs/InputUser'
import HoursInput from '../../Inputs/HoursInput'
import styles from './index.module.css'
import StatusField from '../StatusField'
import useOvertimeServices from '../../../services/overtime'
import useMonthConfirmationServices from '../../../services/monthConfirmation'
import useError from '../../../hooks/useError'

const UserRow = ({ initialUser, currentMonthWeeks, dayOfSelectedMonth }) => {
  const [user, setUser] = useState(initialUser)
  const { lockOvertime } = useOvertimeServices()
  const { lockUserMonth } = useMonthConfirmationServices()
  const { setErrorMessage } = useError()

  useEffect(() => {
    setUser(initialUser)
  }, [initialUser])

  const handleWeekStatusFieldClick = async (overtime, islocked, week) => {
    const newOvertime = { ...overtime, islocked: !islocked, week, hours: overtime.hours || 0 }
    const response = await lockOvertime(newOvertime, user.userid)

    if (response.error) {
      setErrorMessage(response)
      return
    }

    setUser({ ...user, overtimes: { ...user.overtimes, [newOvertime.week]: newOvertime } })
  }

  const handleMonthStatusFieldClick = async (monthConfirmation, islocked) => {
    const month = new Date(dayOfSelectedMonth).getMonth()
    const year = new Date(dayOfSelectedMonth).getFullYear()
    const newMonthConfirmation = { ...monthConfirmation, islocked: !islocked, month: `${year}-${month}` }
    const response = await lockUserMonth(newMonthConfirmation, user.userid)

    if (response.error) {
      setErrorMessage(response)
      return
    }

    setUser({ ...user, monthConfirmation: newMonthConfirmation })
  }

  return (
    <div>
      <div className={styles.container}>
        <InputUser
          value={user.name}
          type='name'
          isDisabled={true}
          customClass={styles['big-input']}
        />
        {currentMonthWeeks && currentMonthWeeks.map(wk => (
          <div key={wk} className={styles.wrapper}>
            <StatusField
              status={user?.overtimes[wk] || {}}
              keyString={wk}
              handleClick={handleWeekStatusFieldClick}
            />
            <div>
              <HoursInput
                value={user?.overtimes[wk]?.hours || ''}
                disabled={true}
                inputStyle={`
                  ${styles['small-input']}
                  ${user?.overtimes[wk]?.ischanged && styles.changed}
                `}
                title={`
                  ${user?.overtimes[wk]?.ischanged
                    ? `Overtime week ${wk.slice(5)} (changed manually)`
                    : `Overtime week ${wk.slice(5)}
                  `}
                `}
              />
            </div>
          </div>
        ))}
        <StatusField
          status={user?.monthConfirmation || {}}
          handleClick={handleMonthStatusFieldClick}
        />
      </div>
    </div>
  )
}

export default UserRow
