const pluralizeWord = (word) => {
    let pluralizedWord = word
    if (pluralizedWord[pluralizedWord.length - 1] === 'y') {
        pluralizedWord = pluralizedWord.slice(0, pluralizedWord.length - 1) + 'ies'
    } else {
        pluralizedWord += 's'
    }

    return pluralizedWord
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export {
    pluralizeWord,
    capitalizeFirstLetter,
}