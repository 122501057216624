import React from 'react'
import styles from './index.module.css'

const Button = ({ type, onClick, title, disabled = false }) => {
    return (
        <button type={type || 'submit'} onClick={onClick} className={styles.btn} disabled={disabled}>
            {title}
        </button>
    )
}

export default Button
