import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import useProject from '../../../hooks/useProject'
import useProjectServices from '../../../services/project'
import { arrangeProjectColumnData } from '../../../utils/userTasks'
import ProjectOptions from '../ProjectOptions'
import Button from '../../Button'
import styles from './index.module.css'
import { deepCopy } from '../../../utils/deepCopy'
import useError from '../../../hooks/useError'
import AttributeInputDropdown from '../AttributeInputDropdown'

let timeout

const EditProject = () => {
    const {
        projectState,
        option,
        updatedOptions,
        setOption,
        setProjectDispatch,
        setOptionVariable,
        setEditProject,
        handleChangeEditProject,
        setManualOptionEdit,
        resetUpdatedOptions
    } = useProject()
    const { tasksState: { clients, projectsColumnData }, setProjectColumnData, editProjectInDB } = useContextTimeCard()
    const { editProject, getProject } = useProjectServices()
    const [userInput, setUserInput] = useState({
        client: { isOnFocus: false, currentInputValue: '', currentClient: null },
        project: { isOnFocus: false, currentInputValue: '' },
    })
    const [projectInfo, setProjectInfo] = useState({})
    const [projectClient, setProjectClient] = useState({ isOnFocus: false, currentInputValue: '', currentClient: null })
    const [showNothingChangedNotification, setShowNothingChangedNotification] = useState(false)
    const selectedEditProjectId = useRef(null)
    const { setErrorMessage } = useError()
    const isComponentLoaded = useRef(true)
    const history = useHistory()

    useEffect(() => {
        return () => {
            clearTimeout(timeout)
            isComponentLoaded.current = false
        }
    }, [])


    const currentClient = userInput.client.currentClient
    const shouldShowProjectDetails = currentClient && projectState.project.name

    const setEditedProject = async (value) => {
        if (!value) return

        const selectedId = Number(value)
        selectedEditProjectId.current = selectedId

        const selectedProjectAttributes = currentClient.projects.find(p => p.projectid === selectedId)
        setProjectInfo({
            id: selectedId,
            name: selectedProjectAttributes.projectname, 
            more: selectedProjectAttributes.projectmore,
            clientId: selectedProjectAttributes.clientid
        })

        const initialSelectedProject = { 
            project: { 
                name: selectedProjectAttributes.projectname, 
                more: selectedProjectAttributes.projectmore, 
                clientId: selectedProjectAttributes.clientid 
            } 
        }
        
        if (projectsColumnData[selectedId]) {
            const selectedProjectOptionFilled = projectsColumnData[selectedId]
            setEditProject({ 
                ...initialSelectedProject, 
                ...selectedProjectOptionFilled 
            })
        } else {
            const response = await getProject(selectedId)
            if (!isComponentLoaded.current) return

            if (response.error) {
                setErrorMessage(response)
                return
            }
            
            const newProjectColumnData = arrangeProjectColumnData(response)

            setProjectColumnData(newProjectColumnData, selectedId)
            setEditProject({ ...initialSelectedProject, ...newProjectColumnData })
        }
    }

    const isProjectOptionChanged = (value) => {
        let optionsLength = []
        const objectValues = Object.values(value)

        objectValues.forEach(o => {
            const keys = Object.keys(o)
            optionsLength.push(keys)
        })

        if ( optionsLength.flat().length > 0) {
            return true
        }

        return false
    }

    const displayNothingChangedNotification = () => {
        setShowNothingChangedNotification(true)

        timeout = setTimeout(() => {
            setShowNothingChangedNotification(false)
        }, 7000)
    }

    const handleSubmit = async () => {
        let preparedEditProject = deepCopy(updatedOptions, { convertSetToArray: true})

        if (
            projectState.project.name === projectInfo.name && 
            projectState.project.more === projectInfo.more && 
            projectState.project.clientId === projectInfo.clientId && 
            !isProjectOptionChanged(preparedEditProject)
        ) {
            displayNothingChangedNotification()
            return
        }

        preparedEditProject.currentProject = projectState.project
        preparedEditProject.projectId = projectInfo.id
        // delete preparedEditProject.currentProject.clientId

        const response = await editProject(preparedEditProject)
        if (!isComponentLoaded.current) return

        if (response.error) {
            setErrorMessage(response)
            return
        }

        editProjectInDB(response)
        const { newProjectColumnData } = arrangeProjectColumnData(response)
        setProjectColumnData(newProjectColumnData, projectInfo.id)
        setProjectDispatch({ type: 'resetState' })
        setOption([])
        resetUpdatedOptions()
        setShowNothingChangedNotification(false)
        setProjectClient({ isOnFocus: false, currentInputValue: '', currentClient: null })
        setUserInput({
            client: { isOnFocus: false, currentInputValue: '', currentClient: null },
            project: { isOnFocus: false, currentInputValue: '' }
        })

        if (projectState.project.clientId !== projectInfo.clientId) {
            // refresh page to update client projects data
            history.go(0)
        }
    }

    const setProjectOption = (projectOption) => {
        const currentProject = currentClient.projects.find(p => p.projectname === projectOption.projectname)
        if (!currentProject) {
            return setErrorMessage('Project not found')
        }

        setEditedProject(currentProject.projectid)
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <span>Edit project</span>
                {showNothingChangedNotification && 
                    <div className={styles['nothing-changed']}>Nothing is changed</div>
                }

                { shouldShowProjectDetails &&
                    <Button onClick={handleSubmit} title='SAVE PROJECT' />
                }
            </div>
            <div className={styles.filters}>
                <h3 className={styles['filters-heading']}>Select project to edit:</h3>
                <div className={styles['select-fields']}>
                    <label htmlFor='client' className={styles.labels}>Client:</label>
                    <AttributeInputDropdown
                        attributeName='client'
                        placeholder='Select client'
                        currentInputValue={userInput.client.currentInputValue}
                        setValue={(value) => {
                            setUserInput(ci => ({ ...ci, client: { ...ci.client, currentInputValue: value }}))}
                        }
                        setOption={(value) => {
                            setProjectClient(ci => ({ ...ci, currentClient: value, currentInputValue: value.clientname }))
                            setUserInput(ci => ({ ...ci, client: { ...ci.client, currentClient: value }}))}
                        }
                        setIsOnFocus={(value) => 
                            setUserInput(ci => ({ ...ci, client: { ...ci.client, isOnFocus: value }}))}
                        isOnFocus={userInput.client.isOnFocus}
                        inputOptions={clients}
                    />

                    <label htmlFor='projects' className={styles.labels}>Project:</label>
                    <AttributeInputDropdown
                        attributeName='project'
                        placeholder='Select project'
                        currentInputValue={userInput.project.currentInputValue}
                        setValue={(value) => 
                            setUserInput(ci => ({ ...ci, project: { ...ci.project, currentInputValue: value }}))}
                        setOption={setProjectOption}
                        setIsOnFocus={(value) => 
                            setUserInput(ci => ({ ...ci, project: { ...ci.project, isOnFocus: value }}))}
                        isOnFocus={userInput.project.isOnFocus}
                        inputOptions={currentClient ? currentClient.projects : []}
                        isDisabled={!currentClient}
                    />
                </div>
            </div>
            { shouldShowProjectDetails &&
                <>
                    <h3>Update project info</h3>
                    <div className={styles.info}>
                        <div className={styles['one-line-field']}>
                            <div className={styles['name-field']}>Project client<span className={styles.required}>/required/</span></div>
                            <AttributeInputDropdown
                                attributeName='client'
                                placeholder='Select client'
                                currentInputValue={projectClient.currentInputValue}
                                setValue={(value) => 
                                    setProjectClient(ci => ({ ...ci, currentInputValue: value }))}
                                setOption={(value) => {
                                    setProjectClient(ci => ({ ...ci, currentClient: value }))
                                    setProjectDispatch({ type: 'setProjectInfoClientId', value: value.clientid })
                                }}
                                setIsOnFocus={(value) => 
                                    setProjectClient(ci => ({ ...ci, isOnFocus: value }))}
                                isOnFocus={projectClient.isOnFocus}
                                inputOptions={clients}
                            />
                        </div>
                        <div className={styles['one-line-field']}>
                            <div className={styles['name-field']}>Project name<span className={styles.required}>/required/</span></div>
                            <input
                                type='text'
                                name='name'
                                placeholder='Name of project'
                                value={projectState.project.name}
                                onChange={e => setProjectDispatch({ type: 'setProjectInfoName', value: e.target.value })}
                            />
                        </div>
                        <div className={styles['one-line-field']}>
                            <div className={styles['name-field']}>Additional info<span className={styles.required}>/not required/</span></div>
                            <input
                                type='text'
                                name='more'
                                placeholder='Additional info'
                                value={projectState.project.more}
                                onChange={e => setProjectDispatch({ type: 'setProjectInfoMore', value: e.target.value })}
                            />
                        </div>
                    </div>
                    <ProjectOptions
                        action='Edit'
                        options={option}
                        setOptionVariable={setOptionVariable}
                        setSelectedOption={handleChangeEditProject}
                        setManualOption={setManualOptionEdit}
                        projectState={projectState}
                        updatedOptions={updatedOptions.create}
                        selectedEditProjectId={selectedEditProjectId.current}
                    />
                    <Button onClick={handleSubmit} title='SAVE PROJECT' />
                    {showNothingChangedNotification && 
                        <div className={styles['nothing-changed']}>Nothing is changed</div>
                    }
                </>
            }
        </div>
    )
}

export default EditProject