import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import NavigationLink from '../NavigationLink'
import styles from './index.module.css'
import logo from '../../public/images/smart-design.png'
import { useContextSession } from '../../contexts/sessionContext'
import useUserServices from '../../services/user'
import { useContextTimeCard } from '../../contexts/timeCardContext'
import ConfirmationDialogBox from '../ConfirmationDialogBox'
import useError from '../../hooks/useError'
import { GUEST_USER } from '../../utils/constants'

const NavigationBar = () => {
    const [isConfirmationShown, setIsConfirmationShown] = useState(false)
    const { setErrorMessage } = useError()
    const { user, setUser } = useContextSession()
    const { setSelectedDays, setSavedTimeCard, tasksState } = useContextTimeCard()
    const { tasks } = tasksState
    const history = useHistory()
    const { logoutUser } = useUserServices()

    const message = 'Are you sure you want to logout? You have unsaved hour inputs.'

    const logout = () => {
        const allTasks = Object.values(tasks).flat()
        const areHoursEntered = allTasks.find(t => !!Object.keys(t.hours).length)

        if (areHoursEntered) {
            setIsConfirmationShown(true)
            return
        }

        handleConfirm()
    }

    const handleConfirm = async () => {
        const response = await logoutUser()
        if (response.error) {
            setErrorMessage(response)
            return 
        }
        
        setUser(GUEST_USER)
        history.push('/')
        setSelectedDays([])
        setSavedTimeCard({})
    }

    return (
        <nav className={styles.container}>
            <div className={styles.logo}>
                <img src={logo} alt='' className={styles.pic} />
                <div className={styles['logo-text']}>SMART DESIGN 2006</div>
            </div>
            {user.role === 'admin' && <NavigationLink name={'Add'} path={'/admin'} />}
            {user.role === 'admin' && <NavigationLink name={'Edit'} path={'/admin/edit'} />}
            {user.role === 'admin' && <NavigationLink name={'Users'} path={'/users'} />}
            {(user.role === 'admin' || user.role === 'statistics') && <NavigationLink name={'Statistics'} path={'/statistics'} />}
            {(user.role === 'admin' || user.role === 'statistics') && <NavigationLink name={'Time card'} path={'/time-card'} />}
            <div className={styles.logout}>
                <div className={styles.user}>{user.name}</div>
                <button className={styles.button} onClick={logout} >Logout</button>
            </div>
            {isConfirmationShown && <ConfirmationDialogBox
                text={message}
                onConfirm={handleConfirm}
                onCancel={() => setIsConfirmationShown(false)}
            />}
        </nav>
    )
}

export default NavigationBar
