const getDescriptionStyle = (isFirstRowOfDate) => {
    if (isFirstRowOfDate) {
        return {
            alignment: { wrapText: true },
            border: {
                top: { style: 'medium', color: { auto: 1 } },
                right: { style: 'thin', color: { auto: 1 } }
            }
        }
    }
    
    return {
        alignment: { wrapText: true },
        border: {
            top: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } }
        }
    }
}

const getHoursStyle = (isFirstRowOfDate) => {
    if (isFirstRowOfDate) {
        return {
            alignment: {
                horizontal: 'center',
                vertical: 'top'
            },
            border: {
                top: { style: 'medium', color: { auto: 1 } },
                right: { style: 'medium', color: { auto: 1 } }
            }
        }
    }
    
    return {
        alignment: {
            horizontal: 'center',
            vertical: 'top'
        },
        border: {
            top: { style: 'thin', color: { auto: 1 } },
            right: { style: 'medium', color: { auto: 1 } }
        }
    }
}

const dateHeaderStyleTop = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    border: {
        top: { style: 'medium', color: { auto: 1 } },
        left: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } }
    }
}

const dateHeaderStyleBottom = {
    border: {
        left: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } },
        bottom: { style: 'medium', color: { auto: 1 } }
    }
}

const userHeaderStyle = {
    font: { bold: true },
    border: {
        top: { style: 'medium', color: { auto: 1 } }
    }
}

const userHeaderHoursStyle = {
    border: {
        top: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } }
    }
}

const descriptionHeaderStyle = {
    font: { bold: false },
    border: {
        top: { style: 'thin', color: { auto: 1 } },
        right: { style: 'thin', color: { auto: 1 } },
        bottom: { style: 'medium', color: { auto: 1 } }
    }
}

const hoursHeaderStyle = {
    font: { bold: false },
    alignment: { horizontal: 'center' },
    border: {
        top: { style: 'thin', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } },
        bottom: { style: 'medium', color: { auto: 1 } }
    }
}

const userTotalHoursTextStyle = {
    font: { bold: false },
    alignment: { horizontal: 'right' },
    border: {
        top: { style: 'medium', color: { auto: 1 } },
    }
}

const userTotalHoursStyle = {
    alignment: { horizontal: 'center' },
    border: {
        top: { style: 'medium', color: { auto: 1 } },
        left: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } },
        bottom: { style: 'medium', color: { auto: 1 } },
    }
}

const userTotalHoursDateStyle = {
    border: {
        top: { style: 'medium', color: { auto: 1 } }
    }
}

const totalHoursTextStyle = {
    font: { bold: true },
    alignment: { horizontal: 'right' }
}

const totalHoursStyle = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    border: {
        top: { style: 'medium', color: { auto: 1 } },
        left: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } },
        bottom: { style: 'medium', color: { auto: 1 } }
    }
}

const dateStyle = {
    alignment: {
        horizontal: 'right',
        vertical: 'top'
    },
    border: {
        top: { style: 'medium', color: { auto: 1 } },
        left: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } }
    }
}

const emptyDateStyle = {
    border: {
        left: { style: 'medium', color: { auto: 1 } },
        right: { style: 'medium', color: { auto: 1 } }
    }
}

const boldStyle = {
    font: { bold: true },
}

export {
    getDescriptionStyle,
    getHoursStyle,
    dateHeaderStyleTop,
    dateHeaderStyleBottom,
    userHeaderStyle,
    userHeaderHoursStyle,
    descriptionHeaderStyle,
    hoursHeaderStyle,
    userTotalHoursTextStyle,
    userTotalHoursStyle,
    userTotalHoursDateStyle,
    totalHoursTextStyle,
    totalHoursStyle,
    dateStyle,
    emptyDateStyle,
    boldStyle
}
