import { useCallback } from 'react'
import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import { localToUtc, utcToLocal } from '../utils/date'
import useUserServices from './user'

const TIME_CARD_URL = REST_API_URL + '/time-card'

const useTimeCardServices = () => {
    const { verifyUserSession } = useUserServices()

    const getTimeCard = useCallback(async (selectedDays, weekKey) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const start = localToUtc(selectedDays[0])
        const end = localToUtc(selectedDays[selectedDays.length - 1])
    
        const response = await fetch(`${TIME_CARD_URL}/get/${start}/${end}/${weekKey}`, {
            method: 'GET',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            }
        })
    
        const timeCardResponse = await response.json()
        const { timeCard } = timeCardResponse
    
        if (timeCard) {
            timeCardResponse.timeCard = timeCard.map(tc => ({ ...tc, date: utcToLocal(tc.date) }))
        }
    
        return timeCardResponse
    }, [verifyUserSession])

    const saveTimeCard = async(tasks, selectedDays, timeCardsForUpdate) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${TIME_CARD_URL}/save`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({
                tasks,
                selectedDays: selectedDays.map(sd => localToUtc(sd)),
                timeCardsForUpdate
            })
        })
    
        return await response.json()
    }
    
    const editTimeCards = async (tasks, week) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${TIME_CARD_URL}/edit`, {
            method: 'PATCH',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ tasks, week })
        })
    
        return await response.json()
    }

    const deleteTimeCards = async (taskIds, week) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${TIME_CARD_URL}/delete`, {
            method: 'DELETE',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ taskIds, week })
        })
    
        return await response.json()
    }
    
    return {
        saveTimeCard,
        getTimeCard,
        editTimeCards,
        deleteTimeCards,
    }
}

export default useTimeCardServices
