import { PROJECT_COLUMN_NAMES, WORKING_SHIFT } from './constants'
import { checkDateEquality } from './date'

const getNewTask = () => {
    const newTask = {
        taskid: '',
        hours: {},
        isSaved: false
    }

    return newTask
}

const getNewProject = () => {
    const newProject = {
        projectname: '',
        projectid: '',
        has_jobs: true,
        has_zones: true,
        has_systems: true,
        has_activities: false,
        has_leads: false,
        has_revisions: false
    }

    return newProject
}

const getNewClient = () => {
    const newClient = {
        clientname: '',
        clientid: '',
        projects: [],
        selectedProjects: [getNewProject()]
    }

    return newClient
}

const initialState = {
    clients: [],
    selectedClients: [],
    unselectedClients: [],
    tasks: {},
    projectsColumnData: {},
    selectedDays: [],
    selectedMonth: '',
    areTasksOk: {
        tasks: true,
        hours: true
    },
    savedTimeCard: {},
    updatedTimeCardIds: [],
    holidays: {
        holidays: [],
        workingDays: []
    },
    overtime: {},
    monthConfirmation: {}
}

const arrangeClientsProjects = (projects, currClients) => {
    if (currClients && currClients.length) {
        const clients = [...currClients]

        for (const project of projects) {
            const currClient = clients.find(c => c.clientid === project.clientid)
            if (!currClient) {
                const newClient = {
                    clientid: project.clientid,
                    clientname: project.clientname,
                    projects: [project]
                }
                clients.push(newClient)

                continue
            }

            const projectIndex = currClient.projects.findIndex(p => p.projectid === project.projectid)
            let newProjects = [...currClient.projects]
            if (projectIndex === -1) {
                newProjects.push(project)
            } else {
                newProjects[projectIndex] = project
            }

            const newClient = { ...currClient, projects: newProjects }
            let index
            clients.find((c, i) => {
                index = i
                return c.clientid === project.clientid
            })

            clients[index] = newClient
        }

        return clients
    }

    const clients = []

    for (const project of projects) {
        let currClient = clients.find(c => c.clientid === project.clientid)
        if (!currClient) {
            currClient = {
                clientid: project.clientid,
                clientname: project.clientname,
                projects: []
            }
            clients.push(currClient)
        }

        currClient.projects.push(project)
    }

    return clients
}

const arrangeUserTasks = (tasks, clients) => {
    const newTasks = {}
    const newSelectedClients = []

    for (const task of tasks) {
        let currClient = newSelectedClients.find(c => c.clientid === task.clientid)
        if (!currClient) {
            currClient = clients.find(c => c.clientid === task.clientid)
            currClient.selectedProjects = []
            newSelectedClients.push(currClient)
        }

        let currProject = currClient.selectedProjects.find(p => p.projectid === task.projectid)
        if (!currProject) {
            currProject = currClient.projects.find(p => p.projectid === task.projectid)
            if (!currProject) {
                currProject = getNewProject()
            }
            currClient.selectedProjects.push(currProject)
        }

        const clientIndex = newSelectedClients.indexOf(currClient)
        const projectIndex = currClient.selectedProjects.indexOf(currProject)
        const taskKey = getTaskKey(clientIndex, projectIndex)
        if (!newTasks[taskKey]) {
            newTasks[taskKey] = []
        }

        task.isSaved = true
        task.hours = {}
        newTasks[taskKey].push(task)
    }

    return { newTasks, newSelectedClients }
}

const arrangeProjectColumnData = (projectData) => {
    const { activities, jobs, leads, revisions, systems, zones, lastchange } = projectData
    const newProjectColumnData = { activities, jobs, leads, revisions, systems, zones, lastchange }
    return newProjectColumnData
}

const getTaskKey = (clientIndex, projectIndex) => {
    return clientIndex + '-' + projectIndex
}

const getTaskIndexes = (taskKey) => {
    return taskKey.split('-')
}

const getTasksForSave = (tasksState, savedTimeCard) => {
    const tasks = Object.values(tasksState.tasks).flat()
    const tasksForSave = tasks.filter(t => Object.keys(t.hours).length)

    let areTasksCorrect = true
    let areHoursCorrect = true

    for (let i = 0; i < tasksForSave.length; i++) {
        const newTask = { ...tasksForSave[i] }
        const currClient = tasksState.clients.find(c => c.clientid === newTask.clientid)
        if (!currClient) {
            areTasksCorrect = false
            return { areTasksCorrect }
        }

        const currProject = currClient.projects.find(p => p.projectid === newTask.projectid)
        if (!currProject) {
            areTasksCorrect = false
            return { areTasksCorrect }
        }

        const columns = Object.keys(PROJECT_COLUMN_NAMES)
        for (const column of columns) {
            let columnPlural = PROJECT_COLUMN_NAMES[column]

            const hasColumn = 'has_' + columnPlural
            const id = column + 'id'
            const name = column + 'name'
            delete newTask[name]
            tasksForSave[i] = newTask

            const projectOptions = tasksState.projectsColumnData[currProject.projectid]

            if (currProject[hasColumn]) {
                const columnOptions = projectOptions[columnPlural]
                areTasksCorrect = columnOptions.find(o => o[id] === newTask[id])
            } else {
                // Deletes all attributes from usertasks that are left from other projects (see timeCardContext => selectProject)
                delete newTask[id]
                tasksForSave[i] = newTask
            }

            if (!areTasksCorrect) {
                return { areTasksCorrect }
            }
        }

        const badInputs = checkHours(Object.values(newTask.hours))
        if (badInputs) {
            areHoursCorrect = false
            return { areTasksCorrect, areHoursCorrect }
        }
    }

    const { newTasksForSave, timeCardsForUpdate } = checkTasksForSave(tasksForSave, savedTimeCard)

    return { tasksForSave: newTasksForSave, areTasksCorrect, areHoursCorrect, timeCardsForUpdate }
}

const arrangeSavedTasks = (savedTasks) => {
    const clients = {}
    for (const task of savedTasks) {
        let currClient = clients[task.clientid]
        if (!currClient) {
            currClient = {}
            clients[task.clientid] = currClient
        }

        let currProject = currClient[task.projectid]
        if (!currProject) {
            currProject = {}
            currClient[task.projectid] = currProject
        }

        const taskKey = getSavedTaskKey(task)
        let currTask = currProject[taskKey]
        if (!currTask) {
            currTask = {}
            currProject[taskKey] = currTask
        }

        let day = new Date(task.date).getDay() - 1

        if (day === -1) {
            day = 6
        }

        currTask[day] = task
    }

    return clients
}

const getSavedTaskKey = (task) => {
    const { jobid, zoneid, systemid, leadid, activityid, revisionid } = task

    const jobKey = jobid || 'n'
    const zoneKey = zoneid || 'n'
    const systemKey = systemid || 'n'
    const leadKey = leadid || 'n'
    const activityKey = activityid || 'n'
    const revisionKey = revisionid || 'n'
    const key = jobKey + zoneKey + systemKey + leadKey + activityKey + revisionKey

    return key
}

const checkTasksForSave = (tasksForSave, savedTimeCard) => {
    const newTasksForSave = [...tasksForSave]
    const timeCardsForUpdate = []
    for (let i = newTasksForSave.length - 1; i >= 0; i--) {
        const task = { ...newTasksForSave[i] }
        const savedClient = savedTimeCard[task.clientid]
        if (!savedClient) continue

        const savedProject = savedClient[task.projectid]
        if (!savedProject) continue

        const taskKey = getSavedTaskKey(task)
        const savedTask = savedProject[taskKey]
        if (!savedTask) continue

        for (const key in task.hours) {
            const dayTask = savedTask[key]
            if (!dayTask) continue

            const updatedTimeCard = { ...dayTask, hours: Number(dayTask.hours) + Number(task.hours[key]) }
            timeCardsForUpdate.push({ timecardid: updatedTimeCard.timecardid, hours: updatedTimeCard.hours, dayOfWeek: key })

            task.hours = { ...task.hours }
            delete task.hours[key]
        }

        if (!Object.keys(task.hours).length) {
            newTasksForSave.splice(i, 1)
            continue
        }

        newTasksForSave[i] = task
    }

    newTasksForSave.forEach(t => delete t.taskid )
    
    return { newTasksForSave, timeCardsForUpdate }
}

const getTotalHours = (savedTimeCard) => {
    const totalHours = {}

    for (const client of Object.values(savedTimeCard)) {
        for (const project of Object.values(client)) {
            for (const task of Object.values(project)) {
                for (const key in task) {
                    if (!totalHours[key]) {
                        totalHours[key] = 0
                    }

                    totalHours[key] += Number(task[key].hours)
                }
            }
        }
    }

    return totalHours
}

const calculateOvertime = (hours, selectedDays, holidays) => {
    const overtime = { ...hours }
    for (const key in overtime) {
        if (Number(key) !== 5 && Number(key) !== 6) {
            const isHoliday = holidays.holidays.find(h => checkDateEquality(selectedDays[key], h))
            if (isHoliday) continue

            overtime[key] -= WORKING_SHIFT

            if (overtime[key] <= 0) {
                delete overtime[key]
            }

            continue
        }

        const isWorkingDay = holidays.workingDays.find(wd => checkDateEquality(selectedDays[key], wd))
        if (!isWorkingDay) continue

        overtime[key] -= WORKING_SHIFT

        if (overtime[key] <= 0) {
            delete overtime[key]
        }
    }

    return overtime
}

const checkHours = (hoursArray) => {
    return hoursArray.find(h => !(Number(h) > 0 && Number(h) <= 24 && Number(h) % 0.5 === 0))
}

const rearrangeTasks = (newTasks, deletedTaskKey, isClient) => {
    const rearrangedTask = { ...newTasks }
    if (isClient) {
        const deletedClientIndex = deletedTaskKey.split('-')[0]
        let lastKey = null
        for (const key in rearrangedTask) {
            const currentClientArray = key.split('-')
            if (deletedClientIndex < currentClientArray[0]) {
                const newKey = (Number(currentClientArray[0]) - 1) + '-' + currentClientArray[1]
                rearrangedTask[newKey] = rearrangedTask[key]
                lastKey = key
            }
        }

        if (lastKey) {
            delete rearrangedTask[lastKey]
        }


        return rearrangedTask
    }

    const [deletedProjectClientIndex, deletedProjectIndex] = deletedTaskKey.split('-').map(Number)
    let lastKey = null
    for (const key in rearrangedTask) {
        const currentClientArray = key.split('-').map(Number)
        if (
            deletedProjectClientIndex === currentClientArray[0] && 
            deletedProjectIndex < currentClientArray[1]
        ) {
                const newKey = currentClientArray[0] + '-' + (Number(currentClientArray[1]) - 1)
                rearrangedTask[newKey] = rearrangedTask[key]
            
            lastKey = key
        }
    }
    
    if (lastKey) {
        delete rearrangedTask[lastKey]
    }

    return rearrangedTask
}

const checkTasksForDuplicates = (projectTasks) => {
    for (const projectKey in projectTasks) {
        const tasks = projectTasks[projectKey]
        const tasksKeys = tasks.map( t => getSavedTaskKey(t) )
        const tasksKeysDistinct = new Set(tasksKeys)

        if (tasksKeys.length > tasksKeysDistinct.size) {
            return true
        }
    }

    return false
}

const parseUserLastProjectChanges = (tasks) => {
    const userLastProjectChanges = {}

    for (const task of tasks) {
        userLastProjectChanges[task.projectid] = {
            projectid: task.projectid,
            lastchange: task.lastchange,
        }
    }

    return userLastProjectChanges
}

export {
    getNewTask,
    getNewProject,
    getNewClient,
    initialState,
    arrangeClientsProjects,
    arrangeUserTasks,
    arrangeProjectColumnData,
    getTaskKey,
    getTaskIndexes,
    getTasksForSave,
    arrangeSavedTasks,
    getSavedTaskKey,
    getTotalHours,
    calculateOvertime,
    checkHours,
    rearrangeTasks,
    checkTasksForDuplicates,
    parseUserLastProjectChanges,
}
