import React, { useMemo, useRef, useState } from 'react'
import { useContextStatistics } from '../../../contexts/statisticsContext'
import useInputDropdownKeyNav from '../../../hooks/useInputDropdownKeyNav'
import styles from './index.module.css'

const enterKeyCode = 13

const InputStatistics = ({ placeholder, type, options }) => {
    const [value, setValue] = useState('')
    const [isOnFocus, setIsOnFocus] = useState(false)
    const { setSelectedProperty, setFiltersChanged } = useContextStatistics()
    const [currentOptionIndex, setCurrentOptionIndex] = useState(-1)
    const containerRef = useRef(null)
    const inputRef = useRef(null)

    const sortedFilteredOptions = useMemo(() => (
        Object.entries(options)
              .filter(
                  option => option[1].toLowerCase().includes(value.toLowerCase())
              )
              .sort((a, b) => a[1].localeCompare(b[1]))
    ), [options, value])

    useInputDropdownKeyNav({
        optionNameKey: 1,
        isOnFocus,
        currentOptionIndex,
        setCurrentOptionIndex,
        setValue,
        sortedOptions: sortedFilteredOptions,
        inputRef,
        containerRef,
    })

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleBlur = () => {
        const selected = Object.entries(options).find(o => o[1] === value)
        if (selected) {
            const newOption = { [selected[0]]: selected[1] }
            setSelectedProperty(type, newOption)
            setValue('')

            setFiltersChanged(true)
        }

        setIsOnFocus(false)
    }

    const handleKeyUp = (e) => {
        if (Number(e.keyCode) === enterKeyCode && inputRef.current) {
            inputRef.current.blur()
        }
    }

    return (
        <div className={styles.container} ref={containerRef}>
            <div>
                <input
                    autoComplete='off'
                    ref={inputRef}
                    value={value}
                    placeholder={placeholder}
                    onFocus={() => setIsOnFocus(true)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    className={styles.input}
                />
                {isOnFocus && <div className={styles['options-container']}>
                    {sortedFilteredOptions
                        .map((opt, i) => {
                            return (
                                <div
                                    key={opt[0]}
                                    onMouseDown={() => setValue(opt[1])}
                                    onMouseEnter={() => setCurrentOptionIndex(i)}
                                    onMouseLeave={() => setCurrentOptionIndex(-1)}
                                    className={`${styles.option} ${i === currentOptionIndex && styles.hover}`}
                                >
                                    {opt[1]}
                                </div>)
                        })}
                </div>}
            </div>
        </div>
    )
}

export default InputStatistics
