import React, { useCallback, useEffect } from 'react'
import styles from './index.module.css'
import AddClient from '../../components/Admin/AddClient'
import AddProject from '../../components/Admin/AddProject'
import AddUser from '../../components/Admin/AddUser'
import NavigationBar from '../../components/NavigationBar'
import useClientServices from '../../services/client'
import { useContextTimeCard } from '../../contexts/timeCardContext'
import useError from '../../hooks/useError'

const AdminPage = () => {
    const { setAdminClients } = useContextTimeCard()
    const { getAllClients } = useClientServices()
    const { setErrorMessage } = useError()

    const checkCurrentList = useCallback(async () => {
        const allClientsDB = await getAllClients()

        if (allClientsDB.error) {
            setErrorMessage(allClientsDB)
            return
        }

        setAdminClients(allClientsDB)

    }, [getAllClients, setAdminClients, setErrorMessage])

    useEffect(() => {
        checkCurrentList()
    }, [checkCurrentList])

    return (
        <>
            <NavigationBar />
            <main className={styles.container}>
                <AddProject />
                <div className={styles['some-fields']}>
                    <AddClient />
                    <AddUser />
                </div>
            </main>
        </>
    )
}

export default AdminPage
