import React from 'react'
import useError from '../../../hooks/useError'
import useForm from '../../../hooks/useForm'
import useUserServices from '../../../services/user'
import Button from '../../Button'
import styles from './index.module.css'

const data = {
    name: '',
    username: '',
    password: '',
    email: '',
    role: 'user'
}

const AddUser = () => {
    const [values, handleChange, setNewFormValues] = useForm(data)
    const { setErrorMessage } = useError()
    const { createUser } = useUserServices()

    const handleSubmit = async (event) => {
        event.preventDefault()

        const response = await createUser(values)
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setNewFormValues(data)
    }

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.title}>Add new user</div>
            <div className={styles.required}>* All fields are required</div>
            <div className={styles.fields}>
                <div className={styles['line-input']}>
                    <span className={styles.name}>Name</span>
                    <input
                        type='text'
                        name='name'
                        placeholder='name'
                        value={values.name}
                        onChange={handleChange}
                        className={styles.input}
                    />
                </div>
                <div className={styles['line-input']}>
                    <span className={styles.name}>Username</span>
                    <input
                        type='text'
                        name='username'
                        placeholder='username'
                        value={values.username}
                        onChange={handleChange}
                        className={styles.input}
                    />
                </div>
                <div className={styles['line-input']}>
                    <span className={styles.name}>Password</span>
                    <input
                        type='password'
                        name='password'
                        placeholder='password'
                        value={values.password}
                        onChange={handleChange}
                        className={styles.input}
                    />
                </div>
                <div className={styles['line-input']}>
                    <span className={styles.name}>Email</span>
                    <input
                        type='text'
                        name='email'
                        placeholder='email'
                        value={values.email}
                        onChange={handleChange}
                        className={styles.input}
                    />
                </div>
                <div className={styles['line-input']}>
                    <span className={styles.name}>Role</span>
                    <select
                        type='text'
                        name='role'
                        placeholder='role'
                        value={values.role}
                        onChange={handleChange}
                        className={styles.input}
                    >
                        <option value='user'>User</option>
                        <option value='statistics'>Statistics</option>
                        <option value='admin'>Admin</option>
                    </select>
                </div>
            </div>
            <Button title='SAVE USER' />
        </form>
    )
}

export default AddUser