import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import useUserTaskServices from '../../../services/userTasks'
import HoursInput from '../../Inputs/HoursInput'
import { getRange } from '../../../utils/getRange'
import pin from '../../../public/pin.svg'
import filledPin from '../../../public/filled_pin.svg'
import ResponsiveInput from '../../Inputs/ResponsiveInput'
import useError from '../../../hooks/useError'

const Task = ({ project, projectData, task, clientIndex, projectIndex, taskIndex, isLastElement }) => {
    const { tasksState, editTask, taskCheckbox, addTask, deleteTask, setTaskHours } = useContextTimeCard()
    const { createTask, editTaskService, removeTask } = useUserTaskServices()
    const { setErrorMessage } = useError()
    const [disabledDays, setDisabledDays] = useState([])

    const getDisabledDays = () => {
        const days = []
        if (tasksState?.monthConfirmation?.prev?.isconfirmed || tasksState?.monthConfirmation?.prev?.islocked) {
            let idx = 0
            while (new Date(tasksState.selectedDays[idx]).getMonth() < Number(tasksState.selectedMonth.slice(5))) {
                days.push(idx)
                setTaskHours(idx, '', clientIndex, projectIndex, taskIndex)
                idx++
            }
        }

        if (tasksState?.monthConfirmation?.next?.isconfirmed || tasksState?.monthConfirmation?.next?.islocked) {
            let idx = 6
            while (new Date(tasksState.selectedDays[idx]).getMonth() > Number(tasksState.selectedMonth.slice(5))) {
                days.push(idx)
                setTaskHours(idx, '', clientIndex, projectIndex, taskIndex)
                idx--
            }
        }

        return days
    }

    useEffect(() => {
        setDisabledDays(getDisabledDays())
    }, [tasksState.monthConfirmation, tasksState.selectedDays])

    const handleEditTask = (option, type) => {
        const id = type + 'id'

        if (task.isSaved && option[id]) {
            editTaskService(task.taskid, type, option[id])
        }

        editTask(option, type, clientIndex, projectIndex, taskIndex)
    }

    const handleTaskCheckbox = async () => {
        const projectid = tasksState.selectedClients[clientIndex].selectedProjects[projectIndex].projectid

        const { jobid, zoneid, systemid, leadid, activityid, revisionid } = task
        const response = await createTask({
            projectid,
            jobid,
            zoneid,
            systemid,
            leadid,
            activityid,
            revisionid
        })

        if (response.error) {
            setErrorMessage(response)
            return
        }

        const { taskid } = response

        taskCheckbox(clientIndex, projectIndex, taskIndex, taskid)
        return
    }

    const handleRemoveTask = async () => {
        if (task.isSaved) {
            const response = await removeTask(task.taskid)

            if (response.error) {
                setErrorMessage(response)
                return
            }

            const { taskid } = response
            if (taskid === task.taskid) {
                deleteTask(clientIndex, projectIndex, taskIndex)
            }
            return
        }

        deleteTask(clientIndex, projectIndex, taskIndex)
    }

    const handleSetTaskHours = (e) => {
        const id = e.target.id
        const hours = e.target.value
        setTaskHours(id, hours, clientIndex, projectIndex, taskIndex)
    }

    return (
        <div className={styles.container}>
            <div className={styles['inner-container']}>
                <div className={styles.task}>
                    {project && project.has_jobs &&
                        <ResponsiveInput
                            title='Job'
                            unselectedOptions={projectData.jobs || []}
                            options={projectData.jobs || []}
                            setOption={handleEditTask}
                            initialValue={{ jobid: task.jobid, jobname: task.jobname }}
                            isInCurrRequest={Object.keys(task.hours).length}
                            index={taskIndex}
                            className={styles['task-column']}
                        />
                    }
                    {project && project.has_zones &&
                        <ResponsiveInput
                            title='Zone'
                            unselectedOptions={projectData.zones || []}
                            options={projectData.zones || []}
                            setOption={handleEditTask}
                            initialValue={{ zoneid: task.zoneid, zonename: task.zonename }}
                            isInCurrRequest={Object.keys(task.hours).length}
                            index={taskIndex}
                            className={styles['task-column']}
                        />
                    }
                    {project && project.has_systems &&
                        <ResponsiveInput
                            title='System'
                            unselectedOptions={projectData.systems || []}
                            options={projectData.systems || []}
                            setOption={handleEditTask}
                            initialValue={{ systemid: task.systemid, systemname: task.systemname }}
                            isInCurrRequest={Object.keys(task.hours).length}
                            index={taskIndex}
                            className={styles['task-column']}
                        />
                    }
                    {project && project.has_leads &&
                        <ResponsiveInput
                            title='Lead'
                            unselectedOptions={projectData.leads || []}
                            options={projectData.leads || []}
                            setOption={handleEditTask}
                            initialValue={{ leadid: task.leadid, leadname: task.leadname }}
                            isInCurrRequest={Object.keys(task.hours).length}
                            index={taskIndex}
                            className={styles['task-column']}
                        />
                    }
                    {project && project.has_activities &&
                        <ResponsiveInput
                            title='Activity'
                            unselectedOptions={projectData.activities || []}
                            options={projectData.activities || []}
                            setOption={handleEditTask}
                            initialValue={{ activityid: task.activityid, activityname: task.activityname }}
                            isInCurrRequest={Object.keys(task.hours).length}
                            index={taskIndex}
                            className={styles['task-column']}
                        />
                    }
                    {project && project.has_revisions &&
                        <ResponsiveInput
                            title='Revision'
                            unselectedOptions={projectData.revisions || []}
                            options={projectData.revisions || []}
                            setOption={handleEditTask}
                            initialValue={{ revisionid: task.revisionid, revisionname: task.revisionname }}
                            isInCurrRequest={Object.keys(task.hours).length}
                            index={taskIndex}
                            className={styles['task-column']}
                        />
                    }
                </div>
                <div className={styles.hours}>
                    {getRange(0, 7).map(num => (
                        <HoursInput
                            key={num}
                            id={num}
                            value={task.hours[num]}
                            onChange={handleSetTaskHours}
                            disabled={disabledDays.includes(num)}
                        />
                    ))}
                    <div className={styles['buttons-container']}>
                        <div className={styles['checkbox-container']}>
                            <input
                                type='checkbox'
                                id={clientIndex + '-' + projectIndex + '-' + taskIndex}
                                checked={task.isSaved}
                                className={styles.checkbox}
                                onChange={handleTaskCheckbox}
                                disabled={!project || !project.projectid || task.isSaved}
                            />
                            <label
                                className={styles.label}
                                htmlFor={clientIndex + '-' + projectIndex + '-' + taskIndex}
                            >
                                <img
                                    className={styles.unchecked}
                                    src={pin} alt={''}
                                    height='17'
                                    title='Pin task'
                                />
                                <img
                                    className={styles.checked}
                                    src={filledPin}
                                    alt={''} height='17'
                                    title='Task will appear again next time'
                                />
                            </label>
                        </div>
                        <button className={styles['remove-button']} onClick={handleRemoveTask} >
                            X
                        </button>
                    </div>
                </div>
            </div>
            {
                project && isLastElement &&
                <button className={styles.button} onClick={() => addTask(clientIndex, projectIndex)} ></button>
            }
        </div >
    )
}

export default Task
