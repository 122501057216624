import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import useSessionServices from '../services/session'
import { GUEST_USER } from '../utils/constants'

const SessionContext = createContext()

export function useContextSession() {
    return useContext(SessionContext)
}

const SessionContextInitializer = ({ children }) => {
    const [user, setUser] = useState(null)
    const { verifySession } = useSessionServices()
    const identifyUser = useCallback(async () => {
        if (user) return
        
        const { user: sessionUser } = await verifySession(true)
        if (!sessionUser) {
            return setUser(GUEST_USER)
        }
        
        setUser(sessionUser)
    }, [user, verifySession])

    useEffect(() => {
        identifyUser()
    }, [identifyUser])

    return (
        <SessionContext.Provider value={{ user, setUser }}>
            {children}
        </SessionContext.Provider>
    )
}

export default SessionContextInitializer