import React, { useEffect, useMemo, useState } from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import { formatDate } from '../../utils/date'
import { getFilters } from '../../utils/statistics'
import styles from './index.module.css'
import useError from '../../hooks/useError'
import useStatisticsServices from '../../services/statistics'
import QuickReportsTable from './QuickReportsTable'

const StatisticsQuickReport = () => {
    const [reportGrouping, setReportGrouping] = useState({ groupBy: 'Project', thenBy: '', projectFallback: true, dirty: false })
    const { statisticsState } = useContextStatistics()
    const { getQuickReport } = useStatisticsServices()
    const [report, setReport] = useState([])
    const { setErrorMessage } = useError()
    const { selectedRange, filtersChanged, statisticsResults } = statisticsState

    const filters = useMemo(() => {
        const allFilters = getFilters(statisticsState)
        for (const key of Object.keys(allFilters)) {
            if (!allFilters[key].length) {
                delete allFilters[key]
            }
        }

        return allFilters
    }, [statisticsState])

    useEffect(() => {
        if (filtersChanged) {
            setReportGrouping(curr => ({ ...curr, dirty: true }))
        }
    }, [filtersChanged])

    const range = useMemo(() => {
        if (!selectedRange[0].hoverTo) return null

        const dateStart = formatDate(new Date(selectedRange[0].from), '%d %b %Y')
        const dateEnd = formatDate(new Date(selectedRange[0].hoverTo), '%d %b %Y')

        return `From ${dateStart} to ${dateEnd}`
    }, [selectedRange])

    const generateQuickReports = async () => {

        let grouping = reportGrouping
        if (reportGrouping.groupBy === 'Project' || reportGrouping.thenBy === 'Project') {
            grouping = { ...reportGrouping, projectFallback: false }
        }

        const response = await getQuickReport(filters, grouping)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        let reportState = response
        // if clientname is included, group results by client
        if (reportState[0].clientname) {
            reportState = reportState.reduce((acc, row) => {
                if (!(row.clientname in acc)) {
                    acc[row.clientname] = []
                }

                acc[row.clientname].push(row)
                return acc
            }, {})
        }

        setReport(reportState)
        setReportGrouping(curr => ({ ...curr, dirty: false }))
    }

    const hasReportRows = () => {
        return report.length || (!Array.isArray(report) && Object.keys(report).length > 0)
    }

    const shouldDisableReportsGeneration = !filters || filtersChanged || !filters.selectedRange || !statisticsResults?.length

    return (
        <div className={styles.container}>
            <div className={styles['grouping-container']}>
                <div className={styles['fallback-grouping']}>
                    {reportGrouping.groupBy !== 'Project' && reportGrouping.thenBy !== 'Project' ? <>
                        <label htmlFor='client-fallback'>Group by project?</label>
                        <input 
                            type='checkbox' 
                            id='client-fallback'
                            checked={reportGrouping.projectFallback} 
                            onClick={() => setReportGrouping(curr => ({ ...curr, projectFallback: !curr.projectFallback, dirty: true }))} 
                        />
                    </> : null}
                </div>
                <div>
                    <label>Group results by</label>
                    <select 
                        value={reportGrouping.groupBy} 
                        onChange={(event) => setReportGrouping(curr => ({ ...curr, groupBy: event.target.value, dirty: true }))} 
                        className={styles['grouping-dropdown']}
                    >
                        { groupByOptions.filter(o => o !== reportGrouping.thenBy).map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>and by</label>
                    <select 
                        value={reportGrouping.thenBy} 
                        onChange={(event) => setReportGrouping(curr => ({ ...curr, thenBy: event.target.value, dirty: true }))} 
                        className={styles['grouping-dropdown']}
                    >
                        <option value=''>-- Empty --</option>
                        { groupByOptions.filter(o => o !== reportGrouping.groupBy).map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <button className={styles.button} onClick={generateQuickReports} disabled={shouldDisableReportsGeneration}>Show reports</button>
            </div>
            {range && !filtersChanged && hasReportRows() && !reportGrouping.dirty ? (
                <div>
                    {range ? <div>{range}</div> : null}
                    <QuickReportsTable 
                        report={report} 
                        reportGrouping={reportGrouping}
                        filters={filters}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default StatisticsQuickReport

const groupByOptions = [ 'Project', 'User', 'Job',  'Activity', 'Zone', 'System', 'Lead', 'Revision' ]