const getCookieValue = (cookieName) => {
    const cookieArr = document.cookie.split(';')
    const requestedCookie = cookieArr.find(item => item.startsWith(cookieName))
    if (!requestedCookie) {
        return null
    }

    const cookieValue = requestedCookie.split('=')[1]
    return Number(cookieValue)
}

const checkCookieExpiration = (cookieName) => {
    const cookieValue = getCookieValue(cookieName)
    if (!cookieValue) return true
    
    const todayToNumber = new Date().getTime()

    return cookieValue < todayToNumber
}

export {
    getCookieValue,
    checkCookieExpiration
}