const parseCronString = (cronString) => {
    const hoursWithWeekday = cronString.split(' * * ')

    return {
        hour: Number(hoursWithWeekday[0].split(' ')[2]),
        weekday: hoursWithWeekday[1],
    }
}

const assembleCronString = (weekday, hour) => `0 0 ${hour} * * ${weekday}`

export {
    parseCronString,
    assembleCronString
}