import React, { useCallback, useEffect, useState } from 'react'
import useProjectAttributeServices from '../../../services/projectAttribute'
import CheckBox from '../../CheckBox'
import AddProjectOptions from '../AddProject/AddProjectOptions'
import styles  from './index.module.css'
import useError from '../../../hooks/useError'

const ProjectOptions = ({ 
    action, 
    options, 
    setOptionVariable, 
    setSelectedOption, 
    setManualOption, 
    projectState, 
    updatedOptions, 
    selectedEditProjectId 
}) => {
    const { getProjectAttributeNames } = useProjectAttributeServices()
    const [optionsData, setOptionsData] = useState({})
    const [isFetchingData, setIsFetchingData] = useState(false)
    const { setErrorMessage } = useError()

    const updateData = useCallback(async () => {
        const projectAttributesToQuery = []
        for (const attributeWithAction of options) {
            if (!attributeWithAction.startsWith(action)) continue

            const projectAttribute = attributeWithAction.replace(`${action} `, '')
            if (projectAttribute in optionsData) continue

            projectAttributesToQuery.push(projectAttribute)
        }

        if (projectAttributesToQuery.length === 0) return

        setIsFetchingData(true)
        
        const newOptionsData = await getProjectAttributeNames(projectAttributesToQuery)
        setIsFetchingData(false)
        if (newOptionsData.error) {
            setErrorMessage(newOptionsData)
            return
        }

        setOptionsData(currData => ({ ...currData, ...newOptionsData }))
    }, [getProjectAttributeNames, options, action, optionsData, setErrorMessage])
    
    useEffect(() => {
        updateData()
    }, [updateData])
    
    return (
     <>
     {  isFetchingData
        ?
        <div className={styles['data-fetching']}>Fetching project data...</div>
        :
        <>
        <div className={styles['option-container']}>
            <CheckBox
                value={`${action} zones`}
                name={`${action}_zoneLabel`}
                checked={options.includes(`${action} zones`)}
                onChange={setOptionVariable}
                isOptional={true}
            />
            {options.includes(`${action} zones`) && optionsData.zones &&
                <AddProjectOptions
                    setSelectedOption={setSelectedOption}
                    setManualOption={setManualOption}
                    options={projectState.zones}
                    manualOption={action === 'Edit' ? updatedOptions.zones : projectState.create.zones}
                    availableOptions={optionsData.zones}
                    optionName='zone'
                    selectedEditProjectId={selectedEditProjectId}
                />
            }
        </div>
        <div className={styles['option-container']}>
            <CheckBox
                value={`${action} systems`}
                name={`${action}_systemLabel`}
                checked={options.includes(`${action} systems`)}
                onChange={setOptionVariable}
                isOptional={true}
            />
            {options.includes(`${action} systems`) && optionsData.systems &&
                <AddProjectOptions
                    setSelectedOption={setSelectedOption}
                    setManualOption={setManualOption}
                    options={projectState.systems}
                    manualOption={action === 'Edit' ? updatedOptions.systems : projectState.create.systems}
                    availableOptions={optionsData.systems}
                    optionName='system'
                    selectedEditProjectId={selectedEditProjectId}
                />
            }
        </div>
        <div className={styles['option-container']}>
            <CheckBox
                value={`${action} jobs`}
                name={`${action}_jobLabel`}
                checked={options.includes(`${action} jobs`)}
                onChange={setOptionVariable}
                isOptional={true}
            />
            {options.includes(`${action} jobs`) && optionsData.jobs &&
                <AddProjectOptions
                    setSelectedOption={setSelectedOption}
                    setManualOption={setManualOption}
                    options={projectState.jobs}
                    manualOption={action === 'Edit' ? updatedOptions.jobs : projectState.create.jobs}
                    availableOptions={optionsData.jobs}
                    optionName='job'
                    selectedEditProjectId={selectedEditProjectId}
                />
            }
        </div>
        <div className={styles['option-container']}>
            <CheckBox
                value={`${action} revisions`}
                name={`${action}_revisionLabel`}
                checked={options.includes(`${action} revisions`)}
                onChange={setOptionVariable}
                isOptional={true}
            />
            {options.includes(`${action} revisions`) && optionsData.revisions &&
                <AddProjectOptions
                    setSelectedOption={setSelectedOption}
                    setManualOption={setManualOption}
                    options={projectState.revisions}
                    manualOption={action === 'Edit' ? updatedOptions.revisions : projectState.create.revisions}
                    availableOptions={optionsData.revisions}
                    optionName='revision'
                    selectedEditProjectId={selectedEditProjectId}
                />
            }
        </div>
        <div className={styles['option-container']}>
            <CheckBox
                value={`${action} activities`}
                name={`${action}_activityLabel`}
                checked={options.includes(`${action} activities`)}
                onChange={setOptionVariable}
                isOptional={true}
            />
            {options.includes(`${action} activities`) && optionsData.activities &&
                <AddProjectOptions
                    setSelectedOption={setSelectedOption}
                    setManualOption={setManualOption}
                    options={projectState.activities}
                    manualOption={action === 'Edit' ? updatedOptions.activities : projectState.create.activities}
                    availableOptions={optionsData.activities}
                    optionName='activity'
                    selectedEditProjectId={selectedEditProjectId}
                />
            }
        </div>
        <div className={styles['option-container']}>
            <CheckBox
                value={`${action} leads`}
                name={`${action}_leadLabel`}
                checked={options.includes(`${action} leads`)}
                onChange={setOptionVariable}
                isOptional={true}
            />
            {options.includes(`${action} leads`) && optionsData.leads &&
                <AddProjectOptions
                    setSelectedOption={setSelectedOption}
                    setManualOption={setManualOption}
                    options={projectState.leads}
                    manualOption={action === 'Edit' ? updatedOptions.leads : projectState.create.leads}
                    availableOptions={optionsData.leads}
                    optionName='lead'
                    selectedEditProjectId={selectedEditProjectId}
                />
            }
        </div>
        </>
    }
     </>
    )
}

export default ProjectOptions
