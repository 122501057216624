import { useMemo } from 'react'
import { useContextStatistics } from '../contexts/statisticsContext'

const useOptions = (type) => {
    const { statisticsState } = useContextStatistics()

    const options = useMemo(() => {
        let options = {}
        let optionsIds = new Set()

        if (Object.keys(statisticsState.selectedProjects).length) {
            Object.keys(statisticsState.selectedProjects).forEach(selectedProjectId => {
                const currProjectOptions = statisticsState.projects[selectedProjectId][type]
                optionsIds = new Set([...optionsIds, ...currProjectOptions])
            })

            Array.from(optionsIds).forEach(jobId => options[jobId] = statisticsState[type][jobId])
            return options
        } if (Object.keys(statisticsState.selectedClients).length) {
            Object.keys(statisticsState.selectedClients).forEach(selectedClientId => {
                Object.values(statisticsState.clients[selectedClientId].projects).forEach(p => {
                    optionsIds = new Set([...optionsIds, ...p[type]])
                })
            })

            Array.from(optionsIds).forEach(jobId => {
                if (jobId) {
                    options[jobId] = statisticsState[type][jobId]
                }
            })

            return options
        }

        return statisticsState[type]
    }, [statisticsState, type])

    return options
}

export default useOptions
