import React from 'react'
import NavigationBar from '../../components/NavigationBar'
import EditProject from '../../components/Admin/EditProject'
import styles from './index.module.css'
import AddHoliday from '../../components/AddHolidays'
import EditNames from '../../components/Admin/EditNames/idenx'

const AdminEditPage = () => {
    return (
        <>
            <NavigationBar />
            <main className={styles.container}>
                <EditProject />
                <EditNames />
                <AddHoliday />
            </main>
        </>
    )
}

export default AdminEditPage
