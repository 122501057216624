import { useCallback } from 'react'
import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import useProjectServices from './project'
import useUserServices from './user'

const USER_TASKS_URL = REST_API_URL + '/user-task'

const useUserTaskServices = () => {
    const { verifyUserSession } = useUserServices()
    const { getProject, getProjectsData } = useProjectServices()

    const checkProjectsLastChange = useCallback(async (clients, projectsColumnData) => {
        const changedProjects = []
    
        for (const currClient of clients) {
            for (const project of currClient.projects) {
                if (projectsColumnData[project.projectid] && project.lastchange !== projectsColumnData[project.projectid].lastchange) {
                    const changedProject = await getProject(project.projectid)
                    
                    if (changedProject.error) return changedProject
                    
                    changedProjects.push(changedProject)
                }
            }
        }
    
        return changedProjects
    }, [getProject])

    const getMissingProjectsData = useCallback(async (clients, projectsColumnData) => {
        const missingProjectIds = []
    
        for (const client of clients) {
            client.projects.forEach(p => {
                if (!projectsColumnData[p.projectid]) {
                    missingProjectIds.push(p.projectid)
                }
            })
        }
    
        if (!missingProjectIds.length) return
    
        const response = await getProjectsData(missingProjectIds)
    
        return response
    }, [getProjectsData])

    const createTask = async (data) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_TASKS_URL}/create`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify(data)
        })
    
        return await response.json()
    }
    
    const editTaskService = async (taskId, attributeType, newValue) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_TASKS_URL}/edit`,{
            method: 'PATCH',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ taskId, attributeType, newValue })
        })
    
        return await response.json()
    }
    
    const editTasks = async (type, taskIdsForUpdate, newId) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_TASKS_URL}/multiple-edit`,{
            method: 'PATCH',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({type, taskIdsForUpdate, newId})
        })
        
        return await response.json()
    }
    
    const removeTask = async (taskid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_TASKS_URL}/remove`,{
            method: 'DELETE',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify({ taskid })
        })
    
        return await response.json()
    }
    
    return {
        createTask,
        editTaskService,
        editTasks,
        removeTask,
        checkProjectsLastChange,
        getMissingProjectsData
    }
}

export default useUserTaskServices