import { useContext, createContext, useReducer, useEffect, useCallback } from 'react'
import useUserTaskServices from '../services/userTasks'
import {
    initialState,
    arrangeUserTasks,
    getTaskKey,
    arrangeProjectColumnData,
    arrangeClientsProjects,
} from '../utils/userTasks'
import timecardReducer from '../utils/timeCard'
import { useContextError } from './errorContext'
import { useContextSession } from './sessionContext'
import useUserServices from '../services/user'

const TimeCardContext = createContext()

export function useContextTimeCard() {
    return useContext(TimeCardContext)
}

const TimeCardContextInitializer = ({ children }) => {
    const { user } = useContextSession()
    const { addMessage } = useContextError()
    const { editTasks, checkProjectsLastChange, getMissingProjectsData } = useUserTaskServices()
    const { getInitialData } = useUserServices()

    const [tasksState, dispatch] = useReducer(timecardReducer, initialState)

    const { clients, projectsColumnData } = tasksState

    const setProjects = useCallback((projects) => {
        dispatch({ type: 'setClients', value: projects })
    }, [])

    const setProjectColumnData = useCallback((projectColumnData, projectid) => {
        dispatch({ type: 'setProjectColumnData', value: { projectColumnData, projectid } })
    }, [])

    const addProjectsColumnData = useCallback((newProjectsData) => {
        dispatch({ type: 'addProjectsColumnData', value: newProjectsData })
    }, [])

    const setOvertime = useCallback(({ week, hours, ischanged = false, isconfirmed = false, islocked = false, overtimeid }) => {
        dispatch({ type: 'setOvertime', value: { week, hours, ischanged: !!ischanged, isconfirmed: !!isconfirmed, islocked: !!islocked, overtimeid } })
    }, [])

    const setMonthConfirmation = useCallback((monthConfirmation) => {
        dispatch({ type: 'setMonthConfirmation', value: monthConfirmation })
    }, [])

    const handleProjectsDataUpdate = useCallback(async () => {
        if (!user || user.role === 'guest') return

        const response = await getInitialData()

        if (response.error) {
            addMessage(response.error)
            return
        }

        const { projects, tasks, holidays } = response
        const clients = arrangeClientsProjects(projects)
        if (tasks.length) {
            setSavedUserTasks(tasks, clients)
        }
        setHolidays(holidays)
        if (projects) {
            setProjects(projects)
        }
    }, [user, getInitialData, addMessage, setProjects])

    const handleMissingProjectsData = useCallback(async () => {
        
        const newProjectsData = await getMissingProjectsData(clients, projectsColumnData)
        
        if (newProjectsData) {
            if (newProjectsData.error) {
                addMessage(newProjectsData.error)
                return
            }
            
            addProjectsColumnData(newProjectsData)
        }
        
    }, [addMessage, addProjectsColumnData, clients, getMissingProjectsData, projectsColumnData])

    const checkProjectsLastChangeCallback = useCallback(async () => {
        const changedProjects = await checkProjectsLastChange(
            tasksState.clients,
            tasksState.projectsColumnData,
        )

        if (changedProjects.error) {
            addMessage(changedProjects.error)
            return
        }

        if (changedProjects && changedProjects.length) {
            const changedProjectsObj = {}
            for (const project of changedProjects) {
                changedProjectsObj[project.projectid] = arrangeProjectColumnData(project)
            }

            dispatch({ type: 'addProjectsColumnData', value: changedProjectsObj })
        }
    }, [addMessage, checkProjectsLastChange, tasksState.clients, tasksState.projectsColumnData])

    useEffect(() => {
        handleProjectsDataUpdate()
    }, [handleProjectsDataUpdate])

    useEffect(() => {
        if (
            user &&
            (user.role === 'statistics' || user.role === 'admin')
        ) {
            handleMissingProjectsData()
        }
    }, [user, handleMissingProjectsData])

    useEffect(() => {
        // tasksState.clients have to be received from DB in order to update selectedClients from localStorage before being set in context
        if (!tasksState.clients.length) return

        const storedData = JSON.parse(window.localStorage.getItem('userTasks'))

        if (storedData) {
            dispatch({ type: 'setStoredData', value: storedData })
        }
    }, [tasksState.clients.length])

    useEffect(() => {
        if (!tasksState.clients || !tasksState.clients.length) return

        const unselectedClients = tasksState.clients.filter(
            (c) => !tasksState.selectedClients.find((sc) => sc.clientid === c.clientid)
        )
        dispatch({ type: 'setUnselectedClients', value: unselectedClients })
    }, [tasksState.clients, tasksState.selectedClients])

    useEffect(() => {
        checkProjectsLastChangeCallback()
    }, [checkProjectsLastChangeCallback])

    useEffect(() => {
        if (
            !tasksState.selectedClients.length &&
            !Object.keys(tasksState.tasks).length &&
            !Object.keys(tasksState.projectsColumnData).length
        ) return

        const userTasks = {
            selectedClients: tasksState.selectedClients,
            tasks: tasksState.tasks,
            projectsColumnData: tasksState.projectsColumnData,
        }

        window.localStorage.setItem('userTasks', JSON.stringify(userTasks))
    }, [tasksState.projectsColumnData, tasksState.selectedClients, tasksState.tasks])

    const setUserTasks = (tasks, clients) => {
        const { newTasks, newSelectedClients } = arrangeUserTasks(tasks, clients)
        dispatch({ type: 'setUserTasks', value: { newTasks, newSelectedClients } })
    }

    const setSavedUserTasks = (tasks, clients) => {
        const { newTasks, newSelectedClients } = arrangeUserTasks(tasks, clients)
        dispatch({ type: 'setSavedUserTasks', value: { newTasks, newSelectedClients } })
    }

    const setSelectedDays = (days) => {
        dispatch({ type: 'setSelectedDays', value: days })
    }

    const setSelectedMonth = (month) => {
        dispatch({ type: 'setSelectedMonth', value: month })
    }

    const addClient = () => {
        dispatch({ type: 'addClient' })
    }

    const setAdminClients = useCallback((allClientsDB) => {
        dispatch({ type: 'setAdminClients', value: allClientsDB })
    }, [])

    const selectClient = (client, clientIndex) => {
        let tasksForUpdate = []
        const currClient = tasksState.selectedClients[clientIndex]
        const selectedProjects = currClient.selectedProjects

        for (let i = 0; i < selectedProjects.length; i++) {
            const taskKey = getTaskKey(clientIndex, i)
            const projectTasks = tasksState.tasks[taskKey]
            if (projectTasks) {
                const tasks = projectTasks.filter((t) => t.isSaved)
                tasksForUpdate = [...tasksForUpdate, ...tasks]
            }
        }

        // if (tasksForUpdate.length) {
        //     editTasks('client', tasksForUpdate.map(tfu => tfu.taskid), client.clientid)
        // }

        dispatch({ type: 'selectClient', value: { client, clientIndex } })
    }

    const returnClient = (newClientName, clientIndex) => {
        dispatch({ type: 'returnClient', value: { newClientName, clientIndex } })
    }

    const addProject = (clientIndex) => {
        dispatch({ type: 'addProject', value: clientIndex })
    }

    const selectProject = (project, clientIndex, projectIndex) => {
        const taskKey = getTaskKey(clientIndex, projectIndex)
        const projectTasks = tasksState.tasks[taskKey] || []
        const tasksForUpdate = projectTasks.filter((t) => t.isSaved)

        if (tasksForUpdate.length) {
            const taskIdsForUpdate = tasksForUpdate.map(tfu => tfu.taskid)
            editTasks('project', taskIdsForUpdate, project.projectid)
        }

        // When setting new project attributes from old project, that are not used in the new project, will remain in the usertask
        // The surplus attributes will be cleared on saveTimeCard (utils => userTasks => getTasksForSave)
        dispatch({ type: 'selectProject', value: { project, clientIndex, projectIndex } })
    }

    const returnProject = (newProjectName, clientIndex, projectIndex) => {
        dispatch({ type: 'returnProject', value: { newProjectName, clientIndex, projectIndex } })
    }

    const addTask = (clientIndex, projectIndex) => {
        dispatch({ type: 'addTask', value: { clientIndex, projectIndex } })
    }

    const editTask = (option, type, clientIndex, projectIndex, taskIndex) => {
        dispatch({ type: 'editTask', value: { option, type, clientIndex, projectIndex, taskIndex } })
    }

    const taskCheckbox = (clientIndex, projectIndex, taskIndex, taskid) => {
        dispatch({ type: 'taskCheckbox', value: { clientIndex, projectIndex, taskIndex, taskid } })
    }

    const deleteTask = (clientIndex, projectIndex, taskIndex) => {
        dispatch({ type: 'deleteTask', value: { clientIndex, projectIndex, taskIndex } })
    }

    const setTaskHours = (id, hours, clientIndex, projectIndex, taskIndex) => {
        dispatch({ type: 'setTaskHours', value: { id, hours, clientIndex, projectIndex, taskIndex } })
    }

    const restoreTask = (oldTask) => {
        dispatch({ type: 'restoreTask', value: oldTask })
    }

    const setAreTasksOk = (newState) => {
        dispatch({ type: 'setAreTasksOk', value: { type: 'tasks', newState } })
    }

    const setAreHoursOk = (newState) => {
        dispatch({ type: 'setAreTasksOk', value: { type: 'hours', newState } })
    }

    const setSavedTimeCard = (savedTimeCard) => {
        dispatch({ type: 'setSavedTimeCard', value: savedTimeCard })
    }

    const deleteSavedTasks = (taskKey, clientid, projectid) => {
        dispatch({ type: 'deleteSavedTasks', value: { taskKey, clientid, projectid } })
    }

    const changeSavedHours = (taskKey, clientid, projectid, day, newValue) => {
        dispatch({ type: 'changeSavedHours', value: { taskKey, clientid, projectid, day, newValue } })
    }

    const setUpdatedTimeCardIds = (timeCardIds) => {
        dispatch({ type: 'setUpdatedTimeCardIds', value: timeCardIds })
    }

    const clearHours = () => {
        dispatch({ type: 'clearHours' })
    }

    const addProjectFromNewSaveInDB = (project) => {
        dispatch({ type: 'addProjectFromNewSaveInDB', value: project })
    }

    const editProjectInDB = (project) => {
        dispatch({ type: 'editProjectInDB', value: project })
    }
    
    const setHolidays = (holidays) => {
        const newHolidays = {
            holidays: [],
            workingDays: [],
        }

        for (const element of holidays) {
            if (element.isholiday) {
                newHolidays.holidays.push(new Date(element.holiday))
                continue
            }

            newHolidays.workingDays.push(new Date(element.holiday))
        }

        dispatch({ type: 'setHolidays', value: newHolidays })
    }

    const updateProjectLastChange = (projects) => {
        dispatch({ type: 'updateProjectLastChange', value: projects })
    }

    const updateUserTasks = (projects) => {
        dispatch({ type: 'updateUserTasks', value: projects })
    }

    return (
        <TimeCardContext.Provider
            value={{
                tasksState,
                setProjects,
                setUserTasks,
                setSelectedDays,
                setSelectedMonth,
                setAdminClients,
                selectClient,
                addClient,
                returnClient,
                addProject,
                selectProject,
                returnProject,
                setProjectColumnData,
                addProjectsColumnData,
                addTask,
                editTask,
                taskCheckbox,
                deleteTask,
                setTaskHours,
                restoreTask,
                setAreTasksOk,
                setAreHoursOk,
                setSavedTimeCard,
                deleteSavedTasks,
                changeSavedHours,
                setUpdatedTimeCardIds,
                clearHours,
                addProjectFromNewSaveInDB,
                editProjectInDB,
                setHolidays,
                setOvertime,
                setMonthConfirmation,
                checkProjectsLastChangeCallback,
                updateProjectLastChange,
                updateUserTasks,
            }}
        >
            {children}
        </TimeCardContext.Provider>
    )
}

export default TimeCardContextInitializer
