const FORMAT_REGEX = /%[aAbBdmwyY]/g

const MONTHS = {
    'bg': [
        'Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли',
        'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'
    ],
    'en': [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ],
    'ru': [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
        'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ],
}

const WEEKDAYS = {
    'bg': [
        'Неделя', 'Понеделник', 'Вторник', 'Сряда',
        'Четвъртък', 'Петък', 'Събота', 'Неделя'
    ],
    'en': [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday',
        'Thursday', 'Friday', 'Saturday', 'Sunday'
    ],
    'ru': [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
    ]
}

const MILLISECONDS_IN_DAY = 86400000
const MILLISECONDS_IN_MINUTE = 60000

const FORMAT_OPTIONS = {
    'y': (date, locale) => date.getFullYear().toString().slice(-2),      // short year (2018 => 18)
    'Y': (date, locale) => date.getFullYear(),                           // full year (2018 => 2018)
    'm': (date, locale) => `0${date.getMonth() + 1}`.slice(-2),             // month as a number (01 - 12)
    'b': (date, locale) => MONTHS[locale][date.getMonth()].slice(0, 3),     // month, short (Dec, Apr etc.)
    'B': (date, locale) => MONTHS[locale][date.getMonth()],                 // month, long (December)
    'd': (date, locale) => `0${date.getDate()}`.slice(-2),                  // day of month (01 - 31)
    'a': (date, locale) => WEEKDAYS[locale][date.getDay()].slice(0, 3),     // weekday, short (Mon, Wed)
    'A': (date, locale) => WEEKDAYS[locale][date.getDay()],                 // weekday, long (Monday)
    'w': (date, locale) => getWeekNumber(date),                             // week number of the year (1-53)
}

const getDateWithOffset = (initialDate, daysOffset) => {
    return new Date(initialDate.getTime() + daysOffset * MILLISECONDS_IN_DAY)
}

const getWeekNumber = (date) => {
    // Thursday in current week decides the year
    // (each week's year is the Gregorian year in which the Thursday falls)
    const currWeekThursday = getDateWithOffset(getMonday(date), 3)

    // January 4th is always in week 1
    const week1 = new Date(date.getFullYear(), 0, 4)
    const week1Thursday = getDateWithOffset(getMonday(week1), 3)

    const weekDiff = 1 + Math.round((currWeekThursday.getTime() - week1Thursday.getTime()) / (7 * MILLISECONDS_IN_DAY))

    if (weekDiff === 0) {
        const lastYearFirstWeekThursday = getDateWithOffset(getMonday(new Date(date.getFullYear() - 1, 0, 4)), 3)
        const lastYearLastWeekThursday = getDateWithOffset(getMonday(new Date(date.getFullYear() - 1, 11, 31)), 3)

        return 1 + Math.round((lastYearLastWeekThursday.getTime() - lastYearFirstWeekThursday.getTime()) / (7 * MILLISECONDS_IN_DAY))
    }

    if (weekDiff > 52) {
        const thisYearLastDay = new Date(date.getFullYear(), 11, 31)
        const lastDayWeekDay = thisYearLastDay.getDay()
        if (lastDayWeekDay < 4 && lastDayWeekDay > 0) {
            return 1
        }
    }

    return weekDiff
}

const getWeekYear = (date) => {
    let copyDate = new Date(date.getTime());
    copyDate.setDate(copyDate.getDate() + 3 - (copyDate.getDay() + 6) % 7);
    return copyDate.getFullYear();
}

/*  Receives a Javascript date as a first parameter and a format string 
as a second parameter and returns a string representing the date in 
the desired format. The format string has several options which can be
seen in the 'FORMAT_OPTIONS' constant above. 
!!! Every format option must be preceded by a percentage sign (%)
Examples: 
    In: (date: new Date('2021-01-28'), formatString: '%y_%B_%d')
   Out: '21_January_28'
   In: (date: new Date('2020-05-12), formatString: '%a, %d %b, %Y')
  Out: 'Tue, 12 May, 2020'
 */
const formatDate = (date, formatString, locale = 'en') => {
    return formatString.replace(FORMAT_REGEX, (substr) => {
        // eslint-disable-next-line prefer-destructuring
        const formatType = substr[1]
        const result = FORMAT_OPTIONS[formatType](date, locale)
        return result
    })
}

const getMonday = (date) => {
    date = date || new Date()

    // need to change to 7 in case date is Sunday (which in JS is 0, while Monday is 1)
    const dateDay = date.getDay() || 7
    const monday = new Date(date)
    monday.setDate(date.getDate() - (dateDay - 1))

    return monday
}

const getSunday = (date) => {
    date = date || new Date()

    // need to change to 7 in case date is Sunday (which in JS is 0, while Monday is 1)
    const dateDay = date.getDay() || 7
    const sunday = new Date(date)
    sunday.setDate(date.getDate() + (7 - dateDay))

    return sunday
}

const getWeekDays = (date) => {
    const monday = getMonday(date)
    const dates = [monday]
    for (let dateOffset = 1; dateOffset < 7; dateOffset++) {
        const currentDate = new Date(monday)
        currentDate.setDate(monday.getDate() + dateOffset)
        dates.push(currentDate)
    }

    return dates
}

const getWeekRange = date => {
    return {
        from: getMonday(date),
        to: getSunday(date),
    }
}

/* Returns true if the year, month and day of two dates are the same,
and false otherwise (i.e. ignores time)
*/
const checkDateEquality = (firstDate, secondDate) => {
    return firstDate.getYear() === secondDate.getYear() 
    && firstDate.getMonth() === secondDate.getMonth() 
    && firstDate.getDate() === secondDate.getDate()
}

const utcToLocal = (date) => {
    const convertedDate = new Date(date).getTime() + new Date(date).getTimezoneOffset() * MILLISECONDS_IN_MINUTE
    return new Date(convertedDate)
}

const localToUtc = (date) => {
    const convertedDate = new Date(date).getTime() - new Date(date).getTimezoneOffset() * MILLISECONDS_IN_MINUTE
    return new Date(convertedDate)
}

const getMonth = (date) => {
    return new Date(date).getMonth()
}

const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0)
}

export {
    MONTHS,
    getMonday,
    getSunday,
    getWeekDays,
    formatDate,
    getWeekRange,
    getWeekNumber,
    getWeekYear,
    checkDateEquality,
    utcToLocal,
    localToUtc,
    getMonth,
    getLastDayOfMonth
}
