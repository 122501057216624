import { useCallback } from 'react'
import {
    REST_API_URL,
    AUTH_COOKIE_NAME,
    HTTP_HEADERS,
    JSON_CONTENT_TYPE
} from '../utils/constants'
import useUserServices from './user'

const PROJECT_ATTS_URL = REST_API_URL + '/project-attribute'

const useProjectAttributeServices = () => {
    const { verifyUserSession } = useUserServices()

    const getProjectAttributeNames = useCallback(async (attributeNames) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${PROJECT_ATTS_URL}/names?atts=[${attributeNames.join(',')}]`, {
            method: 'GET'
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const checkForSavedHoursWithAttributes = async ({ optionName, attributeIds, selectedEditProjectId }) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${PROJECT_ATTS_URL}/hours?optionName=${optionName}&attsIds=[${attributeIds.join(',')}]&projectId=${selectedEditProjectId}`)
    
        return await response.json()
    }

    const editAttributeName = async (type, id, newName) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${PROJECT_ATTS_URL}/edit-name`, {
            method: 'PATCH',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ type, id, newName })
        })
    
        return await response.json()
    }
    
    return {
        getProjectAttributeNames,
        checkForSavedHoursWithAttributes,
        editAttributeName
    }
}

export default useProjectAttributeServices