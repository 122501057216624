import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import useError from '../../hooks/useError'
import useOptions from '../../hooks/useOptions'
import useStatisticsServices from '../../services/statistics'
import { ROWS_ON_PAGE } from '../../utils/constants'
import { arrangeFilterData, getFilters } from '../../utils/statistics'
import InputStatistics from '../Inputs/InputStatistics'
import styles from './index.module.css'

const StatisticsFilters = () => {
    const {
        statisticsState,
        setFilterData,
        clearSelection,
        setStatisticsResults,
        setResultsTotalCount,
        setResultsTotalHours,
        setResultsPage,
        setFiltersChanged,
    } = useContextStatistics()
    const [userOptions, setUserOptions] = useState({})
    const [areOldUsersIncluded, setAreOldUsersIncluded] = useState(false)
    const { setErrorMessage } = useError()
    const jobsOptions = useOptions('jobs')
    const zonesOptions = useOptions('zones')
    const systemsOptions = useOptions('systems')
    const leadsOptions = useOptions('leads')
    const activitiesOptions = useOptions('activities')
    const revisionsOptions = useOptions('revisions')
    const {
        getAllUsers,
        getClientUsers,
        getFilterData,
        getProjectUsers,
        getStatistics,
        getTotalHours,
    } = useStatisticsServices() 

    const getData = useCallback(async () => {
        const data = await getFilterData()

        if (data.error) {
            setErrorMessage(data)
            return
        }

        setFilterData(data)
    }, [getFilterData, setErrorMessage, setFilterData])

    useEffect(() => {
        getData()
    }, [getData])

    const handleGetUsers = useCallback(async () => {
        if (Object.keys(statisticsState.selectedProjects).length) {
            const userResponse = await getProjectUsers(Object.keys(statisticsState.selectedProjects), areOldUsersIncluded)

            if (userResponse.error) {
                setErrorMessage(userResponse)
                return
            }

            const data = arrangeFilterData({ users: userResponse })
            setUserOptions(data.users)
            return
        }

        if (Object.keys(statisticsState.selectedClients).length) {
            const userResponse = await getClientUsers(Object.keys(statisticsState.selectedClients), areOldUsersIncluded)

            if (userResponse.error) {
                setErrorMessage(userResponse)
                return
            }

            const data = arrangeFilterData({ users: userResponse })
            setUserOptions(data.users)
            return
        }

        const userResponse = await getAllUsers(areOldUsersIncluded)

        if (userResponse.error) {
            setErrorMessage(userResponse)
            return
        }

        const data = arrangeFilterData({ users: userResponse })
        setUserOptions(data.users)
    }, [
        statisticsState.selectedProjects, 
        statisticsState.selectedClients, 
        getAllUsers, 
        areOldUsersIncluded, 
        getProjectUsers, 
        setErrorMessage, 
        getClientUsers
    ])

    useEffect(() => {
        handleGetUsers()
    }, [handleGetUsers])

    const clientOptions = useMemo(() => {
        let clients = {}

        if (Object.keys(statisticsState.selectedProjects).length) {
            Object.keys(statisticsState.selectedProjects).forEach((selectedProjectId) => {
                const clientId = statisticsState.projects[selectedProjectId].clientid
                clients[clientId] = statisticsState.clients[clientId].clientname
            })
        } else {
            for (const key in statisticsState.clients) {
                clients[key] = statisticsState.clients[key].clientname
            }
        }

        return clients
    }, [statisticsState.clients, statisticsState.projects, statisticsState.selectedProjects])

    const projectOptions = useMemo(() => {
        let projects = {}

        if (Object.keys(statisticsState.selectedClients).length) {
            Object.keys(statisticsState.selectedClients).forEach((selectedClientId) => {
                const currClientProjects = statisticsState.clients[selectedClientId].projects
                for (const key in currClientProjects) {
                    projects[key] = currClientProjects[key].projectname
                }
            })

            return projects
        } else {
            for (const key in statisticsState.projects) {
                projects[key] = statisticsState.projects[key].projectname
            }
        }

        return projects
    }, [statisticsState.clients, statisticsState.projects, statisticsState.selectedClients])

    const handleGetTotalHours = async (filters) => {
        const response = await getTotalHours(filters)
        
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setResultsTotalHours(response.totalHours)
    }

    const handleDisplay = async () => {
        const filters = getFilters(statisticsState)
        const response = await getStatistics(filters, 1, ROWS_ON_PAGE, true)
        
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setFiltersChanged(false)
        setStatisticsResults(response.statistics)
        setResultsTotalCount(response.count)
        setResultsPage(1)
        if (response.count <= ROWS_ON_PAGE) {
            const totalHours = response.statistics.reduce((acc, row) => acc + row.hours, 0)
            setResultsTotalHours(totalHours)
            return
        }

        handleGetTotalHours(filters)
    }

    return (
        <div className={styles.container}>
            <InputStatistics placeholder='Client' type='client' options={clientOptions} />
            <InputStatistics placeholder='Project' type='project' options={projectOptions} />
            <InputStatistics placeholder='Job' type='job' options={jobsOptions} />
            <InputStatistics placeholder='Zone' type='zone' options={zonesOptions} />
            <InputStatistics placeholder='System' type='system' options={systemsOptions} />
            <InputStatistics placeholder='Lead/Department' type='lead' options={leadsOptions} />
            <InputStatistics placeholder='Activity' type='activity' options={activitiesOptions} />
            <InputStatistics placeholder='Revision' type='revision' options={revisionsOptions} />
            <div className={styles.user}>
                <InputStatistics placeholder='User' type='user' options={userOptions} />
                <input
                    type='checkbox'
                    title='Include old users'
                    checked={areOldUsersIncluded}
                    onChange={() => setAreOldUsersIncluded(!areOldUsersIncluded)}
                />
            </div>
            <button className={styles.button} onClick={handleDisplay}>
                Display
            </button>
            <button onClick={clearSelection}>Clear Filters</button>
            {statisticsState.filtersChanged && (
                <div className={styles.alert}>
                    Some filters have not been applied or have been removed. Data shown has not been updated.
                </div>
            )}
        </div>
    )
}

export default StatisticsFilters
