const deepCopy = (objectToCopy, { convertSetToArray = false } = {}) => {
    const objectKeys = Object.keys(objectToCopy)
    const copy = Array.isArray(objectToCopy) ? [...objectToCopy] : { ...objectToCopy }
    for (const key of objectKeys) {
        const value = copy[key]

        if (typeof(value) === 'object') {
            if (value instanceof Date) {
                copy[key] = new Date(value)
                continue
            }

            if (value instanceof Set) {
                if (value.size === 0) {
                    delete copy[key]
                    continue
                }

                copy[key] = convertSetToArray ? Array.from(copy[key]) : new Set(copy[key])

                continue
            }

            if (value instanceof Map) {
                copy[key] = new Map(value)
                continue
            }

            copy[key] = deepCopy(copy[key], { convertSetToArray })
        }
    }

    return copy
}

export {
    deepCopy
}