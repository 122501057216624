import { useContextTimeCard } from '../contexts/timeCardContext'
import { SERVER_ERROR_MESSAGE_DURATION_MS } from '../utils/constants'
import { checkTasksForDuplicates, getTasksForSave } from '../utils/userTasks'

const useValidatedTimeCards = () => {
    const { tasksState, setAreTasksOk, setAreHoursOk } = useContextTimeCard()

    return () => {
        const areTasksDuplicated = checkTasksForDuplicates(tasksState.tasks)
        if (areTasksDuplicated) {
            return { error: 'You can not have duplicated tasks' }
        }
    
        const { tasksForSave, areTasksCorrect, areHoursCorrect, timeCardsForUpdate } = getTasksForSave(
            tasksState,
            tasksState.savedTimeCard
        )
        
        if (!areTasksCorrect) {
            setAreTasksOk(false)
            setTimeout(() => {
                setAreTasksOk(true)
            }, SERVER_ERROR_MESSAGE_DURATION_MS)
        
            return { error: 'Please fill in task inputs with correct values' }
        }
        
        if (!areHoursCorrect) {
            setAreHoursOk(false)
            setTimeout(() => {
                setAreHoursOk(true)
            }, SERVER_ERROR_MESSAGE_DURATION_MS)
        
            return { error: 'Please fill in hour inputs with correct values' }
        }
        
        if (!tasksForSave.length && !timeCardsForUpdate.length) {
            return { error: 'Working hours have not been filled in' }
        }
    
        return { tasksForSave, timeCardsForUpdate }
    }
}

export default useValidatedTimeCards
