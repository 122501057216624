import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Task from '../Task'
import ResponsiveInput from '../../Inputs/ResponsiveInput'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import { arrangeProjectColumnData, getTaskKey } from '../../../utils/userTasks'
import useProjectServices from '../../../services/project'
import useError from '../../../hooks/useError'

const Project = ({ project, unselectedProjects, allProjects, projectIndex, clientIndex, isLastElement }) => {
    const { tasksState, selectProject, returnProject, addProject, setProjectColumnData } = useContextTimeCard()
    const { getProject } = useProjectServices()
    const [projectTasks, setProjectTasks] = useState([])
    const [projectData, setProjectData] = useState({})
    const [isInCurrRequest, setIsInCurrRequest] = useState(false)
    const { setErrorMessage } = useError()

    useEffect(() => {
        const taskKey = getTaskKey(clientIndex, projectIndex)
        const currProjectTasks = tasksState.tasks[taskKey] || []
        setProjectTasks(currProjectTasks)

        if (!project || !project.projectid) return

        let newProjectData = tasksState.projectsColumnData[project.projectid]
        if (!newProjectData) {
            const fetchData = async () => {
                const response = await getProject(project.projectid)

                if (response.error) {
                    setErrorMessage(response)
                    return
                }

                const newProjectColumnData = arrangeProjectColumnData(response)

                setProjectColumnData(newProjectColumnData, project.projectid)
                setProjectData(newProjectColumnData)
            }

            fetchData()
            return
        }

        setProjectData(newProjectData)
    }, [clientIndex, getProject, project, projectIndex, setErrorMessage, setProjectColumnData, tasksState.projectsColumnData, tasksState.tasks])

    useEffect(() => {
        //checking if the project have tasks with filled hours
        const taskKey = getTaskKey(clientIndex, projectIndex)
        const tasks = tasksState.tasks[taskKey]
        if (!tasks) {
            return
        }

        let haveFilledHours = false
        for (const task of tasks) {
            haveFilledHours = !!Object.keys(task.hours).length

            if (haveFilledHours) {
                break
            }
        }

        setIsInCurrRequest(haveFilledHours)

    }, [clientIndex, projectIndex, tasksState.tasks])

    const handleSelectProject = (project) => {
        selectProject(project, clientIndex, projectIndex)
    }

    const handleReturnProject = (newProjectName) => {
        returnProject(newProjectName, clientIndex, projectIndex)
    }

    return (
        <div className={styles.container}>
            <div className={styles['input-container']}>
                <ResponsiveInput
                    title='Project'
                    unselectedOptions={unselectedProjects}
                    options={allProjects}
                    setOption={handleSelectProject}
                    returnOption={handleReturnProject}
                    initialValue={project}
                    isInCurrRequest={isInCurrRequest}
                    index={0}
                />
                {isLastElement && 
                    <button onClick={() => addProject(clientIndex)} className={styles.button}></button>
                }
            </div>
            <div className={styles.tasks}>
                {projectTasks.map((task, i) => (
                    <div key={i} className={styles.task} >
                        <Task
                            project={project}
                            projectData={projectData}
                            task={task}
                            taskIndex={i}
                            projectIndex={projectIndex}
                            clientIndex={clientIndex}
                            isLastElement={i === projectTasks.length - 1}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Project
