import React, { useEffect, useState } from 'react'
import InputUser from '../Inputs/InputUser'
import UserReminder from '../UserReminder'
import useUserServices from '../../services/user'
import styles from './index.module.css'
import pen from '../../public/pen.svg'
import cancel from '../../public/cancel.svg'
import UserMobileEditMenu from '../UserMobileEditMenu'
import useError from '../../hooks/useError'

const User = ({ initialUser, setCurrentUsers, setOldUsers }) => {
    const [user, setUser] = useState(initialUser)
    const [isUserDisabled, setIsUserDisabled] = useState(true)
    const [userBeforeChange, setUserBeforeChange] = useState(null)
    const [isMobileEditMenuShown, setIsMobileEditMenuShown] = useState(false)
    const { setErrorMessage } = useError()
    const { editUser, sendUserToOld } = useUserServices()

    useEffect(() => {
        setUser(initialUser)
    }, [initialUser])

    const handleChange = (e, type) => {
        setUser({ ...user, [type]: e.target.value })
    }

    const handleEditUser = () => {
        setUserBeforeChange({ ...user })
        if (window.innerWidth < 1366) {
            setIsMobileEditMenuShown(true)
            return
        }

        setIsUserDisabled(false)
    }

    const handleConfirm = async () => {
        const editedUser = { ...user }

        if (editedUser.hours === userBeforeChange.hours) {
            delete editedUser.hours
        }

        const response = await editUser(editedUser)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setUserBeforeChange(null)
        setIsUserDisabled(true)
    }

    const handleCancel = () => {
        setUser({ ...userBeforeChange })
        setIsUserDisabled(true)
    }

    const handleSendUserToOld = async () => {
        const response = await sendUserToOld(initialUser.userid)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setCurrentUsers((currUsers) => {
            return currUsers.filter((u) => u.userid !== initialUser.userid)
        })

        setOldUsers((oldUsers) => {
            if (oldUsers === null) return

            return [...oldUsers, response]
        })
    }

    return (
        <div>
            <div className={styles.container}>
                <InputUser
                    value={user.name}
                    type='name'
                    handleChange={handleChange}
                    isDisabled={isUserDisabled}
                    customClass={styles['big-input']}
                />
                <InputUser
                    value={user.username}
                    type='username'
                    handleChange={handleChange}
                    isDisabled={isUserDisabled}
                    customClass={styles['small-input']}
                />
                <InputUser
                    value={user.email}
                    type='email'
                    handleChange={handleChange}
                    isDisabled={isUserDisabled}
                    customClass={`${styles['big-input']} ${styles.responsive}`}
                />
                <select
                    className={`${styles.select} ${styles.responsive}`}
                    value={user.role}
                    onChange={(e) => handleChange(e, 'role')}
                    disabled={isUserDisabled}
                >
                    <option>user</option>
                    <option>statistics</option>
                    <option>admin</option>
                </select>
                <div className={`${styles.reminder} ${styles.responsive}`}>
                    <UserReminder
                        reminderWeekday={user.reminderWeekday}
                        reminderHour={user.reminderHour}
                        reminderIsActivated={user.reminderIsActivated}
                        setUser={setUser}
                        customStyles={styles}
                        isDisabled={isUserDisabled}
                        userId={user.userid}
                    />
                </div>
                {isUserDisabled ? (
                    <div className={styles['buttons-container']}>
                        <button className={styles['edit-button']} onClick={handleEditUser}>
                            <img src={pen} alt={''} height='16' />
                        </button>
                    </div>
                ) : (
                    <div className={styles['buttons-container']}>
                        <button
                            className={styles['edit-button']}
                            onClick={handleConfirm}
                            title='Confirm user changes'
                        >
                            ✓
                        </button>
                        <button
                            className={styles['cancel-button']}
                            onClick={handleCancel}
                            title='Cancel user changes'
                        >
                            <img src={cancel} alt={''} width='25' height='16' />
                        </button>
                    </div>
                )}
            </div>
                {!isUserDisabled && (
                    <>
                        <InputUser
                            value={user.password || ''}
                            type='password'
                            handleChange={handleChange}
                            isDisabled={isUserDisabled}
                            placeholder='Change Password'
                            customClass={styles['big-input']}
                        />
                        <button
                            className={styles['old-button']}
                            onClick={handleSendUserToOld}
                        >
                            Send to old users
                        </button>
                    </>
                )}
                {isMobileEditMenuShown && (
                    <UserMobileEditMenu
                        user={user}
                        handleChange={handleChange}
                        setUser={setUser}
                        handleSendUserToOld={handleSendUserToOld}
                        handleCancel={() => setIsMobileEditMenuShown(false)}
                        handleConfirm={() => {
                            handleConfirm()
                            setIsMobileEditMenuShown(false)
                        }}
                    />
                )}
        </div>
    )
}

export default User
