import { formatDate } from "./date"

const getRequestStringFromObject = (obj, parameterName) => {
    let requestString = `?`
    const keySubstrings = []

    for (const key in obj) {
        const currKey = parameterName + `[${key}]`
        const string = getRequestStringForKey(currKey, obj[key])
        keySubstrings.push(string)
    }

    return requestString + keySubstrings.flat().join('&')
}

const getRequestStringForKey = (currKey, keyValue) => {
    if (typeof keyValue !== 'object') {
        return `${currKey}=${keyValue}`
    }

    if (keyValue instanceof Date) {
        if (isNaN(keyValue)) {
            throw new Error('Invalid date')
        }

        return `${currKey}=${formatDate(keyValue, '%y-%m-%d')}`
    }

    if (Array.isArray(keyValue)) {
        // TODO
    }

    const keySubstrings = []
    // if reached here keyValue is an object
    for (const key in keyValue) {
        const newCurrKey = currKey + `[${key}]`
        const string = getRequestStringForKey(newCurrKey, keyValue[key])
        keySubstrings.push(string)
    }

    return keySubstrings.flat()
}

export {
    getRequestStringFromObject
}