import { deepCopy } from './deepCopy'
import { arrangeClientsProjects, getNewClient, getNewProject, getNewTask, getSavedTaskKey, getTaskKey, rearrangeTasks } from './userTasks'

const timecardReducer = (tasksState, action) => {
    const { value, type } = action

    switch (type) {
        case 'setStoredData': {
            const { selectedClients, tasks, projectsColumnData } = value

            const newSelectedClients = deepCopy(tasksState.clients.filter(c => {
                return selectedClients.find(sc => c.clientid === sc.clientid)
            }))

            for (const client of selectedClients) {
                if (!client.selectedProjects.length || !client.clientid) continue
                const newClientIndex = newSelectedClients.findIndex(nsc => nsc.clientid === client.clientid)
                const newSelectedProjects = deepCopy(client.projects.filter(p => {
                    return client.selectedProjects.find(sp => p.projectid === sp.projectid)
                }))

                newSelectedClients[newClientIndex].selectedProjects = newSelectedProjects
            }

            if (!newSelectedClients.length) {
                newSelectedClients.push(getNewClient())
            }

            return { ...tasksState, selectedClients: newSelectedClients, tasks, projectsColumnData }
        }
        case 'setClients': {
            const projects = value
            const clients = arrangeClientsProjects(projects, tasksState.clients)

            const newSelectedClients = tasksState.selectedClients.map((client) => {
                if (client.clientid === '') {
                    return client
                }

                const newClient = { ...client }
                const clientIndex = clients.findIndex((c) => c.clientid === newClient.clientid)

                newClient.projects = clients[clientIndex].projects

                const newSelectedProjects = newClient.selectedProjects.map((sp) =>
                    newClient.projects.find((p) => {
                        if (!sp) {
                            return false
                        }

                        return p.projectid === sp.projectid
                    })
                )
                newClient.selectedProjects = newSelectedProjects
                return newClient
            })

            return { ...tasksState, clients, selectedClients: newSelectedClients }
        }
        case 'addProjectsColumnData': {
            return { ...tasksState, projectsColumnData: { ...tasksState.projectsColumnData, ...value } }
        }
        case 'setUnselectedClients': {
            const unselectedClients = value
            return { ...tasksState, unselectedClients }
        }
        case 'setUserTasks': {
            let { newTasks, newSelectedClients } = value
            if (!newSelectedClients || !newSelectedClients.length) {
                newSelectedClients = [getNewClient()]

                const taskKey = getTaskKey(0, 0)
                newTasks[taskKey] = [getNewTask()]
            }

            return { ...tasksState, tasks: newTasks, selectedClients: newSelectedClients }
        }
        case 'setSavedUserTasks': {
            let { newTasks, newSelectedClients } = value
            

            return { ...tasksState, tasks: newTasks, selectedClients: newSelectedClients }
        }
        case 'setSelectedDays': {
            return { ...tasksState, selectedDays: value }
        }
        case 'setSelectedMonth': {
            return { ...tasksState, selectedMonth: value }
        }
        case 'addClient': {
            const clientIndex = tasksState.selectedClients.length
            const projectIndex = 0
            const taskKey = getTaskKey(clientIndex, projectIndex)

            const newTasks = { ...tasksState.tasks }
            newTasks[taskKey] = [getNewTask()]

            return {
                ...tasksState,
                selectedClients: [...tasksState.selectedClients, getNewClient()],
                tasks: newTasks,
            }
        }
        case 'setAdminClients': {
            const resultClients = []
            for (const client of value) {
                if (tasksState.clients.find((c) => c.clientid === client.clientid)) continue

                const clientCopy = { ...client }
                clientCopy.projects = []
                resultClients.push(clientCopy)
            }

            return { ...tasksState, clients: [...tasksState.clients, ...resultClients] }
        }
        case 'selectClient': {
            const { client, clientIndex } = value

            const newSelectedClients = [...tasksState.selectedClients]
            const selectedProjects = newSelectedClients[clientIndex].selectedProjects
            const newClient = { ...client }
            newClient.selectedProjects = selectedProjects
            newSelectedClients[clientIndex] = newClient

            const newTasks = { ...tasksState.tasks }
            for (let i = 0; i < selectedProjects.length; i++) {
                const taskKey = getTaskKey(clientIndex, i)
                const projectTasks = [...newTasks[taskKey]]

                for (let j = 0; j < projectTasks.length; j++) {
                    projectTasks[j] = { ...projectTasks[j], clientid: client.clientid }
                }

                newTasks[taskKey] = projectTasks
            }

            return { ...tasksState, selectedClients: newSelectedClients, tasks: newTasks }
        }
        case 'returnClient': {
            const { newClientName, clientIndex } = value

            const selectedClients = [...tasksState.selectedClients]
            const currClient = { ...selectedClients[clientIndex] }
            currClient.clientname = newClientName
            currClient.clientid = ''
            currClient.projects = []
            selectedClients[clientIndex] = currClient

            return { ...tasksState, selectedClients }
        }
        case 'addProject': {
            const clientIndex = value

            const newSelectedClients = [...tasksState.selectedClients]
            newSelectedClients[clientIndex] = { ...newSelectedClients[clientIndex] }

            newSelectedClients[clientIndex].selectedProjects = [
                ...newSelectedClients[clientIndex].selectedProjects,
                getNewProject(),
            ]

            const projectIndex = newSelectedClients[clientIndex].selectedProjects.length - 1
            const taskKey = getTaskKey(clientIndex, projectIndex)
            const newTasks = { ...tasksState.tasks }
            const newTask = getNewTask()
            newTask.clientid = newSelectedClients[clientIndex].clientid

            newTasks[taskKey] = [newTask]

            return { ...tasksState, selectedClients: newSelectedClients, tasks: newTasks }
        }
        case 'selectProject': {
            const { project, clientIndex, projectIndex } = value

            const newSelectedClients = [...tasksState.selectedClients]
            const newClient = { ...newSelectedClients[clientIndex] }
            const newSelectedProjects = [...newClient.selectedProjects]
            newSelectedProjects[projectIndex] = project
            newClient.selectedProjects = newSelectedProjects
            newSelectedClients[clientIndex] = newClient

            const taskKey = getTaskKey(clientIndex, projectIndex)
            const newTasks = { ...tasksState.tasks }
            const projectTasks = [...newTasks[taskKey]]

            for (let i = 0; i < projectTasks.length; i++) {
                projectTasks[i] = { ...projectTasks[i], projectid: project.projectid, lastchange: project.lastchange }
            }

            newTasks[taskKey] = projectTasks

            return { ...tasksState, selectedClients: newSelectedClients, tasks: newTasks }
        }
        case 'returnProject': {
            const { newProjectName, clientIndex, projectIndex } = value

            const newSelectedClients = [...tasksState.selectedClients]
            const newClient = { ...newSelectedClients[clientIndex] }
            const newSelectedProjects = [...newClient.selectedProjects]
            const newProject = { ...newSelectedProjects[projectIndex] }
            newProject.projectname = newProjectName
            newProject.projectid = ''

            const taskKey = getTaskKey(clientIndex, projectIndex)
            const newTasks = { ...tasksState.tasks }
            const projectTasks = [...newTasks[taskKey]]

            for (let i = 0; i < projectTasks.length; i++) {
                projectTasks[i] = { ...projectTasks[i], projectid: '' }
            }

            newTasks[taskKey] = projectTasks

            newSelectedProjects[projectIndex] = newProject
            newClient.selectedProjects = newSelectedProjects
            newSelectedClients[clientIndex] = newClient

            return { ...tasksState, selectedClients: newSelectedClients, tasks: newTasks }
        }
        case 'setProjectColumnData': {
            const { projectColumnData, projectid } = value
            const projectsColumnData = {
                ...tasksState.projectsColumnData,
                [projectid]: projectColumnData,
            }
            return { ...tasksState, projectsColumnData }
        }
        case 'addTask': {
            const { clientIndex, projectIndex } = value

            const taskKey = getTaskKey(clientIndex, projectIndex)
            const newTasks = { ...tasksState.tasks }
            const newTask = getNewTask()
            const currClient = tasksState.selectedClients[clientIndex]
            newTask.clientid = currClient.clientid
            newTask.projectid = currClient.selectedProjects[projectIndex].projectid
            newTask.lastchange = currClient.selectedProjects[projectIndex].lastchange
            const newProjectTasks = [...newTasks[taskKey], newTask]

            newTasks[taskKey] = newProjectTasks

            return { ...tasksState, tasks: newTasks }
        }
        case 'editTask': {
            const { option, type, clientIndex, projectIndex, taskIndex } = value

            const name = type + 'name'
            const id = type + 'id'
            const taskKey = getTaskKey(clientIndex, projectIndex)
            const newTasks = { ...tasksState.tasks }
            const newProjectTasks = [...newTasks[taskKey]]
            const newTask = { ...newProjectTasks[taskIndex] }
            newTask[name] = option[name]
            newTask[id] = option[id]

            newProjectTasks[taskIndex] = newTask
            newTasks[taskKey] = newProjectTasks

            return { ...tasksState, tasks: newTasks }
        }
        case 'taskCheckbox': {
            const { clientIndex, projectIndex, taskIndex, taskid } = value

            const taskKey = getTaskKey(clientIndex, projectIndex)
            if (!tasksState.tasks[taskKey]) return tasksState
            
            const newTasks = { ...tasksState.tasks }
            const projectTasks = [...newTasks[taskKey]]
            const currTask = { ...projectTasks[taskIndex] }
            currTask.isSaved = true
            currTask.taskid = taskid

            projectTasks[taskIndex] = currTask
            newTasks[taskKey] = projectTasks

            return { ...tasksState, tasks: newTasks }
        }
        case 'deleteTask': {
            const { clientIndex, projectIndex, taskIndex } = value

            const taskKey = getTaskKey(clientIndex, projectIndex)
            let newTasks = { ...tasksState.tasks }
            const projectTasks = [...newTasks[taskKey]]

            if (projectTasks.length > 1) {
                projectTasks.splice(taskIndex, 1)
                newTasks[taskKey] = projectTasks

                return { ...tasksState, tasks: newTasks }
            }

            delete newTasks[taskKey]

            const selectedClients = [...tasksState.selectedClients]
            const currClient = { ...selectedClients[clientIndex] }
            const selectedProjects = [...currClient.selectedProjects]

            if (selectedProjects.length > 1) {
                selectedProjects.splice(projectIndex, 1)
                newTasks = rearrangeTasks(newTasks, taskKey, false)
                currClient.selectedProjects = selectedProjects
                selectedClients[clientIndex] = currClient

                return { ...tasksState, selectedClients, tasks: newTasks }
            }

            selectedClients.splice(clientIndex, 1)
            newTasks = rearrangeTasks(newTasks, taskKey, true)

            if (selectedClients.length === 0) {
                selectedClients.push(getNewClient())

                const newTaskKey = getTaskKey(0, 0)
                newTasks[newTaskKey] = [getNewTask()]
            }

            return { ...tasksState, selectedClients, tasks: newTasks }
        }
        case 'setTaskHours': {
            const { id, hours, clientIndex, projectIndex, taskIndex } = value

            const taskKey = getTaskKey(clientIndex, projectIndex)
            const newTasks = { ...tasksState.tasks }
            const projectTasks = [...newTasks[taskKey]]
            const currTask = { ...projectTasks[taskIndex] }
            const currHours = { ...currTask.hours, [id]: hours }

            if (!currHours[id]) {
                delete currHours[id]
            }

            currTask.hours = currHours
            projectTasks[taskIndex] = currTask
            newTasks[taskKey] = projectTasks

            return { ...tasksState, tasks: newTasks }
        }
        case 'restoreTask': {
            const oldTask = value
            const taskKey = getSavedTaskKey(Object.values(oldTask)[0])
            const clientid = Object.values(oldTask)[0].clientid
            const projectid = Object.values(oldTask)[0].projectid

            const newSavedTimeCard = { ...tasksState.savedTimeCard }
            const newClient = newSavedTimeCard[clientid]
            const newProject = newClient[projectid]
            newProject[taskKey] = oldTask

            newClient[projectid] = newProject
            newSavedTimeCard[clientid] = newClient

            return { ...tasksState, savedTimeCard: newSavedTimeCard }
        }
        case 'setAreTasksOk': {
            const { type, newState } = value
            const areTasksOk = { ...tasksState.areTasksOk, [type]: newState }

            return { ...tasksState, areTasksOk }
        }
        case 'setSavedTimeCard': {
            return { ...tasksState, savedTimeCard: value }
        }
        case 'deleteSavedTasks': {
            const { taskKey, clientid, projectid } = value

            const savedTimeCard = { ...tasksState.savedTimeCard }
            const currClient = { ...savedTimeCard[clientid] }
            const currProject = { ...currClient[projectid] }
            delete currProject[taskKey]

            currClient[projectid] = currProject
            savedTimeCard[clientid] = currClient

            if (!Object.keys(currProject).length) {
                delete currClient[projectid]
            }

            if (!Object.keys(currClient).length) {
                delete savedTimeCard[clientid]
            }

            return { ...tasksState, savedTimeCard }
        }
        case 'changeSavedHours': {
            const { taskKey, clientid, projectid, day, newValue } = value

            const savedTimeCard = { ...tasksState.savedTimeCard }
            const currClient = { ...savedTimeCard[clientid] }
            const currProject = { ...currClient[projectid] }
            const currWeek = { ...currProject[taskKey] }
            const currTask = { ...currWeek[day] }
            currTask.hours = newValue

            currWeek[day] = currTask
            currProject[taskKey] = currWeek
            currClient[projectid] = currProject
            savedTimeCard[clientid] = currClient
            return { ...tasksState, savedTimeCard }
        }
        case 'setUpdatedTimeCardIds': {
            return { ...tasksState, updatedTimeCardIds: [...value] }
        }
        case 'clearHours': {
            const newTasks = { ...tasksState.tasks }

            for (const key in newTasks) {
                const projectTasks = [...newTasks[key]]

                for (let i = 0; i < projectTasks.length; i++) {
                    const currTask = { ...projectTasks[i], hours: {} }
                    projectTasks[i] = currTask
                }

                newTasks[key] = projectTasks
            }

            return { ...tasksState, tasks: newTasks }
        }
        case 'addProjectFromNewSaveInDB': {
            const newClientId = value.clientid
            const newClients = [...tasksState.clients]
            const indexOfChangedClient = newClients.findIndex((c) => c.clientid === newClientId)
            const newClient = { ...newClients[indexOfChangedClient] }

            const newProjects = [...newClient.projects]
            newProjects.push(value)

            newClient.projects = newProjects
            newClients[indexOfChangedClient] = newClient

            const newSelectedClients = [...tasksState.selectedClients]
            const selectedClientIndex = newSelectedClients.findIndex((c) => c.clientid === newClientId)

            if (selectedClientIndex !== -1) {
                const newSelectedClient = { ...newSelectedClients[selectedClientIndex] }

                const newUnselectedProjects = [...newSelectedClient.projects]
                newUnselectedProjects.push(value)
                newSelectedClient.projects = newUnselectedProjects
                newSelectedClients[selectedClientIndex] = newSelectedClient

                return { ...tasksState, clients: newClients, selectedClients: newSelectedClients }
            }

            return { ...tasksState, clients: newClients }
        }
        case 'editProjectInDB': {
            const newClientId = value.clientid
            const id = value.projectid
            const newClients = [...tasksState.clients]
            const clientIndex = newClients.findIndex((c) => c.clientid === newClientId)
            const newClient = { ...newClients[clientIndex] }

            const newProjects = [...newClient.projects]
            const projectIndex = newProjects.findIndex((p) => p.projectid === id)

            const newProject = {
                clientid: value.clientid,
                clientname: value.clientname,
                projectid: value.projectid,
                projectname: value.projectname,
                projectmore: value.projectmore,
                lastchange: value.lastchange,
                has_zones: value.has_zones,
                has_activities: value.has_activities,
                has_jobs: value.has_jobs,
                has_leads: value.has_leads,
                has_revisions: value.has_revisions,
                has_systems: value.has_systems,
            }

            if (newProjects[projectIndex]) {
                newProjects[projectIndex] = newProject
            } else {
                newProjects.push(newProject)
            }

            newClient.projects = newProjects
            newClients[clientIndex] = newClient

            const newSelectedClients = [...tasksState.selectedClients]
            const selectedClientIndex = newSelectedClients.findIndex((c) => c.clientid === newClientId)

            if (selectedClientIndex !== -1) {
                const newSelectedClient = { ...newSelectedClients[selectedClientIndex] }

                const newUnselectedProjects = [...newSelectedClient.projects]
                const unselectedProjectIndex = newUnselectedProjects.findIndex((p) => p.projectid === id)
                if (unselectedProjectIndex !== -1) {
                    newUnselectedProjects[unselectedProjectIndex] = newProject
                } else {
                    newUnselectedProjects.push(newProject)
                }

                const newSelectedProjects = [...newSelectedClient.selectedProjects]
                const selectedProjectIndex = newSelectedProjects.findIndex((p) => p.projectid === id)
                if (selectedProjectIndex !== -1) {
                    newSelectedProjects[selectedProjectIndex] = newProject
                }

                newSelectedClient.projects = newUnselectedProjects
                newSelectedClient.selectedProjects = newSelectedProjects

                newSelectedClients[selectedClientIndex] = newSelectedClient

                return { ...tasksState, clients: newClients, selectedClients: newSelectedClients }
            }

            return { ...tasksState, clients: newClients }
        }
        case 'setHolidays': {
            return { ...tasksState, holidays: value }
        }
        case 'setOvertime': {
            const { week, hours, ischanged, isconfirmed, islocked, overtimeid } = value
            const overtime = { ...tasksState.overtime, week, hours, ischanged, isconfirmed, islocked, overtimeid }

            return { ...tasksState, overtime }
        }
        case 'setMonthConfirmation': {
            return { ...tasksState, monthConfirmation: value }
        }
        case 'updateProjectLastChange': {
            const projects = Object.values(value)
            const newClients = [...tasksState.clients]
            const newSelectedClients = [...tasksState.selectedClients]

            for (const project of projects) {
                const clientIndex = newClients.findIndex((c) => c.clientid === project.clientid)
                const currClient = { ...newClients[clientIndex] }
                const newProjects = [...currClient.projects]
                const projectIndex = newProjects.findIndex((p) => p.projectid === project.projectid)
                const currProject = { ...newProjects[projectIndex] }
                currProject.lastchange = project.lastchange
                currProject.projectname = project.projectname

                newProjects[projectIndex] = currProject
                currClient.projects = newProjects
                newClients[clientIndex] = currClient

                const selectedClientIndex = newSelectedClients.findIndex((c) => c.clientid === project.clientid)
                const currSelectedClient = { ...newSelectedClients[selectedClientIndex] }
                currSelectedClient.projects = deepCopy(newProjects)

                newSelectedClients[selectedClientIndex] = currSelectedClient
            }

            return { ...tasksState, clients: newClients, selectedClients: newSelectedClients }
        }
        case 'updateUserTasks': {
            const projects = Object.values(value)
            const newTasks = { ...tasksState.tasks }

            for (const project of projects) {
                for (const projectTasksKey in newTasks) {
                    if (newTasks[projectTasksKey][0].projectid === project.projectid) {
                        let newProjectTasks = [...newTasks[projectTasksKey]]
                        newProjectTasks = newProjectTasks.map(task => ({ ...task, lastchange: project.lastchange }))
                        newTasks[projectTasksKey] = newProjectTasks
                    }
                }
            }

            return { ...tasksState, tasks: newTasks }
        }
        default: {
            return tasksState
        }
    }
}

export default timecardReducer