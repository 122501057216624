import React from 'react'
import styles from './index.module.css'
import useForm from '../../hooks/useForm'
import Input from '../../components/Inputs/Input'
import { useHistory } from 'react-router-dom'
import { useContextSession } from '../../contexts/sessionContext'
import useUserServices from '../../services/user'
import { useContextTimeCard } from '../../contexts/timeCardContext'
import logo from '../../public/images/smart-design.png'
import { arrangeClientsProjects } from '../../utils/userTasks'
import useError from '../../hooks/useError'

const data = {
    username: '',
    password: ''
}

const HomePage = () => {
    const [values, handleChange] = useForm(data)
    const { setUser } = useContextSession()
    const { setUserTasks, setProjects, setHolidays } = useContextTimeCard()
    const { userLogin } = useUserServices()
    const history = useHistory()
    const { setErrorMessage } = useError()

    const handleSubmit = async (e) => {
        e.preventDefault()

        const response = await userLogin(values)
        if (response.error) {
            setErrorMessage(response)
            return
        }

        const { user, projects, tasks, holidays } = response
        setProjects(projects)
        setUser(user)

        const clients = arrangeClientsProjects(projects)
        setUserTasks(tasks, clients)
        setHolidays(holidays)

        if (user && (user.role === 'admin' || user.role === 'statistics')) {
            history.push('/statistics')
            return
        }

        if (user && user.role === 'user') {
            history.push('/time-card')
            return
        }
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <img className={styles.logo} src={logo} alt='' height='200'></img>
            <div>
                <Input
                    type='text'
                    name='username'
                    placeholder='username'
                    value={values.username}
                    onChange={handleChange}
                />
                <Input
                    type='password'
                    name='password'
                    placeholder='password'
                    value={values.password}
                    onChange={handleChange}
                />
                <button type='submit'>
                    Login
                </button>
            </div>
        </form>
    )
}

export default HomePage
