import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import HomePage from './pages/Home'
import AdminAddPage from './pages/AdminAdd'
import AdminEditPage from './pages/AdminEditPage'
import Statistics from './pages/Statistics'
import TimeCardPage from './pages/TimeCard'
import { useContextSession } from './contexts/sessionContext'
import Users from './pages/Users'

const Navigation = () => {
    const { user } = useContextSession()

    if (!user) {
        return (
            <div></div>
        )
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/'>
                    {user && (user.role === 'admin' || user.role === 'statistics')
                        ? <Redirect to='/statistics' />
                        : user.role === 'user' 
                            ? <Redirect to='/time-card' />
                            : <HomePage/>
                    }
                </Route>
                <Route exact path='/admin'>
                    {user && user.role === 'admin'
                        ? <AdminAddPage />
                        : <Redirect to='/' />
                    }
                </Route>
                <Route path='/admin/edit'>
                    {user && user.role === 'admin'
                        ? <AdminEditPage />
                        : <Redirect to='/' />
                    }
                </Route>
                <Route path='/users'>
                    {user && user.role === 'admin'
                        ? <Users />
                        : <Redirect to='/' />
                    }
                </Route>
                <Route path='/statistics'>
                    {user && ( user.role === 'admin' || user.role === 'statistics' )
                        ? <Statistics />
                        : <Redirect to='/' />
                    }
                </Route>
                <Route path='/time-card'>
                    {user && user.role !== 'guest'
                        ? <TimeCardPage />
                        : <Redirect to='/' />
                    }
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Navigation
