import React from 'react'
import useForm from '../../../hooks/useForm'
import useClientServices from '../../../services/client'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import Button from '../../Button'
import styles from './index.module.css'
import useError from '../../../hooks/useError'

const data = {
    name: '',
    more: ''
}

const AddClient = () => {
    const [values, handleChange, setNewFormValues] = useForm(data)
    const { setAdminClients } = useContextTimeCard()
    const { setErrorMessage } = useError()
    const { addNewClient } = useClientServices()

    const handleSubmit = async (event) => {
        event.preventDefault()

        const response = await addNewClient(values)
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setAdminClients([response])
        setNewFormValues(data)
    }
    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.title}>Add new client</div>
            <div className={styles.fields}>
                <div className={styles['line-input']}>
                    <div className={styles.name}>
                        Client name
                        <span className={styles.required}>/required/</span>
                    </div>
                    <div className={styles.name}>
                        Additional information
                        <span className={styles.required}>/not required/</span>
                    </div>
                </div>
                <div className={styles['line-input']}>
                    <input
                        type='text'
                        name='name'
                        placeholder='Client name'
                        value={values.name}
                        onChange={handleChange}
                        className={styles.input}
                    />
                    <input
                        type='text'
                        name='more'
                        placeholder='Additional information'
                        value={values.more}
                        onChange={handleChange}
                        className={styles.input}
                    />
                </div>
            </div>
            <Button title='SAVE CLIENT' />
        </form>
    )
}

export default AddClient