import React, { useCallback, useEffect, useMemo, useState } from 'react'
import UsersCalendar from './UsersCalendar'
import useUserServices from '../../services/user'
import useOvertimeServices from '../../services/overtime'
import useMonthConfirmationServices from '../../services/monthConfirmation'
import useError from '../../hooks/useError'
import styles from './index.module.css'
import UserRow from './UserRow'
import { getMonthWeekKeys, updateUsersMonthConfirmation, updateUsersOvertime } from '../../utils/overtime'
import lock from '../../public/images/lock.png'
import unlock from '../../public/images/unlock.png'
import { MONTHS } from '../../utils/date'

const UserOvertimeAndConfirmation = () => {
  const [dayOfSelectedMonth, setDayOfSelectedMonth] = useState(new Date())
  const currentMonthWeeks = useMemo(() => {
    return getMonthWeekKeys(dayOfSelectedMonth)
  }, [dayOfSelectedMonth])
  const [users, setUsers] = useState()
  const { setErrorMessage } = useError()
  const { getUsersWithOvertime } = useUserServices()
  const { lockWeek } = useOvertimeServices()
  const { lockMonth } = useMonthConfirmationServices()

  const fetchUsersWithOvertime = useCallback(async () => {
    const response = await getUsersWithOvertime(JSON.stringify(currentMonthWeeks))
    if (response.error) {
        setErrorMessage(response)
        return
    }

    setUsers(response.sort((a, b) => a.name.localeCompare(b.name)))
  }, [getUsersWithOvertime, setErrorMessage, currentMonthWeeks])

  useEffect(() => {
      fetchUsersWithOvertime()
  }, [fetchUsersWithOvertime])

  const handleLockWeek = async (weekKey, islocked) => {
    const response = await lockWeek(weekKey, islocked)
    if (response.error) {
      setErrorMessage(response)
      return
    }

    const updatedUsers = updateUsersOvertime(users, response)
    setUsers(updatedUsers.sort((a, b) => a.name.localeCompare(b.name)))
  }

  const handleLockMonth = async (islocked) => {
    const currMonth = new Date(dayOfSelectedMonth).getMonth()
    const currYear = new Date(dayOfSelectedMonth).getFullYear()
    const response = await lockMonth(`${currYear}-${currMonth}`, islocked)
    if (response.error) {
      setErrorMessage(response)
      return
    }

    const updatedUsers = updateUsersMonthConfirmation(users, response)
    setUsers(updatedUsers.sort((a, b) => a.name.localeCompare(b.name)))
  }

  return (
    <div className={styles.container}>
      <UsersCalendar setDayOfSelectedMonth={setDayOfSelectedMonth} />
      <div>
        <div className={styles.headers}>
          <div>Name</div>
          {currentMonthWeeks && currentMonthWeeks.map(w => (
            <div key={w}>
              <span className={styles.week}>{"Week" + w.slice(4)}</span>
              <button
                className={styles.button}
                title={`Lock week ${w.slice(5)}`}
                onClick={() => handleLockWeek(w, true)}
              >
                <img src={lock} alt='' height='14px'/>
              </button>
              <button
                className={styles.button}
                title={`Unlock week ${w.slice(5)}`}
                onClick={() => handleLockWeek(w, false)}
              >
                <img src={unlock} alt='' height='14px'/>
              </button>
            </div>
          ))}
          <div>
            <span className={styles.week}>Overall</span>
            <button
              className={styles.button}
              title={`Lock ${MONTHS.en[new Date(dayOfSelectedMonth).getMonth()]}`}
              onClick={() => handleLockMonth(true)}
            >
              <img src={lock} alt='' height='14px'/>
            </button>
            <button
              className={styles.button}
              title={`Unlock ${MONTHS.en[new Date(dayOfSelectedMonth).getMonth()]}`}
              onClick={() => handleLockMonth(false)}
            >
              <img src={unlock} alt='' height='14px'/>
            </button>
          </div>
        </div>
        <div className={styles.wrapper}>
          {users && users.map(u => (
            <UserRow
                key={u.userid}
                initialUser={u}
                currentMonthWeeks={currentMonthWeeks}
                dayOfSelectedMonth={dayOfSelectedMonth}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default UserOvertimeAndConfirmation
