import React, { useCallback, useEffect, useState } from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './index.css'
import { getWeekRange, getWeekDays } from '../../utils/date'
import { useContextTimeCard } from '../../contexts/timeCardContext'
import useMonthConfirmationServices from '../../services/monthConfirmation'
import useError from '../../hooks/useError'

const Calendar = ({ selectedDays, setSelectedDays, updateSavedTasks }) => {
    const [hoverRange, setHoverRange] = useState(undefined)
    const { tasksState, setSelectedMonth, setMonthConfirmation } = useContextTimeCard()
    const { holidays } = tasksState
    const { getMonthConfirmation } = useMonthConfirmationServices()
    const { setErrorMessage } = useError()

    const daysAreSelected = selectedDays.length > 0

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
        weekends: {
            daysOfWeek: [0, 6]
        },
        holidays: holidays.holidays,
        workingDays: holidays.workingDays
    }

    const handleOnDayClick = useCallback((date) => {
        const newDate = new Date(date)
        newDate.setHours(0,0,0,0)
        setSelectedDays(getWeekDays(newDate))
        updateSavedTasks(getWeekDays(newDate))
    }, [setSelectedDays, updateSavedTasks])
    
    const handleOnWeekClick = (_, days, e) => {
        const convertedDays = days.map(d => {
            const newDate = new Date(d)
            newDate.setHours(0,0,0,0)
            return newDate
        })

        setSelectedDays(convertedDays)
        updateSavedTasks(convertedDays)
    }
    
    const handleOnTodayButtonClick = (date, modifiers) => {
        setSelectedDays(getWeekDays(date))
        updateSavedTasks(getWeekDays(date))
    }

    const handleOnMonthChange = async (date) => {
        const newSelectedMonth = new Date(date).getMonth()
        const newSelectedYear = new Date(date).getFullYear()
        const newMonthKey = `${newSelectedYear}-${newSelectedMonth}`
        setSelectedMonth(newMonthKey)

        if (new Date(selectedDays[0]).getMonth() !== newSelectedMonth && new Date(selectedDays[6]).getMonth() !== newSelectedMonth) {
            const newDate = new Date(date)
            newDate.setHours(0,0,0,0)
            setSelectedDays(getWeekDays(newDate))
            updateSavedTasks(getWeekDays(newDate))
        }

        const response = await getMonthConfirmation(newMonthKey)
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setMonthConfirmation(response)
    }

    useEffect(() => {
        if (!selectedDays.length) {
            const today = new Date().setHours(0,0,0,0)
            handleOnDayClick(new Date(today))
            handleOnMonthChange(new Date(today))
        }
    }, [handleOnDayClick, selectedDays.length])

    return (
        <div className='calendar'>
            <DayPicker
                selectedDays={selectedDays}
                showWeekNumbers
                showOutsideDays
                modifiers={modifiers}
                onDayClick={handleOnDayClick}
                onDayMouseEnter={(date) => setHoverRange(getWeekRange(date))}
                onDayMouseLeave={() => setHoverRange(undefined)}
                onWeekClick={handleOnWeekClick}
                firstDayOfWeek={1}
                todayButton="This Week"
                onTodayButtonClick={handleOnTodayButtonClick}
                onMonthChange={handleOnMonthChange}
            />
        </div>
    )
}

export default Calendar
