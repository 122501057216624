import React from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import useError from '../../hooks/useError'
import useStatisticsServices from '../../services/statistics'
import { getFilters } from '../../utils/statistics'
import styles from './index.module.css'

const StatisticsResultsHeader = ({ type, text, style }) => {
    const { statisticsState, setSort, setFiltersChanged, setStatisticsResults } = useContextStatistics()
    const { getStatistics } = useStatisticsServices()
    const { setErrorMessage } = useError()
    
    const { sort, resultsPage } = statisticsState

    const sortResults = async (newSort) => {
        setSort(newSort)
        const props = getFilters({ ...statisticsState, sort: newSort })
        const response = await getStatistics(props, resultsPage)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setFiltersChanged(false)
        setStatisticsResults(response.statistics)
    }

    const onClick = async () => {
        if (sort.primary.type === type) {
            return sortResults({
                primary: {
                    type,
                    ascending: !sort.primary.ascending
                },
                secondary: {
                    type: sort.secondary.type,
                    ascending: sort.secondary.ascending
                }
            })
        }
        
        if (sort.primary.type) {
            return sortResults({
                primary: {
                    type,
                    ascending: sort.secondary.type === type 
                        ? !sort.secondary.ascending
                        : true
                },
                secondary: {
                    type: sort.primary.type,
                    ascending: sort.primary.ascending
                }
            })
        }
        
        sortResults({
            primary: {
                type,
                ascending: true
            },
            secondary: {
                type: null,
                ascending: true
            }
        })
    }

    return (
        <div className={`${style} ${styles.container}`} onClick={onClick} >
            <div>{text}</div>
            { sort.primary.type === type &&
                <div>
                    { sort.primary.ascending 
                        ? <div>▲</div>
                        : <div>▼</div>
                    }
                </div>
            }
            {sort.secondary.type === type && 
                <div>
                    { sort.secondary.ascending
                        ? <div>ᐃ</div>
                        : <div>ᐁ</div>
                    }
                </div>
            }
        </div>
    )
}

export default StatisticsResultsHeader
