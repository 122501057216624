import React, { useState } from 'react'
import { useContextTimeCard } from '../../../../contexts/timeCardContext'
import useError from '../../../../hooks/useError'
import useProjectServices from '../../../../services/project'
import { arrangeProjectColumnData } from '../../../../utils/userTasks'
import AttributeInputDropdown from '../../AttributeInputDropdown'
import ConfirmClearTask from '../ConfirmClearTask'
import styles from './index.module.css'

const CopyProject = ({ selectedClient, setCopiedProject, isProjectStateFilled}) => {
    const { tasksState: { clients, projectsColumnData} , setProjectColumnData } = useContextTimeCard()
    const [isShownPopUp, setIsShownPopUp] = useState(false)
    const { setErrorMessage } = useError()
    const { getProject } = useProjectServices()
    const [userInput, setUserInput] = useState({
        client: { 
            isOnFocus: false, 
            currentInputValue: '', 
            currentClient: selectedClient 
                ? clients.find(clientProjects => clientProjects.clientid === Number(selectedClient)) 
                : '' 
        },
        project: { 
            isOnFocus: false, 
            currentInputValue: '',
            selectedProjectId: null 
        },
    })

    const currentClient = userInput.client.currentClient
    const selectedProjectId = userInput.project.selectedProjectId
    const isClientInputBad =
            userInput.client.currentInputValue && 
            !clients.find(c => c.clientname === userInput.client.currentInputValue)
    const isProjectInputBad = 
            userInput.project.currentInputValue &&
            currentClient &&
            !currentClient.projects.find(p => p.projectname === userInput.project.currentInputValue)

    const hideWindow = () => {
        setIsShownPopUp(!isShownPopUp)
    }

    const showConfirmPopup = () => {
        if (isProjectStateFilled) {
            setIsShownPopUp(true)
            return
        }

        setCopyProject()
    }

    const setCopyProject = async() => {
        if (!selectedProjectId) return
        const selectedId = Number(selectedProjectId)

        if (projectsColumnData[selectedId]) {
            setCopiedProject(projectsColumnData[selectedId])
            return
        }

        const response = await getProject(selectedId)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        const newProjectColumnData = arrangeProjectColumnData(response)
        
        setProjectColumnData(newProjectColumnData, selectedId)
        setCopiedProject(newProjectColumnData)
        return
    }

    const setProjectOption = (projectOption) => {
        const currentProject = currentClient && currentClient.projects.find(p => p.projectname === projectOption.projectname)
        if (!currentProject) {
            return setErrorMessage('Project not found')
        }

        setUserInput(ci => ({
            ...ci,
            project: {
                ...ci.project,
                selectedProjectId: currentProject.projectid
            }
        }))
    }
    
    return (
        <div className={styles.container}>
            <label htmlFor='client' className={styles.labels}>Clients:</label>
            <AttributeInputDropdown
                attributeName='client'
                placeholder='Select client'
                currentInputValue={userInput.client.currentInputValue}
                inputOptions={clients}
                isOnFocus={userInput.client.isOnFocus}
                setValue={(value) => 
                    setUserInput(ci => ({ ...ci, client: { ...ci.client, currentInputValue: value }}))}
                setOption={(value) =>
                    setUserInput(ci => ({ ...ci, client: { ...ci.client, currentClient: value }}))}
                setIsOnFocus={(value) => 
                    setUserInput(ci => ({ ...ci, client: { ...ci.client, isOnFocus: value }}))}
                isInputDropdownBad={isClientInputBad}
            />
            <label htmlFor='projects' className={styles.labels}>Projects:</label>
            <AttributeInputDropdown
                    attributeName='project'
                    placeholder='Select project'
                    currentInputValue={userInput.project.currentInputValue}
                    inputOptions={currentClient ? currentClient.projects : []}
                    setValue={(value) => 
                        setUserInput(ci => ({ ...ci, project: { ...ci.project, currentInputValue: value }}))}
                    setOption={setProjectOption}
                    setIsOnFocus={(value) => 
                        setUserInput(ci => ({ ...ci, project: { ...ci.project, isOnFocus: value }}))}
                    isOnFocus={userInput.project.isOnFocus}
                    isDisabled={!currentClient}
                    isInputDropdownBad={isProjectInputBad}
                />
            <button 
                onClick={() => showConfirmPopup()} 
                className={styles.btn} 
                disabled={isClientInputBad || isProjectInputBad}
            >
                Set selected project
            </button>
            {isShownPopUp && 
                    <ConfirmClearTask 
                        hideWindow={hideWindow} 
                        setCopyProject={setCopyProject}  
                    />
            }
        </div>
    )
}

export default CopyProject
