import React from 'react'
import styles from './index.module.css'

const CheckBox = ({ 
    value, 
    name, 
    checked, 
    index, 
    onChange, 
    onMouseEnter, 
    onMouseLeave, 
    isOptional,
    containerStyle = {} 
}) => {
    return (
        <label 
            className={isOptional 
                ? `${styles['container-option']} ${styles.container}` 
                : styles.container} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}
            style={containerStyle}
        >
            <span className={styles['box-name']}>{value}</span>
            <input
                type='checkbox'
                value={value}
                name={name}
                checked={checked}
                onClick={onChange}
                id={index}
                readOnly
            />
            <span className={styles.checkmark}></span>
        </label>
    )
}

export default CheckBox