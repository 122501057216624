import React, { useState } from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import useTimeCardServices from '../../services/timeCard'
import { useContextSession } from '../../contexts/sessionContext'
import { formatDate } from '../../utils/date'
import { XLSX_COLUMNS } from '../../utils/constants'
import useError from '../../hooks/useError'

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

const ExportCSV = ({ fileName, setIsGetReferenceShown, range }) => {
    const { user } = useContextSession()
    const { setErrorMessage } = useError()
    const { getTimeCard } = useTimeCardServices()
    const [isAlertVisible, setIsAlertVisible] = useState(false)

    const exportToCSV = (csvData) => {
        const ws = XLSX.utils.json_to_sheet(csvData, {headers: [], range: 1})
        ws['!cols'] = XLSX_COLUMNS
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    const getCsvData = (data) => {
        const from = formatDate(range.from, '%d %b %Y')
        const to = formatDate(range.to, '%d %b %Y')
        const projects = new Set()
        data.forEach(r => projects.add(r.projectname))

        const rows = Array.from(projects).map(p => {
            const tasks = data.filter(t => t.projectname === p)
            const columns = new Set()
            const columnsData = []

            for (let i = 0; i < tasks.length; i++) {
                if (!columnsData[i]) {
                    columnsData[i] = []
                }

                for (const key in tasks[i]) {
                    if (tasks[i][key] && key.includes('name') && !key.includes('client') && !key.includes('project')) {
                        columns.add(key.slice(0, -4))
                        columnsData[i].push(tasks[i][key])
                    }
                    
                    if (key === 'date') {
                        columnsData[i].unshift(tasks[i][key])
                    }
                }
                
                columnsData[i].push(tasks[i].hours)
            }

            columnsData.forEach(arr => arr[0] = formatDate(new Date(arr[0]), 'w%w-%d %b %Y'))
            return [
                ['Project:', p],
                ['Week-Date', ...columns, 'hours'],
                ...columnsData
            ]
        })

        const csvData = [
            ['User:', user.name],
            ['Reference', `from ${from}`, `to ${to}`],
            ...rows.flat(1)
        ]

        return csvData
    }

    const handleExport = async () => {
        if (!range.to) {
            setIsAlertVisible(true)

            setTimeout(() => {
                setIsAlertVisible(false)
            }, 5000)
            return
        }

        const response = await getTimeCard([range.from, range.to])

        if (response.error) {
            setErrorMessage(response)
            return
        }
        
        const csvData = getCsvData(response.timeCard)
        exportToCSV(csvData)
        setIsGetReferenceShown(false)
    }

    return (
        <>
            <button onClick={handleExport}>Export</button>
            <button onClick={() => setIsGetReferenceShown(false)}>Cancel</button>
            {isAlertVisible && <div>
                    Please choose a period for the reference.
                </div>
            }
        </>
    )
}

export default ExportCSV
