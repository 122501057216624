import React from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import useError from '../../hooks/useError'
import useStatisticsServices from '../../services/statistics'
import { ROWS_ON_PAGE } from '../../utils/constants'
import { getFilters } from '../../utils/statistics'
import styles from './index.module.css'

const Pagination = () => {
    const { statisticsState, setStatisticsResults, setResultsPage } = useContextStatistics()
    const { getStatistics } = useStatisticsServices()
    const { setErrorMessage } = useError()

    const { resultsTotalCount, resultsPage } = statisticsState
    const isLastPage = resultsPage * ROWS_ON_PAGE >= resultsTotalCount

    const scrollTableIntoView = () => {
        const element = document.getElementById('statistics-results');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    const handleNextPage = async () => {
        const filters = getFilters(statisticsState)
        const response = await getStatistics(filters, resultsPage + 1, ROWS_ON_PAGE, false)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setStatisticsResults(response.statistics)
        setResultsPage(resultsPage + 1)
        scrollTableIntoView()
    }

    const handlePreviousPage = async () => {
        const filters = getFilters(statisticsState)
        const response = await getStatistics(filters, resultsPage - 1, ROWS_ON_PAGE, false)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setStatisticsResults(response.statistics)
        setResultsPage(resultsPage - 1)
        scrollTableIntoView()
    }

    const handleLastPage = async () => {
        const lastPageNum = Math.ceil(resultsTotalCount / ROWS_ON_PAGE)
        const filters = getFilters({ ...statisticsState, resultsPage: lastPageNum })
        const response = await getStatistics(filters, lastPageNum, ROWS_ON_PAGE, false)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setStatisticsResults(response.statistics)
        setResultsPage(lastPageNum)
        scrollTableIntoView()
    }

    const handleFirstPage = async () => {
        const filters = getFilters(statisticsState)
        const response = await getStatistics(filters, 1, ROWS_ON_PAGE, false)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setStatisticsResults(response.statistics)
        setResultsPage(1)
        scrollTableIntoView()
    }

    return (
        <div className={styles.container}>
            <button disabled={resultsPage === 1} onClick={handleFirstPage}>{`<<`}</button>
            <button disabled={resultsPage === 1} onClick={handlePreviousPage}>{`<`}</button>
            <div className={styles.text}>
                {isLastPage ? (
                    <>
                        {`${resultsPage * ROWS_ON_PAGE - ROWS_ON_PAGE + 1}-${resultsTotalCount}`} from{' '}
                        {resultsTotalCount}
                    </>
                ) : (
                    <>
                        {`${resultsPage * ROWS_ON_PAGE - ROWS_ON_PAGE + 1}-${resultsPage * ROWS_ON_PAGE}`}{' '}
                        from {resultsTotalCount}
                    </>
                )}
            </div>
            <button disabled={isLastPage} onClick={handleNextPage}>{`>`}</button>
            <button disabled={isLastPage} onClick={handleLastPage}>{`>>`}</button>
        </div>
    )
}

export default Pagination
