import React, { useEffect, useMemo, useRef, useState } from 'react'
import HoursInput from '../../Inputs/HoursInput'
import useOvertimeServices from '../../../services/overtime'
import useMonthConfirmationServices from '../../../services/monthConfirmation'
import styles from './index.module.css'
import cancel from '../../../public/cancel.svg'
import pen from '../../../public/pen.svg'
import hand from '../../../public/hand.svg'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import { getWeekNumber, getWeekYear, MONTHS } from '../../../utils/date'
import useError from '../../../hooks/useError'

const Overtime = ({ overtimeObj }) => {
    const [isOvertimeDisabled, setIsOvertimeDisabled] = useState(true)
    const [currOvertime, setCurrOvertime] = useState({})
    const { 
        tasksState: { selectedDays, overtime, savedTimeCard, monthConfirmation, selectedMonth },
        setOvertime,
        setMonthConfirmation
    } = useContextTimeCard()
    const { saveOvertime, deleteOvertime, removeOvertimeConfirmation } = useOvertimeServices()
    const { confirmMonth, removeMonthConfirmation } = useMonthConfirmationServices()
    const { setErrorMessage } = useError()
    const firstUpdate = useRef(true)
    const isOvertimeShown = useMemo(() => {
        return ((savedTimeCard && !!Object.keys(savedTimeCard).length) || overtime.ischanged)
    }, [overtime.ischanged, savedTimeCard])

    const weekKey = useMemo(() => {
        if (!selectedDays || !selectedDays.length) return

        const week = getWeekNumber(selectedDays[1])
        const year = getWeekYear(selectedDays[1])
        return year + '-' + week
    }, [selectedDays])

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }

        if (overtime.ischanged) return

        const totalOvertime = Object.values(overtimeObj).reduce((acc, hours) => acc + hours, 0)

        setOvertime({ ...overtime, week: weekKey, hours: totalOvertime })
    }, [overtimeObj, setOvertime, weekKey])

    const handleEditOvertime = () => {
        setIsOvertimeDisabled(false)
        setCurrOvertime({ ...overtime })
    }

    const handleRemoveEditedOvertime = async () => {
        const totalOvertime = Object.values(overtimeObj).reduce((acc, hours) => acc + hours, 0)
        setOvertime({ week: weekKey, hours: totalOvertime })

        const response = await deleteOvertime(weekKey)

        if (response.error) {
            setErrorMessage(response)
            return
        }
    }

    const handleConfirmChanges = async () => {
        setIsOvertimeDisabled(true)
        
        if (overtime.hours === currOvertime.hours) return
        
        const response = await saveOvertime(overtime)
        if (response.error) {
            setErrorMessage(response)
            setOvertime({ ...currOvertime })
            return
        }

        setOvertime({ ...overtime, overtimeid: response.add_or_update_overtime })
    }

    const handleCancel = () => {
        setOvertime({ ...currOvertime })
        setIsOvertimeDisabled(true)
    }
    const handleOnChange = (e) => {
        setOvertime({ ...overtime, hours: e.target.value, ischanged: true })
    }

    const handleConfirm = async () => {
        const newOvertime = { ...overtime, isconfirmed: true }

        const response = await saveOvertime(newOvertime)
        if (response.error) {
            setErrorMessage(response)
            return
        }

        setOvertime({ ...newOvertime, overtimeid: response.add_or_update_overtime })
    }

    const handleRemoveWeekConfirmation = async () => {
        const response = await removeOvertimeConfirmation(overtime.overtimeid, overtime.ischanged)
        if (response.error) {
          setErrorMessage(response)
          return
        }

        setOvertime({ ...overtime, isconfirmed: false })
    }

    const handleRemoveMonthConfirmation = async () => {
        const response = await removeMonthConfirmation(monthConfirmation.current.confirmationid)
        if (response.error) {
          setErrorMessage(response)
          return
        }

        setMonthConfirmation(response)
    }

    const handleConfirmMonth = async () => {
        const response = await confirmMonth(selectedMonth)
        if (response.error) {
          setErrorMessage(response)
          return
        }

        setMonthConfirmation(response)
    }

    useEffect(() => {
        setIsOvertimeDisabled(true)
    }, [selectedDays])

    return (
        <div className={styles.container}>
            { isOvertimeShown && (
                <>
                    {overtime.ischanged && (
                        <img src={hand} alt='' height='21' title='Overtime was manually changed' />
                    )}
                    <div className={styles.text}>Overtime:</div>
                    <HoursInput
                        value={overtime.hours}
                        onChange={handleOnChange}
                        disabled={isOvertimeDisabled}
                        minHours={0}
                        maxHours={Number.MAX_VALUE}
                    />
                    {isOvertimeDisabled ? (
                        <div className={styles['buttons-container']}>
                            <button
                                className={`${styles['edit-button']} ${
                                    isOvertimeDisabled && !overtime.ischanged && styles.placeholder
                                }`}
                                onClick={handleEditOvertime}
                                disabled={overtime.isconfirmed || overtime.islocked}
                            >
                                <img src={pen} alt='' height='16' />
                            </button>
                            {overtime.ischanged && (
                                <button
                                    className={styles['remove-button']}
                                    onClick={handleRemoveEditedOvertime}
                                    title='Undo manual changes'
                                    disabled={overtime.isconfirmed || overtime.islocked}
                                >
                                    X
                                </button>
                            )}
                        </div>
                    ) : (
                        <div className={styles['buttons-container']}>
                            <button className={styles['edit-button']} onClick={handleConfirmChanges}>
                                ✓
                            </button>
                            <button className={styles['cancel-button']} onClick={handleCancel}>
                                <img src={cancel} alt='' width='25' height='16' />
                            </button>
                        </div>
                    )}
                </>
            )}
            <div className={styles.confirm}>
                {overtime.isconfirmed && !overtime.islocked && !monthConfirmation?.current?.islocked &&
                    <button
                        className={styles['remove-confirmation']}
                        onClick={handleRemoveWeekConfirmation}
                        title='Remove week confirmation'
                    >
                        X
                    </button>
                }
                <button
                    onClick={handleConfirm}
                    disabled={overtime.isconfirmed || !isOvertimeDisabled || overtime.islocked || monthConfirmation?.current?.islocked}
                >
                    { overtime.islocked
                        ? `Week ${weekKey.slice(5)} is locked`
                        : 'Confirm Week'
                    }
                </button>
            </div>
            <div className={styles.confirm}>
                {monthConfirmation?.current?.isconfirmed && !monthConfirmation?.current?.islocked &&
                    <button
                        className={styles['remove-confirmation']}
                        onClick={handleRemoveMonthConfirmation}
                        title='Remove month confirmation'
                    >
                        X
                    </button>
                }
                <button
                    onClick={handleConfirmMonth}
                    disabled={monthConfirmation?.current?.isconfirmed || !isOvertimeDisabled || monthConfirmation?.current?.islocked}
                >
                    { monthConfirmation?.current?.islocked
                        ? `${MONTHS.en[Number(selectedMonth.slice(5))]} is locked`
                        : 'Confirm Month'
                    }
                </button>
            </div>
        </div>
    )
}

export default Overtime
