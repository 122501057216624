import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useContextSession } from '../contexts/sessionContext'
import { JSON_CONTENT_TYPE, HTTP_HEADERS, REST_API_URL, AUTH_COOKIE_NAME, GUEST_USER } from '../utils/constants'
import { checkCookieExpiration } from '../utils/cookie'
import { utcToLocal } from '../utils/date'
import useSessionServices from './session'

const USER_URL = REST_API_URL + '/user'
const SESSION_URL = REST_API_URL + '/session'

// needed when user is making several requests at the same time
// makes sure all the requests a single set of cookies
let verificationPromiseCb = null

const useUserServices = () => {
    const { setUser } = useContextSession()
    const { verifySession } = useSessionServices()
    const history = useHistory()

    const handleUserVerification = useCallback(async (cookieName) => {
        const isCookieExpired = checkCookieExpiration(cookieName)
    
        if (isCookieExpired) {
            const responseBody = await verifySession()
            if (!responseBody.isVerified) {
                document.cookie = `${AUTH_COOKIE_NAME}= ; expires = Thu, 01 Jan 1970 00:00:01 GMT;path=/`
                history.push('/')
                setUser(GUEST_USER)
    
                return { 
                    isVerified: false,
                    ...(responseBody.error && { error: responseBody.error }), 
                }
            }
        }
    
        return { isVerified: true }
    }, [history, setUser, verifySession])
    
    const verifyUserSession = useCallback(async (cookieName) => {
        if (!verificationPromiseCb) {
            const verificationPromise = async () => {
                const userSession = await handleUserVerification(cookieName)
    
                verificationPromiseCb = null
                return userSession
            }
    
            verificationPromiseCb = verificationPromise()
        }
    
        return await verificationPromiseCb
    }, [handleUserVerification])

    const verifyUser = useCallback(async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return { ...userSession, isVerificationError: true }
        }
    
        const response = await fetch(`${USER_URL}/verify`, {
            method: 'GET',
        })
    
        return await response.json()
    }, [verifyUserSession])

    const getCurrentUsers = useCallback(async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/all-users`, {
            method: 'GET',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
        })
    
        return await response.json()
    }, [verifyUserSession])

    const getUsersWithOvertime = useCallback(async (weekKeys) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/with-overtime/${weekKeys}`, {
            method: 'GET',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const createUser = async (data) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/register`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify(data),
        })
    
        return await response.json()
    }
    
    const userLogin = async (data) => {
        const response = await fetch(`${USER_URL}/login`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify(data),
        })
    
        const responseBody = await response.json()
        if (!response.ok) {
            return responseBody
        }
    
        const { holidays } = responseBody
        responseBody.holidays = holidays.map((h) => ({ ...h, holiday: utcToLocal(h.holiday) }))
    
        return responseBody
    }
    
    const logoutUser = async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${SESSION_URL}/logout`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
        })
    
        if (response.status === 200) {
            document.cookie = `${AUTH_COOKIE_NAME}= ; expires = Thu, 01 Jan 1970 00:00:01 GMT;path=/`
        }
    
        return await response.json()
    }
    
    const getOldUsers = async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/old-users`, {
            method: 'GET',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
        })
    
        return await response.json()
    }
    
    const editUser = async (user) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/edit`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify(user),
        })
    
        return await response.json()
    }
    
    const sendUserToOld = async (userid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/archive`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify({ userid }),
        })
    
        return await response.json()
    }
    
    const sendUserToCurrent = async (userid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/activate`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify({ userid }),
        })
    
        return await response.json()
    }
    
    const changeWeeklyReminderStatus = async (userid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/reminder-status`, {
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE,
            },
            body: JSON.stringify({ userid }),
        })
    
        return await response.json()
    }

    const getInitialData = useCallback(async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${USER_URL}/initial-data`)
    
        return await response.json()
    }, [verifyUserSession])

    return {
        verifyUserSession,
        createUser,
        userLogin,
        verifyUser,
        logoutUser,
        getCurrentUsers,
        getUsersWithOvertime,
        getOldUsers,
        editUser,
        sendUserToOld,
        sendUserToCurrent,
        changeWeeklyReminderStatus,
        getInitialData,
    }
}

export default useUserServices