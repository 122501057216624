import React from 'react'
import useError from '../../hooks/useError'
import useUserServices from '../../services/user'
import { parseCronString } from '../../utils/reminder'
import InputUser from '../Inputs/InputUser'
import styles from './index.module.css'

const OldUser = ({ user, setCurrentUsers, setOldUsers }) => {
    const { setErrorMessage } = useError()
    const { sendUserToCurrent } = useUserServices()

    const prepareCurrentUser = (userResponse) => {
        const { weekday, hour } = parseCronString(userResponse.reminder_cron)

        const newCurrentUser = { ...userResponse }
        newCurrentUser.reminderIsActivated = newCurrentUser.reminder_isactivated
        newCurrentUser.reminderWeekday = weekday
        newCurrentUser.reminderHour = hour

        delete newCurrentUser.reminder_cron
        delete newCurrentUser.reminder_isactivated

        return newCurrentUser
    }

    const handleSendUserToCurrent = async () => {
        const response = await sendUserToCurrent(user.userid)
        
        if (response.error) {
            setErrorMessage(response)
            return
        }

        const newCurrentUser = prepareCurrentUser(response)
        setCurrentUsers(currUsers => {
            return [...currUsers, newCurrentUser]
        })
        
        setOldUsers(oldUsers => {
            return oldUsers.filter(u => u.userid !== user.userid)
        })
    }

    return (
        <div className={styles.container} >
            <InputUser
                value={user.name}
                type='name'
                isDisabled={true}
                customClass={styles['big-input']}
            />
            <InputUser
                value={user.username}
                type='username'
                isDisabled={true}
                customClass={`${styles['small-input']} ${styles.responsive}`}
            />
            <InputUser
                value={user.email}
                type='email'
                isDisabled={true}
                customClass={`${styles['big-input']} ${styles.responsive}`}
            />
            <button
                className={styles['return-button']}
                onClick={handleSendUserToCurrent}
                title='Return to current users'
            >
                ↶
            </button>
        </div>
    )
}

export default OldUser
