import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.css'

const NavigationLink = ({ path, name }) => {
    return (
        <div className={styles['list-item']}>
            <Link to={path} className={styles.link}>
                {name}
            </Link>
        </div>
    )
}

export default NavigationLink