import React from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import { formatDate } from '../../utils/date'
import styles from './index.module.css'

const StatisticsCalendarSelection = () => {
    const { statisticsState, removeCalendarRange } = useContextStatistics()

    return (
        <div className={styles.container}>
            {statisticsState.selectedRange.map((range, i) =>
                <div key={i} className={`${range.from && range.hoverTo && styles.calendar}`}>
                    {range.from && range.hoverTo &&
                        <>
                            <div>
                                {`From ${formatDate(new Date(range.from), '%d %b %Y')} to 
                                ${formatDate(new Date(range.hoverTo), '%d %b %Y')}`}
                            </div>
                            <span onClick={() => removeCalendarRange(i)} >X</span>
                        </>
                    }
                </div>)
            }
        </div>
    )
}

export default StatisticsCalendarSelection
