import React from 'react'
import styles from './index.module.css'
import HoursInput from '../Inputs/HoursInput'
import InputUser from '../Inputs/InputUser'
import UserReminder from '../UserReminder'
import hand from '../../public/hand.svg'


const UserMobileEditMenu = ({ user, handleCancel, handleConfirm, handleChange, setUser, handleSendUserToOld }) => {
    return (
        <div>
            <div className={styles.transparent} ></div>
            <div className={styles.container}>
                <div>Name:</div>
                <InputUser
                    value={user.name}
                    type='name'
                    handleChange={handleChange}
                />
                <div>Username:</div>
                <InputUser
                    value={user.username}
                    type='username'
                    handleChange={handleChange}
                />
                <div>Email:</div>
                <InputUser
                    value={user.email}
                    type='email'
                    handleChange={handleChange}
                />
                <div className={styles['inner-container']}>
                    <div>Role:</div>
                    <div>Overtime:</div>
                </div>
                <div className={styles['inner-container']}>
                    <select
                        className={styles.select}
                        value={user.role}
                        onChange={(e) => handleChange(e, 'role')}
                    >
                        <option>user</option>
                        <option>statistics</option>
                        <option>admin</option>
                    </select>
                    <HoursInput
                        value={user.hours || ''}
                        onChange={(e) => handleChange(e, 'hours')}
                        disabled={!user.overtimeid}
                        inputStyle={styles.hours}
                    />
                    {user.ischanged &&
                        <img
                            src={hand}
                            alt=''
                            height='21'
                            title='Overtime was manually changed'
                        />
                    }
                </div>
                <div>Reminder:</div>
                <div>
                    <UserReminder
                        reminderWeekday={user.reminderWeekday}
                        reminderHour={user.reminderHour}
                        reminderIsActivated={user.reminderIsActivated}
                        setUser={setUser}
                        customStyles={styles}
                        userId={user.userid}
                        />
                </div>
                <div>Password:</div>
                <InputUser
                    value={user.password || ''}
                    type='password'
                    handleChange={handleChange}
                    placeholder='Change Password'
                    customClass={styles['big-input']}
                />
                <div className={styles.buttons}>
                    <button onClick={handleSendUserToOld}>
                        Send to old users
                    </button>
                    <button onClick={handleConfirm}>
                        Confirm
                    </button>
                    <button onClick={handleCancel}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UserMobileEditMenu
