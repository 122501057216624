import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import styles from './index.module.css'
import ReactExport from 'react-data-export'
import { arrangeExportCellData, arrangeExportMonthCellData } from '../../../utils/statistics'
import {
    boldStyle,
    dateHeaderStyleBottom,
    dateHeaderStyleTop,
    descriptionHeaderStyle,
    hoursHeaderStyle,
    totalHoursStyle,
    totalHoursTextStyle,
    userHeaderHoursStyle,
    userHeaderStyle,
    userTotalHoursDateStyle,
    userTotalHoursStyle,
    userTotalHoursTextStyle
} from '../../../utils/excelStyles'
import { formatDate, getWeekNumber, getWeekYear } from '../../../utils/date'

const WEEK_MS = 518400000

const { ExcelFile } = ReactExport
const { ExcelSheet } = ExcelFile

const ExportReport = ({ data, setData, selectedRange, reportGrouping }) => {
    const groupByDataColumnName = `${reportGrouping.groupBy === 'User' ? '' : reportGrouping.groupBy.toLowerCase()}name`
    const thenByDataColumnName = reportGrouping.thenBy ? 
        `${reportGrouping.thenBy === 'User' ? '' : reportGrouping.thenBy.toLowerCase()}name`: 
        ''

    const ref = useRef(null)
    const [multiColumnData, setMultiColumnData] = useState([])
    const [multiColumnMonthData, setMultiColumnMonthData] = useState([])

    const [isWeekSelected, isMonthSelected] = useMemo(() => {
        const from = selectedRange[0].from
        const to = selectedRange[0].to
        
        const dayAfterSelectedRange = new Date(to)
        dayAfterSelectedRange.setDate(to.getDate() + 1)

        const isWeekSelected = from.getDay() === 1
            && (Date.parse(to) - Date.parse(from)) === WEEK_MS
        const isMonthSelected = from.getDate() === 1
            && from.getMonth() === to.getMonth()
            && dayAfterSelectedRange.getDate() === 1

        return [isWeekSelected, isMonthSelected]
    }, [selectedRange])

    const filename = useMemo(() => {
        const date = formatDate(new Date(data[0].date), '%b-%Y')
        const projectFilenamePart = reportGrouping.projectFallback ? `${data[0].projectname}_` : ''
        const groupByFilenamePart = reportGrouping.groupBy === 'Project' && reportGrouping.projectFallback 
            ? '' 
            : data[0][groupByDataColumnName]
        const thenByFilenamePart = reportGrouping.thenBy === 'Project' && reportGrouping.projectFallback 
            ? '' 
            : data[0][thenByDataColumnName] ? `${groupByFilenamePart ? '_' : ''}${data[0][thenByDataColumnName]}`: ''
        const groupsFilenamePart = `${projectFilenamePart}${groupByFilenamePart}${thenByFilenamePart}`

        if (isWeekSelected) {
            const weekNum = getWeekNumber(new Date(data[0].date))
            return `W-${weekNum}-${date}_${groupsFilenamePart}_SD2006_Timesheet`
        }

        if (isMonthSelected) {
            return `${date}_${groupsFilenamePart}_SD2006_Timesheet`
        }

        const dateStart = formatDate(selectedRange[0].from, '%Y-%m-%d')
        const dateEnd = formatDate(selectedRange[0].to, '%Y-%m-%d')
        return `From-${dateStart}-to-${dateEnd}_${groupsFilenamePart}_SD2006_Timesheet`
    }, [data, isMonthSelected, isWeekSelected, selectedRange])

    const parseData = useCallback(() => {
        const usernames = new Set()
        data.forEach(row => usernames.add(row.name))
        const sortedUsers = Array.from(usernames).sort((a, b) => a.localeCompare(b))

        let userColumns = []
        sortedUsers.forEach(name => {
            userColumns = [
                ...userColumns,
                { value: name, style: userHeaderStyle },
                { value: '', style: userHeaderHoursStyle }
            ]
        })

        let columnNames = []
        sortedUsers.forEach(_ => {
            columnNames = [
                ...columnNames,
                { title: 'Description', width: { wpx: 270 }, style: descriptionHeaderStyle },
                { title: 'Hours', width: { wpx: 45 }, style: hoursHeaderStyle }
            ]
        })

        const cellData = arrangeExportCellData(data, sortedUsers)

        const userTotalHours = sortedUsers.map(u => {
            const hours = data.reduce((sum, row) => {
                if (row.name === u) {
                    return sum + row.hours
                }

                return sum
            }, 0)

            return [
                { title: `${u} - Total Hours `, width: { wpx: 270 }, style: userTotalHoursTextStyle },
                { title: `${hours}`, width: { wpx: 45 }, style: userTotalHoursStyle }
            ]
        }).flat()

        const from = selectedRange[0].from
        const to = selectedRange[0].to

        const range = isWeekSelected
            ? `Week: ${getWeekNumber(new Date(data[0].date))}`
            : isMonthSelected
                ? `Month: ${formatDate(from, '%B')}`
                : `From ${formatDate(from, '%d %b %Y')} to ${formatDate(to, '%d %b %Y')}`

        const totalHoursString = isWeekSelected
            ? `SMART DESIGN 2006 Ltd. - Total Hours for week ${getWeekNumber(new Date(data[0].date))}, ${getWeekYear(new Date(data[0].date))} `
            : isMonthSelected
                ? `SMART DESIGN 2006 Ltd. - Total Hours for ${formatDate(from, '%B')}, ${formatDate(from, '%Y')} `
                : `SMART DESIGN 2006 Ltd. - Total Hours from ${formatDate(from, '%d %b %Y')} to ${formatDate(to, '%d %b %Y')} `

        const totalHours = sortedUsers.map((_, i) => {
            if (i === sortedUsers.length - 1) {
                return [
                    {
                        value: totalHoursString,
                        style: totalHoursTextStyle
                    },
                    { value: data.reduce((sum, row) => sum + row.hours, 0), style: totalHoursStyle }
                ]
            }

            return [
                { value: '' },
                { value: '' }
            ]
        }).flat()

        const multiColumnData  = []
        if (reportGrouping.projectFallback) {
            multiColumnData.push([{ value: `Project: ${data[0].projectname}`, style: boldStyle }])
            multiColumnData.push([{ value: `Client: ${data[0].clientname}`, style: boldStyle }])
        }

        if (reportGrouping.groupBy !== 'Project' || !reportGrouping.projectFallback) {
            multiColumnData.push([{ value: `${reportGrouping.groupBy.replace('User', 'Work performed by')}: ${data[0][groupByDataColumnName]}`, style: boldStyle }])
        }

        if (data[0][thenByDataColumnName] && (reportGrouping.thenBy !== 'Project' || !reportGrouping.projectFallback)) {
            multiColumnData.push([{ value: `${reportGrouping.thenBy.replace('User', 'Work performed by')}: ${data[0][thenByDataColumnName]}`, style: boldStyle }])
        }

        multiColumnData.push([{ value: range, style: boldStyle }])
        
        setMultiColumnData([
            {
                columns: [{ title: 'SMART DESIGN 2006 Ltd.' }],
                data: multiColumnData
            },
            {
                columns: [],
                data: [[
                    { value: 'Date', style: dateHeaderStyleTop },
                    ...userColumns
                ]]
            },
            {
                columns: [
                    { title: '', width: { wpx: 120 }, style: dateHeaderStyleBottom },
                    ...columnNames
                ],
                data: cellData
            },
            {
                columns: [
                    { title: '', width: { wpx: 120 }, style: userTotalHoursDateStyle },
                    ...userTotalHours
                ],
                data: []
            },
            {
                columns: [],
                data: [[
                    { value: '' },
                    ...totalHours
                ]]
            }
        ])
        
        if (isMonthSelected) {
            const monthCellData = arrangeExportMonthCellData(data, sortedUsers)
            
            setMultiColumnMonthData([
                {
                    columns: [{ title: 'SMART DESIGN 2006 Ltd.' }],
                    data: multiColumnData
                },
                {
                    columns: [],
                    data: [[
                        { value: 'Date', style: dateHeaderStyleTop },
                        ...userColumns
                    ]]
                },
                {
                    columns: [
                        { title: '', width: { wpx: 120 }, style: dateHeaderStyleBottom },
                        ...columnNames
                    ],
                    data: monthCellData
                },
                {
                    columns: [
                        { title: '', width: { wpx: 120 }, style: userTotalHoursDateStyle },
                        ...userTotalHours
                    ],
                    data: []
                },
                {
                    columns: [],
                    data: [[
                        { value: '' },
                        ...totalHours
                    ]]
                }
            ])
        }
    }, [data, isMonthSelected, isWeekSelected, selectedRange])

    useEffect(() => {
        if (!data) return

        if (!multiColumnData.length) {
            parseData()
            return
        }

        if (!ref.current) return

        ref.current.click()
        setData(null)
        setMultiColumnData([])
    }, [data, multiColumnData.length, parseData, setData])

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {multiColumnData && multiColumnData.length && multiColumnMonthData.length
                ? <ExcelFile
                    filename={filename}
                    element={<button className={styles['btn-download']} ref={ref}></button>}
                >
                    <ExcelSheet dataSet={multiColumnMonthData} name={`${formatDate(new Date(data[0].date), '%B')}`} />
                    <ExcelSheet dataSet={multiColumnData} name='Timesheet' />
                </ExcelFile>
                : <ExcelFile
                    filename={filename}
                    element={<button className={styles['btn-download']} ref={ref}></button>}
                >
                    <ExcelSheet dataSet={multiColumnData} name='Timesheet' />
                </ExcelFile>
            }
        </div>
    )
}

export default ExportReport
