import React, { useEffect, useState } from 'react'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import styles from './index.module.css'

const HoursInput = ({
    id,
    value,
    onChange,
    placeholder,
    autoFocus = false,
    inputStyle,
    disabled = false,
    timecardid,
    minHours = 0.5,
    maxHours = 24,
    title
}) => {
    const { tasksState, setUpdatedTimeCardIds } = useContextTimeCard()
    const { updatedTimeCardIds } = tasksState
    const [isUpdated, setIsUpdated] = useState(false)

    useEffect(() => {
        if (!timecardid) return
        
        setIsUpdated(false)

        if (updatedTimeCardIds.find(tc => tc.timecardid === timecardid)) {
            setIsUpdated(true)

            setTimeout(() => {
                setUpdatedTimeCardIds([])
                setIsUpdated(false)
            }, 5000)
        }
    }, [updatedTimeCardIds, setUpdatedTimeCardIds, timecardid])

    return (
        <input
            id={id}
            min={minHours}
            max={maxHours}
            type='number'
            step={0.5}
            className={`${isUpdated && styles.updated} ${styles.input} ${inputStyle}`}
            value={value || ''}
            onChange={onChange}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            title={title}
            onFocus={(e) => e.target.addEventListener("wheel", (e) => e.preventDefault())}
        />
    )
}

export default HoursInput
