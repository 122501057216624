import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useContextError } from '../contexts/errorContext'
import { useContextSession } from '../contexts/sessionContext'
import { GUEST_USER } from '../utils/constants'

const useError = () => {
    const history = useHistory()
    const { addMessage } = useContextError()
    const { setUser } = useContextSession()

    const setErrorMessage = useCallback((error) => {

        addMessage(error.error)

        if (error.type === 'authentication') {
            // user is not logged in, cannot access pages other than login page
            setUser(GUEST_USER)
            history.push('/')
        }

        return
    },[addMessage, history, setUser])


    return { setErrorMessage }
}

export default useError