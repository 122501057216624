import React, { useState } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { useContextTimeCard } from '../../contexts/timeCardContext'
import useError from '../../hooks/useError'
import useHolidayServices from '../../services/holiday'
import { formatDate } from '../../utils/date'
import styles from './index.module.css'

const AddHoliday = () => {
    const [selectedDays, setSelectedDays] = useState([])
    const { tasksState, setHolidays } = useContextTimeCard()
    const { setErrorMessage } = useError()
    const { saveHolidays } = useHolidayServices()

    const { holidays, workingDays } = tasksState.holidays

    const modifiers = {
        weekends: {
            daysOfWeek: [0, 6]
        },
        holidays,
        workingDays
    }

    const handleDayClick = (day, { selected }) => {
        const newSelectedDays = [...selectedDays]
        if (selected) {
            const selectedIndex = newSelectedDays.findIndex(selectedDay => DateUtils.isSameDay(selectedDay, day))
            newSelectedDays.splice(selectedIndex, 1)
        } else {
            newSelectedDays.push(day)
        }

        setSelectedDays(newSelectedDays)
    }

    const handleSaveHolidays = async () => {
        const filteredHolidays = selectedDays.filter(checkIsHoliday)

        if (!filteredHolidays.length) return

        const response = await saveHolidays(filteredHolidays, true)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setHolidays(response)
        const remainingSelectedDays = selectedDays.filter(d => !checkIsHoliday(d))
        setSelectedDays(remainingSelectedDays)
    }

    const handleSaveWorkingDays = async () => {
        const filteredHolidays = selectedDays.filter(d => !checkIsHoliday(d))

        if (!filteredHolidays.length) return

        const reponse = await saveHolidays(filteredHolidays, false)
        
        if (reponse.error) {
            setErrorMessage(reponse)
            return
        }
        
        setHolidays(reponse)
        const remainingSelectedDays = selectedDays.filter(checkIsHoliday)
        setSelectedDays(remainingSelectedDays)
    }

    const checkIsHoliday = (day) => {
        let isNewHoliday = !holidays.find(h => h.toString() === day.toString())

        if (isNewHoliday) {
            const weekDay = new Date(day).getDay()
            if ((weekDay === 0 || weekDay === 6) && !workingDays.find(wd => wd.toString() === day.toString())) {
                isNewHoliday = false
            }
        }

        return isNewHoliday
    }

    return (
        <div className={styles['holiday-picker']} >
            <div className={styles.text}>Edit Holidays</div>
            <div>
                <DayPicker
                    modifiers={modifiers}
                    selectedDays={selectedDays}
                    onDayClick={handleDayClick}
                    firstDayOfWeek={1}
                    showWeekNumbers
                    showOutsideDays
                    todayButton="This Month"
                />
            </div>
            <div className={styles.holidays}>
                <div>
                    {selectedDays.filter(checkIsHoliday).map((sd, i) => (
                        <div key={i} >
                            {formatDate(new Date(sd), '%a, %d %b, %Y')}
                        </div>
                    ))}
                </div>
                <button className={styles.button} onClick={handleSaveHolidays} >Set as holidays</button>
            </div>
            <div className={styles['working-days']}>
                <div>
                    {selectedDays.filter(d => !checkIsHoliday(d)).map((sd, i) => (
                        <div key={i} >
                            {formatDate(new Date(sd), '%a, %d %b, %Y')}
                        </div>
                    ))}
                </div>
                <button className={styles.button} onClick={handleSaveWorkingDays} >Set as working days</button>
            </div>
            <button className={styles.button} onClick={() => setSelectedDays([])} >Clear</button>
        </div>
    )
}

export default AddHoliday
