import React from 'react'
import styles from './index.module.css'

const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

const Weekdays = () => {
    return (
        <div>
            <div className={styles.container}>
                {days.map(d => (
                    <div key={d} className={styles.day}>
                        {d}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Weekdays
