import { useRef } from 'react'
import { SERVER_ERROR_MESSAGE_DURATION_MS } from '../../utils/constants'
import styles from './index.module.css'

const ErrorMessage = ({ message, handleClose }) => {
    const timeoutRef = useRef(window.setTimeout(handleClose, SERVER_ERROR_MESSAGE_DURATION_MS))

    const handelWindowClose = () => {
        window.clearTimeout(timeoutRef.current)
        handleClose()
    }

    return (
        <div className={styles.container}>
            <div className={styles['btn-close']} onClick={handelWindowClose}>
                x
            </div>
            {message}
        </div>
    )
}

export default ErrorMessage
