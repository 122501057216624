import React from 'react'
import { useContextStatistics } from '../../contexts/statisticsContext'
import StatisticsSelectionByType from '../StatisticsSelectionByType'

const StatisticsSelection = () => {
    const { statisticsState } = useContextStatistics()
    const {
        selectedClients,
        selectedProjects,
        selectedJobs,
        selectedZones,
        selectedSystems,
        selectedLeads,
        selectedActivities,
        selectedRevisions,
        selectedUsers
    } = statisticsState

    return (
        <div>
            <StatisticsSelectionByType
                type='client'
                selection={selectedClients}
            />
            <StatisticsSelectionByType
                type='project'
                selection={selectedProjects}
            />
            <StatisticsSelectionByType
                type='job'
                selection={selectedJobs}
            />
            <StatisticsSelectionByType
                type='zone'
                selection={selectedZones}
            />
            <StatisticsSelectionByType
                type='system'
                selection={selectedSystems}
            />
            <StatisticsSelectionByType
                type='lead'
                selection={selectedLeads}
            />
            <StatisticsSelectionByType
                type='activity'
                selection={selectedActivities}
            />
            <StatisticsSelectionByType
                type='revision'
                selection={selectedRevisions}
            />
            <StatisticsSelectionByType
                type='user'
                selection={selectedUsers}
            />
        </div>
    )
}

export default StatisticsSelection
