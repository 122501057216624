import React from 'react'
import styles from './index.module.css'

const Input = ({
    name,
    value,
    onChange,
    placeholder,
    autoFocus = false,
    type,
    id,
    inputStyle
}) => {
    return (
        <input
            id={id}
            name={name}
            type={type}
            className={`${styles.input} ${inputStyle}`}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            autoFocus={autoFocus}
        />
    )
}

export default Input
