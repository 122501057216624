import React, { useEffect, useState } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import './index.css'

const initialState = {
    from: null,
    hoverTo: null,
    enteredTo: null, // Keep track of the last day for mouseEnter.
}

const CalendarRange = ({ setRange }) => {
    const [state, setState] = useState(initialState)

    useEffect(() => {
        const from = new Date(state.from)
        from.setHours(0,0,0,0)
        const to = new Date(state.enteredTo)
        to.setHours(0,0,0,0)


        setRange({
            from,
            to
        })
    }, [setRange, state.enteredTo, state.from])

    const isSelectingFirstDay = (from, hoverTo, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
        const isRangeSelected = from && hoverTo
        return !from || isBeforeFirstDay || isRangeSelected
    }

    const handleDayClick = (day) => {
        const { from, hoverTo } = state
        if (from && hoverTo && day >= from && day <= hoverTo) {
            handleResetClick()
            return
        }

        if (isSelectingFirstDay(from, hoverTo, day)) {
            setState({
                from: day,
                hoverTo: null,
                enteredTo: null,
            })
        } else {
            setState(currState => { 
                return {...currState, hoverTo: day, enteredTo: day}
            })
        }
    }

    const handleDayMouseEnter = (day) => {
        const { from, hoverTo } = state
        if (!isSelectingFirstDay(from, hoverTo, day)) {
            setState(currState => { 
                return {...currState, enteredTo: day}
            })
        }
    }

    const handleResetClick = () => {
        setState(initialState)
    }

    return (
        <div>
            <DayPicker
                className='range'
                showWeekNumbers
                showOutsideDays
                firstDayOfWeek={1}
                selectedDays={[state.from, { from: state.from, to: state.enteredTo }]}
                modifiers={{ start: state.from, end: state.enteredTo }}
                onDayClick={handleDayClick}
                onDayMouseEnter={handleDayMouseEnter}
            />
            <div>
                {!state.from && !state.hoverTo && 'Please select the first day.'}
                {state.from && !state.hoverTo && 'Please select the last day.'}
                {state.from && state.hoverTo && `Selected from ${state.from.toLocaleDateString()} to ${state.hoverTo.toLocaleDateString()}`}
                {state.from && state.hoverTo && (
                    <button onClick={handleResetClick}>
                        Reset
                    </button>
                )}
            </div>
        </div>
    )
}

export default CalendarRange
