import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import Project from '../Project'
import ResponsiveInput from '../../Inputs/ResponsiveInput'
import { getTaskKey } from '../../../utils/userTasks'

const Client = ({ client, clientIndex, isLastElement }) => {
    const { tasksState, selectClient, returnClient, addClient, addProject } = useContextTimeCard()
    const [unselectedProjects, setUnselectedProjects] = useState(null)
    const [isInCurrRequest, setIsInCurrRequest] = useState(false)

    const handleSelectClient = async (client) => {
        selectClient(client, clientIndex)
    }

    const handleReturnClient = (value) => {
        returnClient(value, clientIndex)
    }

    useEffect(() => {
        if (!client.selectedProjects.length) {
            addProject(clientIndex)
        }

        if (!client.projects) {
            return
        }

        const newUnselectedProjects = client.projects.filter(p => client.selectedProjects && !client.selectedProjects.find(sp => {
            if (!sp) {
                return false
            }

            return p.projectid === sp.projectid
        }))

        setUnselectedProjects(newUnselectedProjects)
    }, [addProject, client.projects, client.selectedProjects, clientIndex])

    useEffect(() => {
        //checking if the client have tasks with filled hours
        const currClient = tasksState.selectedClients[clientIndex]
        let haveFilledHours = false

        for (let i = 0; i < currClient.selectedProjects.length; i++) {
            const taskKey = getTaskKey(clientIndex, i)
            const tasks = tasksState.tasks[taskKey]
            if (!tasks) continue

            for (const task of tasks) {
                haveFilledHours = !!Object.keys(task.hours).length
    
                if (haveFilledHours) {
                    break
                }
            }

            if (haveFilledHours) {
                break
            }
        }
        
        setIsInCurrRequest(haveFilledHours)
    }, [clientIndex, tasksState.selectedClients, tasksState.tasks])

    return (
        <div className={styles.container}>
            <div className={styles['input-container']}>
                <ResponsiveInput
                    title='Client'
                    unselectedOptions={tasksState.unselectedClients || []}
                    options={tasksState.clients}
                    setOption={handleSelectClient}
                    returnOption={handleReturnClient}
                    initialValue={client}
                    isInCurrRequest={isInCurrRequest}
                    isDisabled={client.selectedProjects.some(sp => {
                        if (!sp) {
                            return false
                        }

                        return sp.projectname
                    })}
                    index={0}
                />
                {isLastElement &&
                    <button onClick={addClient} className={styles.button} ></button>
                }
            </div>
            <div className={styles.projects}>
                {client.selectedProjects && client.selectedProjects.map((project, i) => (
                    <div key={i} className={styles.project} >
                        <Project
                            project={project}
                            unselectedProjects={unselectedProjects || []}
                            allProjects={client.projects || []}
                            projectIndex={i}
                            clientIndex={clientIndex}
                            isLastElement={i === client.selectedProjects.length - 1}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Client
