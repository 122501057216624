const checkIsMonthLocked = (monthConfirmation, timeCards, selectedDays) => {
  const monthsForCheck = new Set([])

  for (const task of timeCards.tasksForSave) {
    Object.keys(task.hours).forEach(d => {
      const currMonth = new Date(selectedDays[d]).getMonth()
      const currYear = new Date(selectedDays[d]).getFullYear()
      monthsForCheck.add(`${currYear}-${currMonth}`)
    })
  }

  for (const task of timeCards.timeCardsForUpdate) {
    const currMonth = new Date(selectedDays[task.dayOfWeek]).getMonth()
    const currYear = new Date(selectedDays[task.dayOfWeek]).getFullYear()
    monthsForCheck.add(`${currYear}-${currMonth}`)
  }
  
  for (const m of Object.values(monthConfirmation)) {
    if (monthsForCheck.has(m.month) && (m.isconfirmed || m.islocked)) {
      return true
    }
  }

  return false
}

const editTimeCardsCheckIsMonthLocked = (monthConfirmation, tasks) => {
  const monthsForCheck = new Set([])

  for (const task of tasks) {
    const currMonth = new Date(task.date).getMonth()
    const currYear = new Date(task.date).getFullYear()
    monthsForCheck.add(`${currYear}-${currMonth}`)
  }

  for (const m of Object.values(monthConfirmation)) {
    if (monthsForCheck.has(m.month) && (m.isconfirmed || m.islocked)) {
      return true
    }
  }

  return false
}

export {
  checkIsMonthLocked,
  editTimeCardsCheckIsMonthLocked,
}
