import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import useUserServices from './user'

const MONTH_CONFIRMATION_URL = REST_API_URL + '/month-confirmation'

const useMonthConfirmationServices = () => {
    const { verifyUserSession } = useUserServices()

    const getMonthConfirmation = async (month) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${MONTH_CONFIRMATION_URL}/${month}`,{
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
        })
    
        return await response.json()
    }

    const confirmMonth = async (month) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${MONTH_CONFIRMATION_URL}/confirm`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ month })
        })
    
        return await response.json()
    }

    const removeMonthConfirmation = async (confirmationid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${MONTH_CONFIRMATION_URL}/remove-confirmation`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ confirmationid })
        })
    
        return await response.json()
    }

    const lockUserMonth = async (monthConfirmation, userid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${MONTH_CONFIRMATION_URL}/lock-user-month`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ monthConfirmation, userid })
        })
    
        return await response.json()
    }

    const lockMonth = async (monthKey, islocked) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${MONTH_CONFIRMATION_URL}/lock-month`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ monthKey, islocked })
        })
    
        return await response.json()
    }
    
    return {
        getMonthConfirmation,
        confirmMonth,
        removeMonthConfirmation,
        lockUserMonth,
        lockMonth,
    }
}

export default useMonthConfirmationServices
