import React, { useState } from 'react'
import styles from './index.module.css'
import CalendarRange from '../CalendarRange'
import ExportCSV from '../ExportCSV'

const UserReference = () => {
    const [isGetReferenceShown, setIsGetReferenceShown] = useState(false)
    const [range, setRange] = useState(null)

    return (
        <div className={styles.container}>
            {!isGetReferenceShown
                ? <button onClick={() => setIsGetReferenceShown(true)} >Get Reference</button>
                : <div>
                    <CalendarRange setRange={setRange} />
                    <ExportCSV
                        fileName='test'
                        setIsGetReferenceShown={setIsGetReferenceShown}
                        range={range}
                    />
                </div>
            }
        </div>
    )
}

export default UserReference
