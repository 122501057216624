import { useContext, createContext, useReducer, useCallback, useEffect } from 'react'
import { arrangeClientsAndProjects, arrangeFilterData, getSelectedPropertyString, initialRangeState, initialSort, initialState } from '../utils/statistics'
import { useContextTimeCard } from './timeCardContext'

const StatisticsContext = createContext()

export function useContextStatistics() {
    return useContext(StatisticsContext)
}

const StatisticsContextInitializer = ({ children }) => {
    const { tasksState } = useContextTimeCard()

    const reducer = (statisticsState, action) => {
        const { value, type } = action

        switch (type) {
            case 'setSelectedRange': {
                const newSelectedRange = [...statisticsState.selectedRange]
                newSelectedRange[newSelectedRange.length - 1] = value
                return { ...statisticsState, selectedRange: newSelectedRange }
            }
            case 'removeCalendarRange': {
                const newSelectedRange = [...statisticsState.selectedRange]
                newSelectedRange.splice(value, 1)
                if (!newSelectedRange.length) {
                    newSelectedRange.push(initialRangeState)
                }

                return { ...statisticsState, selectedRange: newSelectedRange }
            }
            case 'addNewCalendarRange': {
                const newSelectedRange = [...statisticsState.selectedRange]
                newSelectedRange.push(initialRangeState)

                return { ...statisticsState, selectedRange: newSelectedRange }
            }
            case 'setFilterData': {
                return { ...statisticsState, ...value }
            }
            case 'setSelectedProperty': {
                const { type, newValue } = value
                const selectedProperty = getSelectedPropertyString(type)

                return { ...statisticsState, [selectedProperty]: { ...statisticsState[selectedProperty], ...newValue } }
            }
            case 'removeSelectedProperty': {
                const { type, id } = value
                const selectedProperty = getSelectedPropertyString(type)
                const newState = { ...statisticsState[selectedProperty] }
                delete newState[id]

                return { ...statisticsState, [selectedProperty]: newState, filtersChanged: true }
            }
            case 'clearSelection': {
                const newStatisticsState = { ...statisticsState }
                newStatisticsState.selectedRange = [initialRangeState]
                newStatisticsState.selectedClients = {}
                newStatisticsState.selectedProjects = {}
                newStatisticsState.selectedZones = {}
                newStatisticsState.selectedSystems = {}
                newStatisticsState.selectedJobs = {}
                newStatisticsState.selectedActivities = {}
                newStatisticsState.selectedLeads = {}
                newStatisticsState.selectedRevisions = {}
                newStatisticsState.selectedUsers = {}
                newStatisticsState.sort = initialSort

                delete newStatisticsState.statisticsResults
                delete newStatisticsState.resultsTotalCount
                delete newStatisticsState.resultsPage
                delete newStatisticsState.resultsTotalHours

                return newStatisticsState
            }
            case 'setStatisticsResults': {
                return { ...statisticsState, statisticsResults: value }
            }
            case 'setResultsTotalCount': {
                return { ...statisticsState, resultsTotalCount: Number(value) }
            }
            case 'setResultsTotalHours': {
                return { ...statisticsState, resultsTotalHours: Number(value) }
            }
            case 'setResultsPage': {
                return { ...statisticsState, resultsPage: Number(value) }
            }
            case 'setFiltersChanged': {
                if (!statisticsState.statisticsResults && value) {
                    return statisticsState
                }

                return { ...statisticsState, filtersChanged: value }
            }
            case 'setSort': {
                return { ...statisticsState, sort: value }
            }
            default: {
                return statisticsState
            }
        }
    }

    const [statisticsState, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const arrangedData = arrangeClientsAndProjects(tasksState.clients, tasksState.projectsColumnData)
        if (!arrangedData) return
        dispatch({ type: 'setFilterData', value: arrangedData })
    }, [tasksState.clients, tasksState.projectsColumnData])

    const setSelectedRange = (newRange) => {
        dispatch({ type: 'setSelectedRange', value: newRange })
        dispatch({ type: 'setFiltersChanged', value: true })
    }

    const removeCalendarRange = (index) => {
        dispatch({ type: 'removeCalendarRange', value: index })
    }

    const addNewCalendarRange = () => {
        dispatch({ type: 'addNewCalendarRange' })
    }

    const setFilterData = useCallback((data) => {
        const arrangedData = arrangeFilterData(data)
        dispatch({ type: 'setFilterData', value: arrangedData })
    }, [])

    const setSelectedProperty = (type, newValue) => {
        dispatch({ type: 'setSelectedProperty', value: { type, newValue } })
    }

    const removeSelectedProperty = (type, id) => {
        dispatch({ type: 'removeSelectedProperty', value: { type, id } })
    }

    const clearSelection = () => {
        dispatch({ type: 'clearSelection' })
        dispatch({ type: 'setFiltersChanged', value: false })
    }

    const setStatisticsResults = (data) => {
        dispatch({ type: 'setStatisticsResults', value: data })
    }

    const setResultsTotalCount = (count) => {
        dispatch({ type: 'setResultsTotalCount', value: count })
    }

    const setResultsTotalHours = (total) => {
        dispatch({ type: 'setResultsTotalHours', value: total })
    }

    const setResultsPage = (num) => {
        dispatch({ type: 'setResultsPage', value: num })
    }

    const setFiltersChanged = (value) => {
        dispatch({ type: 'setFiltersChanged', value })
    }

    const setSort = (value) => {
        dispatch({ type: 'setSort', value })
    }

    return (
        <StatisticsContext.Provider value={{
            statisticsState,
            setSelectedRange,
            removeCalendarRange,
            addNewCalendarRange,
            setFilterData,
            setSelectedProperty,
            removeSelectedProperty,
            clearSelection,
            setStatisticsResults,
            setResultsTotalCount,
            setResultsTotalHours,
            setResultsPage,
            setFiltersChanged,
            setSort
        }}>
            {children}
        </StatisticsContext.Provider>
    )
}

export default StatisticsContextInitializer
