import React from 'react'
import styles from './index.module.css'

const CustomInput = ({ optionName, setOption, currentValue, setCurrentValue, lineExists }) => {

    const handleClick = () => {
        if (lineExists || !currentValue) return

        setOption(currentValue)
        setCurrentValue('')
    }

    return (
        <div className={styles['list-li']}>
            <input
                type='text'
                value={currentValue}
                onChange={e => setCurrentValue(e.target.value)}
                placeholder={`Create new ${optionName}`}
                className={`${styles.input} ${lineExists && styles.red}`}
            />
            <button 
                onClick={handleClick} 
                className={lineExists 
                    ? `${styles['btn-red']} ${styles['input-icon']}` 
                    : styles['input-icon']}
            >
                ADD
            </button>
        </div>
    )
}

export default CustomInput
