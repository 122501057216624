import { formatDate, getWeekNumber, localToUtc } from './date'
import { deepCopy } from './deepCopy'
import { dateStyle, emptyDateStyle, getDescriptionStyle, getHoursStyle } from './excelStyles'

const initialRangeState = {
    from: null,
    hoverTo: null,
    enteredTo: null
}

const initialSort = {
    primary: {
        type: 'date',
        ascending: false
    },
    secondary: {
        type: null,
        ascending: true
    }
}

const initialState = {
    selectedRange: [initialRangeState],
    clients: {},
    projects: {},
    zones: {},
    systems: {},
    jobs: {},
    activities: {},
    leads: {},
    revisions: {},
    users: {},
    selectedClients: {},
    selectedProjects: {},
    selectedZones: {},
    selectedSystems: {},
    selectedJobs: {},
    selectedActivities: {},
    selectedLeads: {},
    selectedRevisions: {},
    selectedUsers: {},
    sort: initialSort,
}

const getSingularFromPlural = (tableName) => {
    const pluralToSingularObject = {
        activities: 'activity',
        clients: 'client',
        jobs: 'job',
        leads: 'lead',
        projects: 'project',
        revisions: 'revision',
        systems: 'system',
        users: 'user',
        zones: 'zone'
    }

    return pluralToSingularObject[tableName]
}

const getSelectedPropertyString = (type) => {
    const typeToSelectedPropertyObject = {
        activity: 'selectedActivities',
        client: 'selectedClients',
        job: 'selectedJobs',
        lead: 'selectedLeads',
        project: 'selectedProjects',
        revision: 'selectedRevisions',
        system: 'selectedSystems',
        user: 'selectedUsers',
        zone: 'selectedZones'
    }

    return typeToSelectedPropertyObject[type]
}

const arrangeFilterData = (data) => {
    const arrangedData = {}

    for (const key in data) {
        arrangedData[key] = {}
        const id = getSingularFromPlural(key) + 'id'
        const name = getSingularFromPlural(key) + 'name'
        data[key].forEach(row => arrangedData[key][row[id]] = row[name])
    }

    return arrangedData
}

const arrangeClientsAndProjects = (clients, projectsColumnData) => {
    const arrangedData = {}
    arrangedData.clients = {}
    arrangedData.projects = {}

    for (const client of clients) {
        const projects = {}

        client.projects.forEach(p => {
            if (!projectsColumnData[p.projectid]) return

            const { systems, zones, jobs, activities, leads, revisions } = projectsColumnData[p.projectid]

            projects[p.projectid] = {
                projectname: p.projectname,
                clientid: p.clientid,
                systems: new Set(systems.map(s => s.systemid)),
                zones: new Set(zones.map(z => z.zoneid)),
                jobs: new Set(jobs.map(j => j.jobid)),
                activities: new Set(activities.map(a => a.activityid)),
                leads: new Set(leads.map(l => l.leadid)),
                revisions: new Set(revisions.map(r => r.revisionid))
            }
        })

        arrangedData.clients[client.clientid] = {
            clientname: client.clientname,
            projects
        }

        arrangedData.projects = { ...arrangedData.projects, ...projects }
    }

    return arrangedData
}

const arrangeExportCellData = (data, sortedUsers) => {
    const arrangedData = {}

    for (const row of data) {
        if (!arrangedData[row.date]) {
            arrangedData[row.date] = {}
            arrangedData[row.date][row.name] = [row]
            continue
        }

        if (!arrangedData[row.date][row.name]) {
            arrangedData[row.date][row.name] = [row]
            continue
        }

        arrangedData[row.date][row.name].push(row)
    }

    const sortedData = Object.entries(arrangedData).sort((a, b) => new Date(a[0]) - new Date(b[0]))

    const cellData = []
    for (const element of sortedData) {
        const usersTimesheets = []
        let areAllTimesheetsArranged = false
        let isFirstRowOfDate = true

        while (!areAllTimesheetsArranged) {
            areAllTimesheetsArranged = true
            let row = []

            for (const user of sortedUsers) {
                if (!element[1][user]) {
                    row = [
                        ...row,
                        { value: '', style: getDescriptionStyle(isFirstRowOfDate) },
                        { value: '', style: getHoursStyle(isFirstRowOfDate) }
                    ]
                    continue
                }

                const timesheet = element[1][user].shift()
                if (!timesheet) {
                    row = [
                        ...row,
                        { value: '', style: getDescriptionStyle(isFirstRowOfDate) },
                        { value: '', style: getHoursStyle(isFirstRowOfDate) }
                    ]
                    continue
                }

                const description = getDescription(timesheet)
                row = [
                    ...row,
                    { value: description, style: getDescriptionStyle(isFirstRowOfDate) },
                    { value: timesheet.hours, style: getHoursStyle(isFirstRowOfDate) }
                ]

                if (element[1][user].length) {
                    areAllTimesheetsArranged = false
                }
            }

            usersTimesheets.push(row)
            isFirstRowOfDate = false
        }

        cellData.push([
            { value: formatDate(new Date(element[0]), '%a %d.%m.%Y'), style: dateStyle },
            ...usersTimesheets.shift()
        ])

        for (const element of usersTimesheets) {
            cellData.push([
                { value: '', style: emptyDateStyle },
                ...element
            ])
        }
    }

    return cellData
}

const arrangeExportMonthCellData = (data, sortedUsers) => {
    const arrangedData = {}
    for (const row of data) {
        const weekNum = `Week ${getWeekNumber(new Date(row.date))}`
        const description = getDescription(row)

        if (!arrangedData[weekNum]) {
            arrangedData[weekNum] = {
                [row.name]: {
                    [description]: row.hours
                }
            }
            continue
        }

        if (!arrangedData[weekNum][row.name]) {
            arrangedData[weekNum][row.name] = {
                [description]: row.hours
            }
            continue
        }

        if (!arrangedData[weekNum][row.name][description]) {
            arrangedData[weekNum][row.name][description] = row.hours
            continue
        }

        arrangedData[weekNum][row.name][description] += row.hours
    }

    const cellData = []
    for (const week in arrangedData) {
        let isWeekReady = false
        let isFirstRowOfWeek = true
        const weekData = []
        
        while (!isWeekReady) {
            let row = isFirstRowOfWeek
                ? [{ value: week, style: dateStyle }]
                : [{ value: '', style: emptyDateStyle }]

            for (const user of sortedUsers) {
                if (!arrangedData[week][user]) {
                    row = [
                        ...row,
                        { value: '', style: getDescriptionStyle(isFirstRowOfWeek) },
                        { value: '', style: getHoursStyle(isFirstRowOfWeek) }
                    ]
                    continue
                }
    
                const description = Object.keys(arrangedData[week][user])[0]
                const hours = arrangedData[week][user][description]
                row = [
                    ...row,
                    { value: description, style: getDescriptionStyle(isFirstRowOfWeek) },
                    { value: hours, style: getHoursStyle(isFirstRowOfWeek) }
                ]
    
                delete arrangedData[week][user][description]
    
                if (Object.keys(arrangedData[week][user]).length === 0) {
                    delete arrangedData[week][user]
                }
            }

            if (Object.keys(arrangedData[week]).length === 0) {
                isWeekReady = true
            }

            isFirstRowOfWeek = false
            weekData.push(row)
        }

        cellData.push(weekData)
    }

    const sortedCellData = cellData.sort((a, b) => Number(a[0][0].value.slice(-2)) - Number(b[0][0].value.slice(-2)))
    return sortedCellData.flat()
}

const getDescription = (timesheet) => {
    let description = ''

    if (timesheet.zonename || timesheet.zonename === '0') {
        description += `Zone: ${timesheet.zonename}`
    }

    if (timesheet.jobname || timesheet.jobname === '0') {
        description = description.length ? description + `\nJob: ${timesheet.jobname}`
                                        : `Job: ${timesheet.jobname}`
    }

    if (timesheet.leadname || timesheet.leadname === '0') {
        description = description.length ? description + `\nLead/Department: ${timesheet.leadname}`
                                        : `Lead/Department: ${timesheet.leadname}`
    }

    if (timesheet.systemname || timesheet.systemname === '0') {
        description = description.length ? description + `\nSystem: ${timesheet.systemname}`
                                        : `System: ${timesheet.systemname}`
    }

    return description
}

const getFilters = (statisticsState) => {
    const selectedRange = statisticsState.selectedRange.map(sr => ({ from: new Date(sr.from), to: new Date(sr.hoverTo) }))
    const lastRange = selectedRange[selectedRange.length - 1]
    if (
        lastRange.from.getTime() === 0 ||
        lastRange.to.getTime() === 0
    ) {
        selectedRange.pop()
    }

    return {
        selectedClients: Object.keys(statisticsState.selectedClients),
        selectedProjects: Object.keys(statisticsState.selectedProjects),
        selectedZones: Object.keys(statisticsState.selectedZones),
        selectedSystems: Object.keys(statisticsState.selectedSystems),
        selectedJobs: Object.keys(statisticsState.selectedJobs),
        selectedActivities: Object.keys(statisticsState.selectedActivities),
        selectedLeads: Object.keys(statisticsState.selectedLeads),
        selectedRevisions: Object.keys(statisticsState.selectedRevisions),
        selectedUsers: Object.keys(statisticsState.selectedUsers),
        selectedRange,
        sort: statisticsState.sort,
        // offset: (statisticsState.resultsPage && (statisticsState.resultsPage - 1) * ROWS_ON_PAGE) || 0
    }
}

const convertDateRangesToUTC = (dateRanges) => {
    if (!dateRanges) return

    const newDateRanges = deepCopy(dateRanges)
    for (let rangeIndex = 0; rangeIndex < dateRanges.length; rangeIndex++) {
        newDateRanges[rangeIndex].from = localToUtc(newDateRanges[rangeIndex].from)
        newDateRanges[rangeIndex].to = localToUtc(newDateRanges[rangeIndex].to)
    }

    return newDateRanges
}

export {
    initialRangeState,
    initialSort,
    initialState,
    getSelectedPropertyString,
    arrangeFilterData,
    arrangeClientsAndProjects,
    arrangeExportCellData,
    arrangeExportMonthCellData,
    getFilters,
    convertDateRangesToUTC
}
