import { useReducer, useState } from 'react'
import { getInitialState, reducer } from '../utils/project'
import { PROJECT_COLUMN_NAMES } from '../utils/constants'
import { pluralizeWord } from '../utils/text'

const initialUpdatedOptions = {
    create: {
        zones: new Set(),
        systems: new Set(),
        jobs: new Set(),
        revisions: new Set(),
        activities: new Set(),
        leads: new Set()
    },
    deleted: {},
    add: {}
}

const useProject = () => {
    const [projectState, setProjectDispatch] = useReducer(reducer, getInitialState())
    const [isProjectStateFilled, setIsProjectStateFilled] = useState(false)
    const [option, setOption] = useState([])
    const [updatedOptions, setUpdatedOptions] = useState(initialUpdatedOptions)

    const fillProjectDispatch = ({ type, value }) => {
        if (!isProjectStateFilled) {
            setIsProjectStateFilled(true)
        }

        if (type === 'resetState') {
            setIsProjectStateFilled(false)
        }

        setProjectDispatch({ type: type, value })
    }

    const setSelectedOption = (optionName, value) => {
        const optionNamePlural = PROJECT_COLUMN_NAMES[optionName]
        const upperCaseLetter = optionNamePlural.charAt(0).toUpperCase() + optionNamePlural.slice(1)
        const type = 'setProject' + upperCaseLetter
        fillProjectDispatch({ type: type, value })
    }

    const setManualOption = (optionName, value) => {
        const optionNamePlural = PROJECT_COLUMN_NAMES[optionName]
        const upperCaseLetter = optionNamePlural.charAt(0).toUpperCase() + optionNamePlural.slice(1)
        const type = 'setManual' + upperCaseLetter
        fillProjectDispatch({ type: type, value })
    }

    const setOptionVariable = (e) => {
        const propValue = e.target.value
        const isChecked = e.target.checked

        const newValues = isChecked
            ? [...option, propValue]
            : option.filter(v => v !== propValue)

        setOption(newValues)
    }

    const setCopiedProject = (value) => {
        const isCopied = true
        fillOption('Add', value, isCopied)
        fillProjectDispatch({ type: 'setCopiedProject', value })
    }

    const setEditProject = (value) => {
        fillOption('Edit', value)
        setProjectDispatch({ type: 'setEditProject', value })
    }

    const fillOption = (action, value, isCopied) => {
        let filledOption = isCopied ? ['Do you want to copy an existing project?'] : []

        if (value.zones.length) {
            filledOption.push(`${action} zones`)
        }

        if (value.systems.length) {
            filledOption.push(`${action} systems`)
        }

        if (value.jobs.length) {
            filledOption.push(`${action} jobs`)
        }

        if (value.revisions.length) {
            filledOption.push(`${action} revisions`)
        }

        if (value.activities.length) {
            filledOption.push(`${action} activities`)
        }

        if (value.leads.length) {
            filledOption.push(`${action} leads`)
        }

        setOption(filledOption)
    }

    const handleChangeAddProject = ({ propId, isChecked, optionName }) => {
        const options = pluralizeWord(optionName)

        let newValues = new Set(projectState[options])

        Array.from(propId).forEach(projectId => {
            if (isChecked) {
                newValues.add(Number(projectId))
            } else {
                newValues.delete(Number(projectId))
            }
        })

        setSelectedOption(optionName, newValues)
    }

    const handleChangeEditProject = ({ propId, isChecked, optionName }) => {
        const options = pluralizeWord(optionName)

        let newValues = new Set(projectState[options])
        const newUpdatedOption = { ...updatedOptions }
        newUpdatedOption.add = { ...updatedOptions.add }
        newUpdatedOption.deleted = { ...updatedOptions.deleted }
        
        if (!newUpdatedOption.add[options]) {
            newUpdatedOption.add[options] = new Set()
        } else {
            newUpdatedOption.add[options] = new Set(updatedOptions.add[options])
        }

        if (!newUpdatedOption.deleted[options]) {
            newUpdatedOption.deleted[options] = new Set()
        } else {
            newUpdatedOption.deleted[options] = new Set(updatedOptions.deleted[options])
        }

        if (isChecked) {
            Array.from(propId).forEach(projectId => {
                const id = Number(projectId)
                newValues.add(id)

                if (newUpdatedOption.deleted[options] && newUpdatedOption.deleted[options].has(id)) {
                    newUpdatedOption.deleted[options].delete(id)
                } else {
                    newUpdatedOption.add[options].add(id)
                }

            })

            setUpdatedOptions(newUpdatedOption)
        } else {

            Array.from(propId).forEach(projectId => {
                const id = Number(projectId)
                newValues.delete(id)

                if (newUpdatedOption.add[options] && newUpdatedOption.add[options].has(id)) {
                    newUpdatedOption.add[options].delete(id)
                } else {
                    newUpdatedOption.deleted[options].add(id)
                }
            })

            setUpdatedOptions(newUpdatedOption)
        }

        setSelectedOption(optionName, newValues)
    }

    const setManualOptionEdit = (optionName, value) => {
        const options = pluralizeWord(optionName)
        const newUpdatedOption = { ...updatedOptions }
        newUpdatedOption.create = { ...updatedOptions.create }
        newUpdatedOption.create[options] = new Set(value)
        setUpdatedOptions(newUpdatedOption)
    }

    const resetUpdatedOptions = () => {
        setUpdatedOptions(initialUpdatedOptions)
    }

    return {
        projectState,
        option,
        isProjectStateFilled,
        updatedOptions,
        setProjectDispatch,
        setOption,
        fillProjectDispatch,
        setSelectedOption,
        setManualOption,
        setOptionVariable,
        setCopiedProject,
        setEditProject,
        handleChangeAddProject,
        handleChangeEditProject,
        setManualOptionEdit,
        resetUpdatedOptions
    }
}

export default useProject
