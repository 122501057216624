import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import useUserServices from './user'

const OVERTIME_URL = REST_API_URL + '/overtime'

const useOvertimeServices = () => {
    const { verifyUserSession } = useUserServices()

    const saveOvertime = async (overtime) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${OVERTIME_URL}/save`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ overtime })
        })
    
        return await response.json()
    }

    const lockOvertime = async (overtime, userid) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${OVERTIME_URL}/lock-overtime`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ overtime, userid })
        })
    
        return await response.json()
    }

    const lockWeek = async (weekKey, islocked) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${OVERTIME_URL}/lock-week`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ weekKey, islocked })
        })
    
        return await response.json()
    }
    
    const deleteOvertime = async (week) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${OVERTIME_URL}/delete`,{
            method: 'DELETE',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ week })
        })
    
        return await response.json()
    }
    
    const removeOvertimeConfirmation = async (overtimeid, ischanged) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${OVERTIME_URL}/remove-confirmation`,{
            method: 'PATCH',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({ overtimeid, ischanged })
        })
    
        return await response.json()
    }
    
    return {
        saveOvertime,
        lockOvertime,
        lockWeek,
        deleteOvertime,
        removeOvertimeConfirmation
    }
}

export default useOvertimeServices
