import React from 'react'
import useVisible from '../../../hooks/useVisible'
import { formatDate, MONTHS } from '../../../utils/date'
import styles from './index.module.css'

const YearMonthForm = ({ date, localeUtils, onMonthChange, onYearChange, fromMonth, toMonth }) => {
    const [monthRef, isMonthActive, setIsMonthActive] = useVisible()
    const [yearRef, isYearActive, setIsYearActive] = useVisible()
    const months = localeUtils.getMonths()
    const monthsStrings = MONTHS.en

    const years = []
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
        years.push(i)
    }

    const handleClickYear = (year) => {
        onYearChange(new Date(year, date.getMonth()))
    }

    const handleClickMonth = (month) => {
        onMonthChange(new Date(date.getFullYear(), monthsStrings.indexOf(month)))
    }

    return (
        <form className='DayPicker-Caption'>
            <div className={styles.flex}>
                <div className={styles['container']}>
                    <div className={`${styles.select} ${styles.year}`} onClick={() => setIsYearActive(!isYearActive)} >
                        <div>{date.getFullYear()}</div>
                        <span>▼</span>
                    </div>
                    {isYearActive && <div ref={yearRef} className={`${styles.dropdown} ${styles.year}`} >
                        {years.sort((a, b) => b - a)
                            .map(year => (
                                <div
                                    key={year} className={styles.option}
                                    onClick={() => handleClickYear(year)}
                                >
                                    <div>{year}</div>
                                </div>
                            ))
                        }
                    </div>}
                </div>
                <div className={styles['container']}>
                    <div className={`${styles.select} ${styles.month}`} onClick={() => setIsMonthActive(!isMonthActive)} >
                        <div>{formatDate(date, '%B')}</div>
                        <span>▼</span>
                    </div>
                    {isMonthActive && <div ref={monthRef} className={`${styles.dropdown} ${styles.month}`} >
                        {months.sort((a, b) => b - a)
                            .map(month => (
                                <div
                                    key={month} className={styles.option}
                                    onClick={() => handleClickMonth(month)}
                                >
                                    <div>{month}</div>
                                </div>
                            ))
                        }
                    </div>}
                </div>
            </div>
        </form>
    )
}

export default YearMonthForm
