import styles from './index.module.css'
import { useContextError } from '../../contexts/errorContext'
import ErrorMessage from '../ErrorMessage'

const ErrorWrapper = ({ children }) => {
    const { messages, removeMessage } = useContextError()

    return (
        <>
            {children}
            {Array.from(messages).length > 0
                ?
                <div className={styles['error-container']}>
                    {Array.from(messages).map(msg =>
                        <ErrorMessage
                            key={`${msg}`}
                            message={msg}
                            handleClose={() => removeMessage(msg)}
                        />
                    )}
                </div>
                : null
            }
        </>
    )
}

export default ErrorWrapper