import React, { useRef, useMemo, useState } from 'react'
import useInputDropdownKeyNav from '../../../hooks/useInputDropdownKeyNav'
import styles from './index.module.css'

const InputDropdown = (
    {
        value,
        setValue,
        placeholder,
        type,
        setOption,
        returnOption,
        isDisabled,
        setIsOnFocus,
        setIsInputShown,
        inputOptions,
        isInputDropdownBad,
        isOnFocus,
        customContainerStyle = {},
        customStyle = {},
        autoFocus = false,
        inputId,
    }) => {
    const name = type + 'name'
    const id = type + 'id'
    const [oldValue, setOldValue] = useState(null)
    const [currentOptionIndex, setCurrentOptionIndex] = useState(-1)
    const containerRef = useRef(null)
    const inputRef = useRef(null)

    const sortedSearchFilteredOptions = useMemo(() => {
        return inputOptions.filter(option => option[name].toLowerCase().includes(value.toLowerCase()))
            .sort((a, b) => a[name].localeCompare(b[name]))
    }, [inputOptions, name, value])

    useInputDropdownKeyNav({
        optionNameKey: name,
        isOnFocus,
        currentOptionIndex,
        setCurrentOptionIndex,
        setValue,
        sortedOptions: sortedSearchFilteredOptions,
        inputRef,
        containerRef
    })

    const onFocus = async () => {
        setIsOnFocus(true)
        setOldValue(value)
    }

    const onBlur = () => {
        setIsOnFocus(false)
        setCurrentOptionIndex(-1)

        if (setIsInputShown) {
            setTimeout(() => {
                setIsInputShown(false)
            }, 1)
        }

        if (oldValue === value) {
            return
        }

        const option = inputOptions.find(opt => opt[name] === value)
        if (option) {
            setOption(option, type)
            return
        }

        if (type !== 'client' && type !== 'project') {
            const emptyOption = {
                [name]: value,
                [id]: ''
            }

            setOption(emptyOption, type)
        }
    }

    const selectOption = (opt) => {
        setValue(opt[name])
    }

    const handleChange = (e) => {
        setValue(e.target.value)

        if (returnOption) {
            returnOption(e.target.value)
        }
    }

    return (
        <div className={`${styles.container} input-dropdown`} style={customContainerStyle} ref={containerRef}>
            <input
                autoComplete='off'
                value={value}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleChange}
                className={`${styles.input} ${isInputDropdownBad && styles['bad-input']}`}
                style={customStyle}
                disabled={isDisabled}
                autoFocus={autoFocus}
                ref={inputRef}
                id={inputId}
            />
            { isOnFocus && 
                <div className={styles['options-container']}>
                    {sortedSearchFilteredOptions.map((opt, i) => {
                        return (
                            <div
                                key={i}
                                onMouseDown={() => selectOption(opt)}
                                onMouseEnter={() => setCurrentOptionIndex(i)}
                                onMouseLeave={() => setCurrentOptionIndex(-1)}
                                className={`${styles.option} ${i === currentOptionIndex && styles.hover}`}
                            >
                                {opt[name]}
                            </div>)
                    })}
                </div>
            }
        </div>
    )
}

export default InputDropdown
