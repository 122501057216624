import { getRange } from '../../utils/getRange'
import styles from './index.module.css'
import alarmClock from '../../public/alarmClock.svg'
import alarmClockFilled from '../../public/alarmClockFilled.svg'
import useUserServices from '../../services/user'
import useError from '../../hooks/useError'

const UserReminder = ({ 
    customStyles, 
    reminderWeekday,
    reminderHour, 
    reminderIsActivated, 
    setUser, 
    isDisabled, 
    userId 
}) => {
    const { setErrorMessage } = useError()
    const { changeWeeklyReminderStatus } = useUserServices()

    const handleReminderChange = (newValue, isWeekdayChanged) => {
        if (isWeekdayChanged) {
            setUser(currUser => ({ ...currUser, reminderWeekday: newValue, reminderHour }))
            return
        }
        
        setUser(currUser => ({ ...currUser, reminderWeekday, reminderHour: newValue }))
    }

    const handleActivationChange = async () => {
        const response = await changeWeeklyReminderStatus(userId)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setUser(currUser => ({ ...currUser, reminderIsActivated: !reminderIsActivated }))
    }
        
    return (
        <div className={styles.container}>
            <select
                className={`${customStyles.select} ${styles.weekday}`}
                value={reminderWeekday}
                onChange={(e) => handleReminderChange(e.target.value, true)}
                disabled={isDisabled}
            >
                <option>Monday</option>
                <option>Tuesday</option>
                <option>Wednesday</option>
                <option>Thursday</option>
                <option>Friday</option>
                <option>Saturday</option>
                <option>Sunday</option>
            </select>
            <select
                className={customStyles.select}
                value={`${reminderHour}:00`}
                onChange={(e) => handleReminderChange(e.target.value.replace(':00', ''), false)}
                disabled={isDisabled}
                >
                { getRange(0, 24).map(hour =>
                    <option key={hour}>{hour}:00</option>
                )}
            </select>

            <img
                src={reminderIsActivated ? alarmClockFilled : alarmClock}
                alt='Reminder switch'
                title={'Set reminder ' + (reminderIsActivated ? 'off' : 'on')}
                onClick={handleActivationChange}
                className={styles.alarm}
            />
        </div>
    )
}

export default UserReminder