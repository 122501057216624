import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import { localToUtc, utcToLocal } from '../utils/date'
import useUserServices from './user'

const HOLIDAYS_URL = REST_API_URL + '/holiday'

const useHolidayServices = () => {
    const { verifyUserSession } = useUserServices()

    const saveHolidays = async (selectedDays, isholiday) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${HOLIDAYS_URL}/save`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify({
                selectedDays: selectedDays.map(sd => localToUtc(sd)),
                isholiday
            })
        })
    
        const holidays = await response.json()
    
        const convertedHolidays = holidays.map(h => ({ ...h, holiday: utcToLocal(h.holiday) }))
    
        return convertedHolidays
    }
    
    return {
        saveHolidays
    }
}

export default useHolidayServices