import { useCallback } from 'react'
import { REST_API_URL } from '../utils/constants'

const SESSION_URL = REST_API_URL + '/session'

const useSessionServices = () => {
    const verifySession = useCallback(async (returnUser = false) => {
        const response = await fetch(`${SESSION_URL}/verify?returnUser=${returnUser}`)

        return await response.json()
    }, [])

    return {
        verifySession,
    }
}



export default useSessionServices