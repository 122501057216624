import React, { useCallback, useState } from 'react'
import styles from './index.module.css'
import Calendar from '../../components/Calendar'
import Client from '../../components/TimeCard/Client'
import SavedTimeCard from '../../components/TimeCard/SavedTimeCard'
import Weekdays from '../../components/Weekdays'
import NavigationBar from '../../components/NavigationBar'
import UserReference from '../../components/UserReference'
import { useContextTimeCard } from '../../contexts/timeCardContext'
import useTimeCardServices from '../../services/timeCard'
import useProjectServices from '../../services/project'
import useMonthConfirmationServices from '../../services/monthConfirmation'
import { arrangeSavedTasks, parseUserLastProjectChanges } from '../../utils/userTasks'
import { getWeekNumber, getWeekYear } from '../../utils/date'
import useError from '../../hooks/useError'
import useValidatedTimeCards from '../../hooks/useValidatedTimeCards'
import AlertDialogBox from '../../components/AlertDialogBox'
import { checkIsMonthLocked } from '../../utils/monthConfirmation'

const TimeCardPage = () => {
    const [isSaving, setIsSaving] = useState(false)
    const [showUpdateProjectAlert, setUpdateProjectAlert] = useState(false)

    const {
        tasksState,
        setSavedTimeCard,
        setUpdatedTimeCardIds,
        setSelectedDays,
        clearHours,
        setOvertime,
        addProjectsColumnData,
        updateProjectLastChange,
        updateUserTasks,
    } = useContextTimeCard()
    const { setErrorMessage } = useError()

    const { getTimeCard, saveTimeCard } = useTimeCardServices()
    const { getMonthConfirmation } = useMonthConfirmationServices()
    const { getUpdatedProjects } = useProjectServices()
    const getValidatedTimeCards = useValidatedTimeCards()

    const updateSavedTasks = useCallback(
        async (selected) => {
            const week = getWeekNumber(selected[1])
            const year = getWeekYear(selected[1])
            const weekKey = year + '-' + week

            const response = await getTimeCard(selected, weekKey)

            if (response.error) {
                setErrorMessage(response)
                return
            }

            let { timeCard, savedOvertime } = response
            const newSavedTimeCard = arrangeSavedTasks(timeCard)
            setSavedTimeCard(newSavedTimeCard)

            if (!savedOvertime) {
                setOvertime({ week: weekKey, hours: 0 })
                return
            }

            setOvertime({...savedOvertime})
        },
        [getTimeCard, setErrorMessage, setOvertime, setSavedTimeCard]
    )

    const handleSave = async () => {
        if (isSaving) {
            // should never normally reach here
            return setErrorMessage({ error: 'Another save request is currently being processed' })
        }

        const timeCards = getValidatedTimeCards()

        if (timeCards.error) {
            return setErrorMessage(timeCards)
        }

        const userLastProjectChanges = parseUserLastProjectChanges(timeCards.tasksForSave)

        setIsSaving(true)
        const updatedProjects = await getUpdatedProjects(userLastProjectChanges)
        // TODO update project lastchange

        if (updatedProjects.error) {
            setIsSaving(false)
            return setErrorMessage(updatedProjects)
        }

        if (!updatedProjects.areProjectsUpToDate) {
            updateProjectLastChange(updatedProjects)
            updateUserTasks(updatedProjects)
            addProjectsColumnData(updatedProjects)
            setUpdateProjectAlert(true)
            setIsSaving(false)
            return
        }

        const monthConfirmationResponse = await getMonthConfirmation(tasksState.selectedMonth)
        const isLocked = checkIsMonthLocked(monthConfirmationResponse, timeCards, tasksState.selectedDays)
        if (isLocked) {
            setIsSaving(false)
            return setErrorMessage({error: 'Trying to submit data for a locked month. Please refresh the page.'})
        }

        const response = await saveTimeCard(timeCards.tasksForSave, tasksState.selectedDays, timeCards.timeCardsForUpdate)
        setIsSaving(false)

        if (response.error) {
            return setErrorMessage(response)
        }

        clearHours()
        const newSavedTimeCard = arrangeSavedTasks(response.timeCards)
        setSavedTimeCard(newSavedTimeCard)

        if (response.updatedTimeCardIds) {
            setUpdatedTimeCardIds(response.updatedTimeCardIds)
        }
    }

    return (
        <>
            <NavigationBar />
            <main className={styles.container}>
                <div className={styles.tasks}>
                    <Calendar
                        updateSavedTasks={updateSavedTasks}
                        selectedDays={tasksState.selectedDays}
                        setSelectedDays={setSelectedDays}
                    />
                    <SavedTimeCard />
                </div>
                <div className={styles['new-tasks']}>
                    <Weekdays />
                    {tasksState.selectedClients.map((client, index) => (
                        <div key={index} className={styles.client}>
                            <Client
                                clientIndex={index}
                                client={client}
                                isLastElement={tasksState.selectedClients.length - 1 === index}
                            />
                        </div>
                    ))}
                    <div className={styles.button}>
                        {isSaving 
                            ? <div>Saving...</div>
                            : null
                        }
                        <button
                            onClick={handleSave}
                            disabled={
                                isSaving || tasksState.overtime.isconfirmed || tasksState.overtime.islocked
                                || tasksState.monthConfirmation?.current?.isconfirmed
                                || tasksState.monthConfirmation?.current?.islocked
                            }
                        >
                            Save
                        </button>
                    </div>
                </div>
                <UserReference />
            </main>
            {showUpdateProjectAlert && <AlertDialogBox 
                text='The project you are trying to save have been edited, please check your timecards before saving'
                onConfirm={() => setUpdateProjectAlert(false)}
            />}
        </>
    )
}

export default TimeCardPage
