import { useCallback } from 'react'
import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import useUserServices from './user'

const CLIENT_URL = REST_API_URL + '/client'

const useClientServices = () => {
    const { verifyUserSession } = useUserServices()

    const getAllClients = useCallback(async () => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${CLIENT_URL}/`, {
            method: 'GET',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            }
        })
    
        return await response.json()
    }, [verifyUserSession])
    
    const addNewClient = async(data) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${CLIENT_URL}/add`,{
            method: 'POST',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify(data)
        })
    
        return await response.json()
    }
    
    return {
        getAllClients,
        addNewClient
    }
}

export default useClientServices