import React, { useState } from 'react'
import useError from '../../../../hooks/useError'
import useStatisticsServices from '../../../../services/statistics'
import { pluralizeWord } from '../../../../utils/text'
import ExportReport from '../../ExportReport'
import styles from './index.module.css'
import commonStyles from '../index.module.css'

const Report = ({ filters, report, reportGrouping }) => {
    const [data, setData] = useState(null)
    const { getFullReport } = useStatisticsServices()
    const { setErrorMessage } = useError()

    const reportGroupToFilterName = (group) => `selected${pluralizeWord(group)}`

    const handleRowClick = async (row) => {
        const updatedFilters = { ...filters }
        const groupByFilterName = reportGroupToFilterName(reportGrouping.groupBy)
        updatedFilters[groupByFilterName] = [row.groupbyid]
        if (reportGrouping.thenBy) {
            const thenByFilterName = reportGroupToFilterName(reportGrouping.thenBy)
            updatedFilters[thenByFilterName] = [row.thenbyid]
        }

        if (row.projectid) {
            updatedFilters.selectedProjects = [row.projectid]
        }

        const response = await getFullReport(updatedFilters)

        if (response.error) {
            setErrorMessage(response)
            return
        }

        setData(response)
    }

    console.log(report)
    
    return (
        <>
        { report && report.map((row, idx) => (
            <div key={idx} className={`${commonStyles.row} ${styles.button}`} onClick={() => handleRowClick(row)}>
                { row.projectname && <div className={commonStyles.project}>{row.projectname}</div> }
                <div className={commonStyles['report-group']}>{row.groupbyname || ''}</div>
                { row.thenbyname && <div className={commonStyles['report-group']}>{row.thenbyname || ''}</div> }
                <div className={commonStyles.hours}>{row.totalhours}</div>
            </div>
        ))}
        {data && (
            <ExportReport 
                data={data} 
                setData={setData} 
                selectedRange={filters.selectedRange.map(sr => 
                    ({ to: new Date(sr.to), from: new Date(sr.from) })
                )} 
                reportGrouping={reportGrouping}
            />
        )}
    </>
    )
}

export default Report