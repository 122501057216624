import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.css'

const DescriptionTextarea = ({ value }) => {
    const ref = useRef(null)
    const [areaHeight, setAreaHeight] = useState(null)

    useEffect(() => {
        if (ref.current) {
            setAreaHeight(ref.current.scrollHeight + 2)
        }
    }, [])

    return (
        <textarea
            ref={ref}
            readOnly
            value={value}
            className={styles.textarea}
            style={{ 'height': areaHeight }}
        />
    )
}

export default DescriptionTextarea
