import { useEffect } from "react"
import { ARROW_DOWN_KEY_CODE, ARROW_UP_KEY_CODE, ENTER_KEY_CODE } from "../utils/constants"

const useInputDropdownKeyNav = ({
    optionNameKey,
    isOnFocus,
    currentOptionIndex,
    setCurrentOptionIndex,
    setValue,
    sortedOptions,
    inputRef,
    containerRef,
}) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isOnFocus) {
                if (e.keyCode === ENTER_KEY_CODE) {
                    if (currentOptionIndex === -1) {
                        setValue(sortedOptions[0][optionNameKey])
                        inputRef.current.blur()
                        return
                    }
    
                    setValue(sortedOptions[currentOptionIndex][optionNameKey])
                    inputRef.current.blur()
                    return
                }
    
                if (e.keyCode === ARROW_DOWN_KEY_CODE) {
                    if (currentOptionIndex === sortedOptions.length - 1) {
                        setCurrentOptionIndex(0)
                        return
                    }
    
                    setCurrentOptionIndex(curr => curr + 1)
                    return
                }
    
                if (e.keyCode === ARROW_UP_KEY_CODE) {
                    if (currentOptionIndex === 0 || currentOptionIndex === -1) {
                        setCurrentOptionIndex(sortedOptions.length - 1)
                        return
                    }
    
                    setCurrentOptionIndex(curr => curr - 1)
                    return
                }
            }
        }

        const container = containerRef.current
        container.addEventListener('keydown', handleKeyDown)

        return () => {
            container.removeEventListener('keydown', handleKeyDown)
        }
    }, [
        currentOptionIndex, 
        isOnFocus, 
        optionNameKey, 
        setValue, 
        sortedOptions, 
        setCurrentOptionIndex, 
        containerRef, 
        inputRef
    ])
}

export default useInputDropdownKeyNav