import React, { useCallback, useEffect, useRef, useState } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { useContextStatistics } from '../../contexts/statisticsContext'
import { getWeekDays } from '../../utils/date'
import { initialRangeState } from '../../utils/statistics'
import StatisticsCalendarSelection from '../StatisticsCalendarSelection'
import YearMonthForm from './YearMonthForm'

const currentYear = new Date().getFullYear()
const fromMonth = new Date(2006, 0, 1)
const toMonth = new Date(currentYear, 11)

const CalendarStatistics = () => {
    const [month, setMonth] = useState(new Date())
    const isInitialRender = useRef(true)
    const { statisticsState, setSelectedRange, addNewCalendarRange } = useContextStatistics()
    const { selectedRange } = statisticsState
    const currSelectedRange = selectedRange[selectedRange.length - 1]

    const handleWeekClick = useCallback((weekNumber, days, e) => {
        const firstDayOfWeek = days[0]
        const lastDayOfWeek = days[6]
        firstDayOfWeek.setHours(0, 0, 0)
        lastDayOfWeek.setHours(0, 0, 0)
        setSelectedRange({
            from: firstDayOfWeek,
            hoverTo: lastDayOfWeek,
            enteredTo: lastDayOfWeek
        })
    }, [setSelectedRange])

    useEffect(() => {
        if (isInitialRender.current) {
            const today = new Date()
            const lastWeek = getWeekDays(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7))
            handleWeekClick(undefined, lastWeek)

            isInitialRender.current = false
        }
    }, [handleWeekClick, isInitialRender])

    const handleMonthChange = (newMonth) => {
        setMonth(newMonth)

        const LastDayOfMonth = new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 1)
        LastDayOfMonth.setDate(LastDayOfMonth.getDate() - 1)
        setSelectedRange({
            from: newMonth,
            hoverTo: LastDayOfMonth,
            enteredTo: LastDayOfMonth
        })
    }

    const handleYearChange = (newDate) => {
        setMonth(newDate)

        const firstDayOfYear = new Date(newDate.getFullYear(), 0, 1)
        const lastDayOfYear = new Date(newDate.getFullYear(), 11, 31)
        setSelectedRange({
            from: firstDayOfYear,
            hoverTo: lastDayOfYear,
            enteredTo: lastDayOfYear
        })
    }

    const isSelectingFirstDay = (from, hoverTo, day) => {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
        const isRangeSelected = from && hoverTo
        return !from || isBeforeFirstDay || isRangeSelected
    }

    const handleDayClick = (day) => {
        day.setHours(0, 0, 0)
        const { from, hoverTo } = currSelectedRange
        if (from && hoverTo && day >= from && day <= hoverTo) {
            setSelectedRange(initialRangeState)
            return
        }

        if (isSelectingFirstDay(from, hoverTo, day)) {
            setSelectedRange({
                from: day,
                hoverTo: null,
                enteredTo: null,
            })
        } else {
            setSelectedRange({ ...currSelectedRange, hoverTo: day, enteredTo: day })
        }
    }

    const handleDayMouseEnter = (day) => {
        const { from, hoverTo } = currSelectedRange
        if (!isSelectingFirstDay(from, hoverTo, day)) {
            setSelectedRange({ ...currSelectedRange, enteredTo: day })
        }
    }

    return (
        <div className="YearNavigation">
            <DayPicker
                className='range'
                showWeekNumbers
                showOutsideDays
                firstDayOfWeek={1}
                selectedDays={[currSelectedRange.from, { from: currSelectedRange.from, to: currSelectedRange.enteredTo }]}
                modifiers={{ start: currSelectedRange.from, end: currSelectedRange.enteredTo }}
                onDayClick={handleDayClick}
                onDayMouseEnter={handleDayMouseEnter}
                onWeekClick={handleWeekClick}
                todayButton="This Month"

                month={month}
                fromMonth={fromMonth}
                toMonth={toMonth}
                captionElement={({ date, localeUtils }) => (
                    <YearMonthForm
                        date={date}
                        localeUtils={localeUtils}
                        onMonthChange={handleMonthChange}
                        onYearChange={handleYearChange}
                        fromMonth={fromMonth}
                        toMonth={toMonth}
                    />
                )}
            />
            <div>
                <StatisticsCalendarSelection />
                {!currSelectedRange.from && !currSelectedRange.hoverTo && 'Select the first day.'}
                {currSelectedRange.from && !currSelectedRange.hoverTo && 'Select the last day.'}
                {currSelectedRange.from && currSelectedRange.hoverTo && (
                    <div>
                        <button onClick={addNewCalendarRange}>Add More</button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CalendarStatistics
