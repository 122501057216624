import { useCallback } from 'react'
import {
    JSON_CONTENT_TYPE,
    HTTP_HEADERS,
    REST_API_URL,
    AUTH_COOKIE_NAME
} from '../utils/constants'
import { getRequestStringFromObject } from '../utils/getRequestStringFromObject'
import useUserServices from './user'

const PROJECT_URL = REST_API_URL + '/project'

const useProjectServices = () => {
    const { verifyUserSession } = useUserServices()

    const getProject = useCallback(async (projectId) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${PROJECT_URL}/${projectId}`, {
            method: 'GET'
        })
    
        return await response.json()
    }, [verifyUserSession])

    const getProjectsData = useCallback(async (projectIds) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
        
        const response = await fetch(`${PROJECT_URL}/details?projectIds=${projectIds.join(',')}`, {
            method: 'GET'
        })
    
        return await response.json()
    }, [verifyUserSession])

    const addProject = async (data) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
            const response = await fetch(`${PROJECT_URL}/add`, {
                method: 'POST',
                headers: {
                    [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
                },
                body: JSON.stringify(data)
            })
        
            return await response.json()
    }
    
    const editProject = async(data) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }
    
        const response = await fetch(`${PROJECT_URL}/edit`, {
            method: 'PUT',
            headers: {
                [HTTP_HEADERS.CONTENT_TYPE]: JSON_CONTENT_TYPE
            },
            body: JSON.stringify(data)
        })
    
        return await response.json()
    }

    const getUpdatedProjects = async (userLastProjectChanges) => {
        const userSession = await verifyUserSession(AUTH_COOKIE_NAME)
    
        if (!userSession.isVerified) {
            return userSession
        }

        const updatedProjectsQuery = getRequestStringFromObject(userLastProjectChanges, 'projectsInfo')
        
        const response = await fetch(`${PROJECT_URL}/get-updated-projects${updatedProjectsQuery}`)
    
        return await response.json()
    }
    
    return {
        addProject,
        getProject,
        editProject,
        getProjectsData,
        getUpdatedProjects,
    }
    
}

export default useProjectServices