import React, { useState } from 'react'
import CustomInput from '../../CustomInput'
import styles from './index.module.css'

const AddManualProjectOption = ({ setManualOption, manualOption, optionName, listOfOptions }) => {
    const [newInput, setNewInput] = useState('')

    const setOption = (value) => {
        const newManualOption = new Set(manualOption)
        newManualOption.add(value)
        setManualOption(optionName, newManualOption)
    }

    const removeOption = (index) => {
        const newManualOption = Array.from(manualOption)
        newManualOption.splice(index, 1)
        setManualOption(optionName, new Set(newManualOption))

    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>Create new {optionName}:</div>
            <ul className={styles['list-ul']}>
                {Array.from(manualOption).map((line, index) => (
                    <li key={index} className={styles['list-li']}>
                        <div className={styles.span}>{line}</div>
                        <div onClick={() => removeOption(index)} className={styles['btn-icon']}>REMOVE</div>
                    </li>
                ))
                }
                <CustomInput
                    optionName={optionName}
                    setOption={setOption}
                    currentValue={newInput}
                    setCurrentValue={setNewInput}
                    lineExists={(listOfOptions.has(newInput) || manualOption.has(newInput))}
                />
            </ul>
        </div>
    )
}

export default AddManualProjectOption