import { Fragment, useMemo } from 'react'
import Report from './Report'
import styles from './index.module.css'

const QuickReportsTable = ({ report, reportGrouping, filters }) => {

  const totalHours = useMemo(() => {
    let flatReportsArray
    if (Array.isArray(report)) {
      flatReportsArray = report
    } else {
      flatReportsArray = Object.values(report).flat()
    }

    return flatReportsArray.reduce((total, value) => (total += value.totalhours), 0)
  }, [report])
  
  const includesProject = 
    (Array.isArray(report) && report[0].projectname) ||
    (!Array.isArray(report) && Object.values(report)[0][0].projectname)


  return (
    <>
    <div className={styles.table}>
        <div className={`${styles.row} ${styles['table-header']}`}>
            { includesProject && <div className={styles.project}>Project</div>}
            <div className={styles['report-group']}>{reportGrouping.groupBy}</div>
            { reportGrouping.thenBy && <div className={styles['report-group']}>{reportGrouping.thenBy}</div> }
            <div className={styles.hours}>Hours</div>
        </div>
        <div className={styles['table-body']}>
            { Array.isArray(report) ? (
              <Report report={report} filters={filters} reportGrouping={reportGrouping} />
            ) : Object.entries(report).map(([clientName, reports]) => (
              <Fragment key={clientName}>
                <div className={`${styles.row} ${styles['client-row']}`}>{clientName}</div>
                <Report report={reports} filters={filters} reportGrouping={reportGrouping} />
              </Fragment>
            ))}
        </div>
    </div>
    <div className={`${styles.row} ${styles['total-container']}`}>
        <div className={`${styles.project} ${styles.total}`}>Total Hours:</div>
        <div className={styles.hours}>
            {totalHours}
        </div>
    </div>
    </>
  )
}



export default QuickReportsTable