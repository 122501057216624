import React from 'react'
import InputDropdown from '../../Inputs/InputDropdown'

const AttributeInputDropdown = ({ 
    attributeName, 
    inputOptions, 
    placeholder,
    setOption, 
    setValue, 
    setIsOnFocus,
    isOnFocus,
    isDisabled,
    isInputDropdownBad,
    currentInputValue,
}) => {
    /*
    Component used for dropdown selection specifically of clients and projects in admin panel
    */

    return(
        <InputDropdown
            placeholder={placeholder || `Select ${attributeName}`}
            value={currentInputValue}
            setValue={setValue}
            setOption={setOption}
            setIsOnFocus={setIsOnFocus}
            isOnFocus={isOnFocus}
            inputOptions={inputOptions || []}
            isDisabled={isDisabled}
            isInputDropdownBad={isInputDropdownBad}
            customContainerStyle={{ height: '1.8rem', flexGrow: 10 }}
            customStyle={{ width: '100%', height: '100%' }}
            type={attributeName}
        />
    )
}

export default AttributeInputDropdown
