import { getLastDayOfMonth, getMonth, getWeekNumber, getWeekYear } from "./date"

const getMonthWeekKeys = (dayOfSelectedMonth) => {
  const month = getMonth(dayOfSelectedMonth)
  const year = getWeekYear(dayOfSelectedMonth)
  let firstWeek = getWeekNumber(new Date(year, month, 1))
  let lastWeek = getWeekNumber(getLastDayOfMonth(year, month))
  if (lastWeek === 1) {
    // In case last days in a year are included in the first week of the next year
    let lastDay = getLastDayOfMonth(year, month)
    while (lastWeek === 1) {
      lastDay.setDate(lastDay.getDate() - 1)
      lastWeek = getWeekNumber(lastDay)
    }
  }

  if (firstWeek > lastWeek) {
    // In case first days in a year are included in the last week of the previous year
    let firstDay = new Date(year, month, 2)
    while (firstWeek > lastWeek) {
      firstDay.setDate(firstDay.getDate() + 1)
      firstWeek = getWeekNumber(firstDay)
    }
  }

  let weekKeys = []
  for (let i = firstWeek; i <= lastWeek; i++) {
    weekKeys.push(`${year}-${i}`)
  }

  return weekKeys
}

const updateUsersOvertime = (users, newOvertimes) => {
  const newUsers = []
  for (const u of users) {
    const currUser = { ...u }
    const newOvertime = newOvertimes.find(ot => ot.fk_userid === u.userid)
    currUser.overtimes = { ...currUser.overtimes, [newOvertime.week]: newOvertime }
    newUsers.push(currUser)
  }

  return newUsers
}

const updateUsersMonthConfirmation = (users, monthConfirmations) => {
  const newUsers = []

  for (const user of users) {
    newUsers.push({...user, monthConfirmation: monthConfirmations.find(mc => mc.fk_userid === user.userid)})
  }

  return newUsers
}

export {
  getMonthWeekKeys,
  updateUsersOvertime,
  updateUsersMonthConfirmation,
}
