import React, { useEffect, useMemo, useState } from 'react'
import styles from './index.module.css'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import InputDropdown from '../InputDropdown'

const columns = {
    Job: {
        placeholder: 'Job',
        type: 'job',
        shortTitle: 'Job'
    },
    Zone: {
        placeholder: 'Zone',
        type: 'zone',
        shortTitle: 'Zon'
    },
    System: {
        placeholder: 'System',
        type: 'system',
        shortTitle: 'Sys'
    },
    Lead: {
        placeholder: 'Lead/Department',
        type: 'lead',
        shortTitle: 'L/D'
    },
    Activity: {
        placeholder: 'Activity',
        type: 'activity',
        shortTitle: 'Act'
    },
    Revision: {
        placeholder: 'Revision',
        type: 'revision',
        shortTitle: 'Rev'
    },
    Project: {
        placeholder: 'Project',
        type: 'project',
        shortTitle: 'Pro'
    },
    Client: {
        placeholder: 'Client',
        type: 'client',
        shortTitle: 'Cli'
    }

}

const ResponsiveInput = (
    {
        index,
        title,
        initialValue,
        unselectedOptions,
        options,
        setOption,
        returnOption,
        isDisabled,
        isInCurrRequest,
        className,
    }) => {
    const name = columns[title].type + 'name'
    const id = columns[title].type + 'id'
    const [value, setValue] = useState('')
    const [isOnFocus, setIsOnFocus] = useState(false)
    const [inputOptions, setInputOptions] = useState(unselectedOptions)
    const [isInputShown, setIsInputShown] = useState(false)
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1365)
    const { tasksState } = useContextTimeCard()

    const isInputOk = useMemo(() => {
        if (inputOptions && inputOptions.find(opt => opt[name] === value)) {
            return true
        }

        return false
    }, [inputOptions, name, value])

    const isInputDropdownBad = useMemo(() => {
        return (!!value && !isInputOk && !isOnFocus) || 
               (isInCurrRequest && !tasksState.areTasksOk.tasks && !isInputOk)
    }, [isInCurrRequest, isInputOk, isOnFocus, tasksState.areTasksOk.tasks, value])


    useEffect(() => {
        const checkWindowWidth = () => {
            if (window.innerWidth > 1365) {
                setIsLargeScreen(true)
                return
            }

            setIsLargeScreen(false)
        }

        window.addEventListener('resize', checkWindowWidth)

        return () => {
            window.removeEventListener('resize', checkWindowWidth)
        }
    }, [])

    useEffect(() => {
        if (!initialValue) return

        setValue(initialValue[name] || '')
    }, [initialValue, name])

    useEffect(() => {
        const newUnselectedOptions = [...unselectedOptions]

        if ((columns[title].type === 'client' || columns[title].type === 'project')) {
            const isInitialValueInOptions = options.length && options.find(opt => {
                if (!opt || !initialValue) {
                    return false
                }

                return opt[name] === value && opt[id] === initialValue[id]
            })

            if (isInitialValueInOptions) {
                newUnselectedOptions.push(initialValue)
            }
        }

        setInputOptions(newUnselectedOptions)
    }, [id, initialValue, name, options, setInputOptions, title, unselectedOptions, value])

    return (
        <>
            {isLargeScreen
                ? <div className={className}>
                    {index === 0 && <div className={styles.title}>{title}:</div>}
                    <InputDropdown
                        value={value}
                        setValue={setValue}
                        placeholder={columns[title].placeholder}
                        type={columns[title].type}
                        setOption={setOption}
                        returnOption={returnOption}
                        isDisabled={isDisabled}
                        setIsOnFocus={setIsOnFocus}
                        setIsInputShown={setIsInputShown}
                        inputOptions={inputOptions}
                        isInputDropdownBad={isInputDropdownBad}
                        isOnFocus={isOnFocus}
                    />
                </div>
                : <div className={`${styles.mobile} ${className || ''}`} >
                    {index === 0 && <div className={styles.title}>{columns[title].shortTitle}:</div>}
                    <input
                        title={value}
                        value={value}
                        className={`${styles.input} ${isInputDropdownBad && styles['bad-input']}`}
                        onFocus={() => setIsInputShown(true)}
                        readOnly
                    />
                    {isInputShown && <>
                        <div className={styles.transparent} onClick={() => setIsInputShown(false)} ></div>
                        <div className={styles.container}>
                            <InputDropdown
                                value={value}
                                setValue={setValue}
                                placeholder={columns[title].placeholder}
                                type={columns[title].type}
                                setOption={setOption}
                                returnOption={returnOption}
                                isDisabled={isDisabled}
                                setIsOnFocus={setIsOnFocus}
                                setIsInputShown={setIsInputShown}
                                inputOptions={inputOptions}
                                isInputDropdownBad={isInputDropdownBad}
                                isOnFocus={isOnFocus}
                                autoFocus={true}
                            />
                        </div>
                    </>}
                </div>
            }
        </>
    )
}

export default ResponsiveInput
