import React, { useEffect, useState } from 'react'
import { useContextTimeCard } from '../../../contexts/timeCardContext'
import { getRange } from '../../../utils/getRange'
import { getTotalHours, calculateOvertime } from '../../../utils/userTasks'
import HoursInput from '../../Inputs/HoursInput'
import styles from './index.module.css'
import Overtime from '../Overtime'

const TotalHours = () => {
    const [totalHours, setTotalHours] = useState({})
    const [overtimeObj, setOvertimeObj] = useState({})
    const { tasksState } = useContextTimeCard()
    const { savedTimeCard, selectedDays, holidays } = tasksState

    useEffect(() => {
        const hours = getTotalHours(savedTimeCard)
        setTotalHours(hours)

        const newOvertime = calculateOvertime(hours, selectedDays, holidays)
        setOvertimeObj(newOvertime)
    }, [holidays, savedTimeCard, selectedDays])

    return (
        <div>
            {savedTimeCard && !!Object.keys(savedTimeCard).length &&
                <div className={styles.container}>
                    <div className={styles.text} >Total:</div>
                    {getRange(0, 7).map(num => (
                        <HoursInput
                            key={num}
                            id={num}
                            value={totalHours[num]}
                            disabled={true}
                        />
                    ))}
                </div>
            }
            <Overtime overtimeObj={overtimeObj} />
        </div>
    )
}

export default TotalHours
