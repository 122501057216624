import React from 'react'
import CalendarStatistics from '../../components/CalendarStatistics'
import NavigationBar from '../../components/NavigationBar'
import StatisticsFilters from '../../components/StatisticsFilters'
import StatisticsQuickReport from '../../components/StatisticsQuickReport'
import StatisticsResults from '../../components/StatisticsResults'
import StatisticsSelection from '../../components/StatisticsSelection'
import styles from './index.module.css'

const Statistics = () => {
    return (
        <>
            <NavigationBar />
            <div className={styles.container}>
                <div className={styles['inner-container']}>
                    <CalendarStatistics />
                    <StatisticsFilters />
                    <StatisticsSelection />
                </div>
                <StatisticsQuickReport />
            </div>
            <StatisticsResults />
        </>
    )
}

export default Statistics
