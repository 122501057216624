import React, { useEffect } from 'react'
import styles from './index.module.css'
import { ENTER_KEY_CODE } from '../../utils/constants'

const AlertDialogBox = ({ text, onConfirm }) => {
    useEffect(() => {
        const onKeyPress = (event) => {
            if (event.keyCode === ENTER_KEY_CODE) {
                onConfirm()
                return
            }
        }

        document.addEventListener('keydown', onKeyPress)
        
        return () => document.removeEventListener('keydown', onKeyPress)
    }, [onConfirm])

    return (
        <div>
            <div className={styles.transparent}></div>
            <div className={styles.container}>
                <div>{text}</div>
                <div className={styles['buttons-container']}>
                    <button className={styles.button} onClick={onConfirm} >Ok</button>
                </div>
            </div>
        </div>
    )
}

export default AlertDialogBox
